import React,{useEffect} from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CollectionsIcon from "@mui/icons-material/Collections";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import "../App.css";
import BrandPage from "./brand-admin";
import MenuPage from "./menu";
import BannerPage from "./banner";
import ProductAdminPage from "./product-admin";
import SupportPage from "./suppot";
import PolicyPage from "./policy";
import ContactPage from "./contact";
import { useNavigate } from "react-router-dom";
import ShopIcon from '@mui/icons-material/Shop';
import OrderPage from "./order";
import PostPage from "./post";
import RateReviewIcon from '@mui/icons-material/RateReview';

const drawerWidth = 210;

// interface Props {
//   /**
//    * Injected by the documentation to work in an iframe.
//    * Remove this when copying and pasting into your project.
//    */
//   window?: () => Window;
// }

const AdminPage = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [valueItemList, setValueItemList] = React.useState("Item 2");
  const navigate = useNavigate();
  useEffect(() => {
    const storedData = localStorage.getItem("tm-login");
    if (storedData ===null) {
      navigate("/admin/login");
    }
   
  }, []);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const drawer = (
    <div>
      <Toolbar>
        <div
          style={{
            width: "100%",
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <h1>
          ĐÔNG Á
          </h1>
         
        </div>
      </Toolbar>
      <Divider />
      <List>
        {/* <ListItem
          key={"banner"}
          disablePadding
          style={{
            color: valueItemList === "Item 1" ? "white" : "black",
            backgroundColor: valueItemList === "Item 1" ? "teal" : "white",
          }}
        >
          <ListItemButton
            onClick={() => {
              setValueItemList("Item 1");
            }}
          >
            <ListItemIcon style={{ minWidth: "36px" }}>
              <CollectionsIcon />
            </ListItemIcon>
            <ListItemText primary={"Banner"} />
          </ListItemButton>
        </ListItem> */}
        <ListItem
          key={"menu"}
          disablePadding
          style={{
            color: valueItemList === "Item 2" ? "white" : "black",
            backgroundColor: valueItemList === "Item 2" ? "teal" : "white",
          }}
        >
          <ListItemButton
            onClick={() => {
              setValueItemList("Item 2");
            }}
          >
            <ListItemIcon style={{ minWidth: "36px" }}>
              <MenuIcon />
            </ListItemIcon>
            <ListItemText primary={"Danh mục"} />
          </ListItemButton>
        </ListItem>
        <ListItem
          key={"brand"}
          disablePadding
          style={{
            color: valueItemList === "Item 3" ? "white" : "black",
            backgroundColor: valueItemList === "Item 3" ? "teal" : "white",
          }}
        >
          <ListItemButton
            onClick={() => {
              setValueItemList("Item 3");
            }}
          >
            <ListItemIcon style={{ minWidth: "36px" }}>
              <AppRegistrationIcon />
            </ListItemIcon>
            <ListItemText primary={"Thương hiệu"} />
          </ListItemButton>
        </ListItem>
        <ListItem
          key={"product"}
          disablePadding
          style={{
            color: valueItemList === "Item 4" ? "white" : "black",
            backgroundColor: valueItemList === "Item 4" ? "teal" : "white",
          }}
        >
          <ListItemButton
            onClick={() => {
              setValueItemList("Item 4");
            }}
          >
            <ListItemIcon style={{ minWidth: "36px" }}>
              <ReceiptLongIcon />
            </ListItemIcon>
            <ListItemText primary={"Sản phẩm"} />
          </ListItemButton>
        </ListItem>
        <ListItem
          key={"support"}
          disablePadding
          style={{
            color: valueItemList === "Item 5" ? "white" : "black",
            backgroundColor: valueItemList === "Item 5" ? "teal" : "white",
          }}
        >
          <ListItemButton
            onClick={() => {
              setValueItemList("Item 5");
            }}
          >
            <ListItemIcon style={{ minWidth: "36px" }}>
              <SupportAgentIcon />
            </ListItemIcon>
            <ListItemText primary={"Hỗ trợ khách hàng"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"policy"} disablePadding style={{
            color: valueItemList === "Item 6" ? "white" : "black",
            backgroundColor: valueItemList === "Item 6" ? "teal" : "white",
          }}>
          <ListItemButton  onClick={() => {
              setValueItemList("Item 6");
            }}>
            <ListItemIcon style={{ minWidth: "36px" }}>
              <LocalPoliceIcon />
            </ListItemIcon>
            <ListItemText primary={"Điều khoản & Chính sách"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"contact"} disablePadding style={{
            color: valueItemList === "Item 7" ? "white" : "black",
            backgroundColor: valueItemList === "Item 7" ? "teal" : "white",
          }}>
          <ListItemButton  onClick={() => {
              setValueItemList("Item 7");
            }}>
            <ListItemIcon style={{ minWidth: "36px" }}>
              <PermContactCalendarIcon />
            </ListItemIcon>
            <ListItemText primary={"Liên hệ"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"contact"} disablePadding style={{
            color: valueItemList === "Item 8" ? "white" : "black",
            backgroundColor: valueItemList === "Item 8" ? "teal" : "white",
          }}>
          <ListItemButton  onClick={() => {
              setValueItemList("Item 8");
            }}>
            <ListItemIcon style={{ minWidth: "36px" }}>
              <ShopIcon />
            </ListItemIcon>
            <ListItemText primary={"Đơn hàng"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"post"} disablePadding style={{
            color: valueItemList === "Item 9" ? "white" : "black",
            backgroundColor: valueItemList === "Item 9" ? "teal" : "white",
          }}>
          <ListItemButton  onClick={() => {
              setValueItemList("Item 9");
            }}>
            <ListItemIcon style={{ minWidth: "36px" }}>
              <RateReviewIcon />
            </ListItemIcon>
            <ListItemText primary={"Bài viết"} />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  // Remove this const when copying and pasting into your project.
  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <div>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
     {
        valueItemList ==="Item 1" && <BannerPage/>
     }
     {
        valueItemList ==="Item 2" && <MenuPage/>
     }
      {
        valueItemList ==="Item 3" && <BrandPage/>
     }
     {
        valueItemList ==="Item 4" && <ProductAdminPage/>
     }
     {
        valueItemList ==="Item 5" && <SupportPage/>
     }
     {
        valueItemList ==="Item 6" && <PolicyPage/>
     }
     {
        valueItemList ==="Item 7" && <ContactPage/>
     }
      {
        valueItemList ==="Item 8" && <OrderPage/>
     }
      {
        valueItemList ==="Item 9" && <PostPage/>
     }
    </div>
  );
};
export default AdminPage;
