/* eslint-disable jsx-a11y/alt-text */
import "../App.css";
import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const BrandPage = () => {
  const [valueData, setValueData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [valueName,setValueName] = React.useState("");
   const [valueCode,setValueCode]= React.useState("");
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const [valuePathImg, setValuePathImg] = React.useState("");
  const [valuelink,setValuelink]= React.useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const selectItemFind = (event, newData) => {
    const qr = newData.menuSon;
  };
  useEffect(() => {
    fetchData();
  }, []);
  const formatter = new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
  });

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://codiendonga.com.vn/API/Brand/select.php"
      );
      const result = await response.json();
      console.log(result);
      setValueData(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleChangePage = (event, newNumber) => {
    setPage(newNumber);
  };
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedPathImage, setSelectedPathImage] = useState(null);
  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setSelectedPathImage(file);
      const reader = new FileReader();

      reader.onload = () => {
        setSelectedImage(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };
  function convertToKebabCase(inputString) {
    return inputString
      .toLowerCase()  // Chuyển đổi tất cả thành chữ thường
      .replace(/\s+/g, '-')  // Thay thế khoảng trắng bằng dấu gạch ngang
      .replace(/[^a-z0-9-]/g, '')  // Loại bỏ các ký tự không phải chữ cái, số, hoặc dấu gạch ngang
      .replace(/--+/g, '-');  // Loại bỏ các dấu gạch ngang liên tiếp
  }
  function generateRandomString(length) {
    const characters = '0123456789';
    let randomString = '';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters[randomIndex];
    }
  
    return randomString;
  }
  const save = () => {
    setOpen(false);
    const randomString = generateRandomString(10);
    const kebabCaseString = convertToKebabCase(valueName);
    const formData = new FormData();
    formData.append('image', selectedPathImage);
    formData.append('code',valueCode===""?randomString:valueCode);
    formData.append('name', valueName);
    formData.append('linkbrand', kebabCaseString);

    fetch("https://codiendonga.com.vn/API/Brand/insert.php", {
      method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
      body: formData, // Đảm bảo chuyển đổi đối tượng dữ liệu thành chuỗi JSON nếu bạn sử dụng 'application/json'
    })
      .then((response) => response.json()) // Nếu server trả về JSON, chuyển đổi response thành đối tượng JavaScript
      .then((data) => {
        console.log("Server response:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleCloseUpdate=()=>{
    setSelectedImage(null);
    setValueName("");
    setOpenUpdate(false);
  }
  const update = () => {
    setOpen(false);
    
    const formData = new FormData();
    formData.append('image', selectedPathImage);
    formData.append('code',valueCode);
    formData.append('name', valueName);
    formData.append('linkbrand', valuelink);

    fetch("https://codiendonga.com.vn/API/Brand/insert.php", {
      method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
      body: formData, // Đảm bảo chuyển đổi đối tượng dữ liệu thành chuỗi JSON nếu bạn sử dụng 'application/json'
    })
      .then((response) => response.json()) // Nếu server trả về JSON, chuyển đổi response thành đối tượng JavaScript
      .then((data) => {
        console.log("Server response:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const DeleteItem = async (data) => {
    if (data !== "") {
      const bodydata = {
        code: data,
      };
      fetch("https://codiendonga.com.vn/API/Brand/delete.php", {
        method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
        headers: {
          "Content-Type": "application/json", // hoặc 'application/x-www-form-urlencoded', ...
          // Nếu cần thêm các header khác, thêm vào đây
        },
        body: JSON.stringify(bodydata), // Đảm bảo chuyển đổi đối tượng dữ liệu thành chuỗi JSON nếu bạn sử dụng 'application/json'
      })
        .then((response) => response.text()) // Nếu server trả về JSON, chuyển đổi response thành đối tượng JavaScript
        .then((data) => {
          fetchData();
          console.log("Server response:", data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } 
  };
  const findItem=(giatri)=>{
    const item =valueData.find(item => item.name.toLowerCase() === giatri);
    setValueData(item);
  }
  return (
    <div className="page-admin">
      <div style={{ display: "flex", marginTop: "15px", marginRight: "10px" }}>
        <div
          style={{
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
            justifyItems: "center",
            fontWeight: 600,
            fontSize: 18,
            marginTop: "5px",
          }}
        >
          Thương hiệu
        </div>
        <div
          style={{
            flexGrow: 1,
            marginLeft: "45px",
            marginRight: "45px",
          }}
        >
          <TextField
            size="small"
            placeholder="Nhập tìm sản phẩm"
            InputProps={{
              type: "search",

              endAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            }}
            onChange={(event) => {
              console.log(event.target.value);
              findItem(event.target.value);
            }}
          />
        </div>
        <div style={{ height: "60px" }}>
          <button className="bt-add-brand" onClick={handleClickOpen}>
            Thêm
          </button>
        </div>
      </div>
      <div>
        <table className="custom-border" style={{ width: "90%" }}>
          <thead>
            <tr>
              <th className="custom-border" style={{ width: "5%" }}>
                STT
              </th>
              <th className="custom-border" style={{ width: "50%" }}>
                Tên
              </th>
              <th className="custom-border">Hình ảnh</th>
              <th className="custom-border" style={{ width: "25%" }}>
                Hành động
              </th>
            </tr>
          </thead>
          <tbody>
            {valueData.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  height: "200px",
                  textAlign: "center",
                  justifyItems: "center",
                  justifyContent: "center",
                  width: "100vh",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              valueData.map((item) => (
                <tr key={"key" + item.id}>
                  <td className="custom-border">{item.id}</td>
                  <td>{item.name}</td>
                  <td>
                    <img src={item.img} width={70} />
                  </td>
                  <td>
                    <div>
                      <button className="bt-menu-edit" onClick={(e)=>{
                        setValueCode(item.code);
                        setOpenUpdate(true);
                        setValuelink(item.linkBrand);
                        setValueName(item.name);
                        setValuePathImg(item.img);
                      }}>Edit</button>
                      <button className="bt-menu-delete" onClick={()=>{
                        DeleteItem(item.code);
                      }}>Delete</button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Thương hiệu
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div>
            <div style={{ marginBottom: "5px" }}>Tên thương hiệu</div>
            <input type="text" style={{ width: "300px" }} value={valueName} onChange={(event)=>{
              setValueName(event.target.value);
            }} />
            <div style={{ marginTop: "15px" }}>
              <div style={{ display: "flex" }}>
                <div style={{ flexGrow: 1 }}> Hình ảnh</div>
                <Button component="label" variant="contained">
                  Upload
                  <VisuallyHiddenInput type="file" onChange={handleImageChange}/>
                </Button>
              </div>
            </div>
            <div>
              {selectedImage && (
                <div>
                  <img
                    src={selectedImage}
                    alt="Preview"
                    style={{ maxWidth: "300px", height: "190px" }}
                  />
                </div>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={save}>
            Lưu
          </Button>
        </DialogActions>
      </BootstrapDialog>

      {/* update */}
      <BootstrapDialog
        onClose={handleCloseUpdate}
        aria-labelledby="customized-dialog-title"
        open={openUpdate}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Thương hiệu
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseUpdate}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div>
            <div style={{ marginBottom: "5px" }}>Tên thương hiệu</div>
            <input type="text" style={{ width: "300px" }} value={valueName} onChange={(event)=>{
              setValueName(event.target.value);
            }} />
            <div style={{ marginTop: "15px" }}>
              <div style={{ display: "flex" }}>
                <div style={{ flexGrow: 1 }}> Hình ảnh</div>
                <Button component="label" variant="contained">
                  Upload
                  <VisuallyHiddenInput type="file" onChange={handleImageChange}/>
                </Button>
              </div>
            </div>
            <div>

              {selectedImage ===null?
              valuePathImg !=="" && (
                <div>
                  <img
                    src={valuePathImg}
                    alt="Preview"
                    style={{ maxWidth: "300px", height: "190px" }}
                  />
                </div>
              ): <div>
              <img
                src={selectedImage}
                alt="Preview"
                style={{ maxWidth: "300px", height: "190px" }}
              />
            </div>
              }
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={save}>
            Lưu
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
export default BrandPage;
