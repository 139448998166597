/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from "@mui/material/Autocomplete";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Badge from "@mui/material/Badge";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { myStateAtom } from "./recoilAtoms.js";
import { useRecoilValue } from "recoil";
import { menuStateAtom } from "./recoilMenu.js";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useParams } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import shoppingCart from "../src/Image/shopping-cart.jpg";
import { format } from "date-fns";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import logo from "../src/Image/co-dien-dong-a-color-logo.png";
import CircularProgress from '@mui/material/CircularProgress';
import { Helmet } from "react-helmet";

const ShoppingCartPage = () => {
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const { support } = useParams();
  const [openMenuChildren, setOpenMenuChildren] = React.useState(false);
  const [valueSearcData, setValueSearchData] = useState([]);
  const navigate = useNavigate();
  const [valueDataMenu, setValueDataMenu] = useState([]);
  const [openDraw, setOpenDraw] = useState(false);
  const [htmlContent, setHtmlContent] = useState([]);
  const [valueMenuOld, setValueMenuOld] = useRecoilState(menuStateAtom);
  const [cart, setCart] = useState([]);
  const [valueDiscount, setValuedDiscount] = useState("0");
  const [valuebtBuy, setValuebtBuy] = useState(false);
  const [valueAddress, setValueAddress] = useState([]);
  const [valueDistrict, setValueDistrict] = useState([]);
  const [valueWard, setValueWard] = useState([]);
  const [valueRadio, setValueRadio] = useState("thanh-toan-khi-nhan-hang");
  const [valueItemListIndex, setValueItemListIndex] = React.useState(0);
  const [valueHotline, setValueHotline] = React.useState("");
  const [valueNam, setValueNam] = React.useState("");
  const [valueBac, setValueBac] = React.useState("");
  const [valueTrung, setValueTrung] = React.useState("");
  const [valueKT, setValueKT] = React.useState("");

  const [open, setOpen] = React.useState(false);


  const handleClickCLose = () => {
    setOpenMenuChildren(!openMenuChildren);
  };
  const nextPage = (path) => {
    navigate(path);
    // history.push(path);
  };
  const eventOpenDraw = () => {
    setOpenDraw(true);
  };
  const closeDraw = () => {
    setOpenDraw(false);
  };
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsVisible(true);
      console.log(true);
    } else {
      setIsVisible(false);
      console.log(false);
    }
  };

  useEffect(() => {
    getdata();
    fetchDataMenu();
    const storedData = localStorage.getItem("tm-shopping-cart");
    if (storedData) {
      setCart(JSON.parse(storedData));
    }
    getdataPhone();
    getTinhPho();

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [support, cart]);

  const getdata = () => {
    const url = "https://codiendonga.com.vn/API/Support/select.php";

    const bodydata = {
      link: support,
    };

    fetch(url, {
      method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
      body: JSON.stringify(bodydata), // Đảm bảo chuyển đổi đối tượng dữ liệu thành chuỗi JSON nếu bạn sử dụng 'application/json'
    })
      .then((response) => response.json()) // Nếu server trả về JSON, chuyển đổi response thành đối tượng JavaScript
      .then((data) => {
        setHtmlContent(data[0].content);

        console.log("Server response:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const fetchDataMenu = async () => {
    try {
      const response = await fetch(
        "https://codiendonga.com.vn/API/Menu/select.php"
      );
      const result = await response.json();
      console.log(result);
      setValueDataMenu(result);
      setValueMenuOld(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const formatter = new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
  });

  const subQuatily = (event, product) => {
    const existingItemIndex = cart.findIndex(
      (item) => item.codeTM === product.codeTM
    );
    if (existingItemIndex !== -1) {
      const updatedCart = [...cart];
      updatedCart[existingItemIndex].quantity -= 1;
      setCart(updatedCart);
      localStorage.setItem("tm-shopping-cart", JSON.stringify(updatedCart));
    }
  };
  const addQuatily = (event, product) => {
    const existingItemIndex = cart.findIndex(
      (item) => item.codeTM === product.codeTM
    );
    if (existingItemIndex !== -1) {
      const updatedCart = [...cart];
      updatedCart[existingItemIndex].quantity += 1;
      setCart(updatedCart);
      localStorage.setItem("tm-shopping-cart", JSON.stringify(updatedCart));
    }
  };

  const getTinhPho = async () => {
    try {
      const response = await fetch(
        "https://provinces.open-api.vn/api/?depth=3"
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      setValueAddress(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const changeCity = (event, data) => {
    console.log(data);
    setValueDistrict(data.districts);
  };

  const changeDistrict = async (event, data) => {
    setValueWard(data.wards);
  };

  const handleChangeRadio = (event) => {
    setValueRadio(event.target.value);
  };
  const [order, setOrder] = useState({
    customer: {
      name: '',
      phone: '',
      city:'',
      districts:'',
      wards:'',
      address:'',
      email:'',
      note:''
      // Add more customer fields as needed
    },
    
  });
  const [currentDate, setCurrentDate] = useState(new Date());
  function generateRandomString(length) {
    const characters = "0123456789";
    let randomString = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters[randomIndex];
    }

    return randomString;
  }
  const saveOrder=()=>{
    setOpen(true);
    const randomString = generateRandomString(10);
    const totalMoney =  cart.reduce(
      (total, item) =>
        total + item.price * item.quantity,
      0
    );
    const formattedDate = format(currentDate, 'yyyy-MM-dd HH:mm');
    const ttoan =parseFloat(totalMoney) - parseFloat(valueDiscount);
    const orderCode = "DH"+randomString;
    const bodydata = {
      codeOrder:orderCode,
      customer:order,
      total:totalMoney,
      promotion:valueDiscount,
      thanhtoan:ttoan,
      ngay:formattedDate,
      stylePay:valueRadio,
      products: cart,
    };
    fetch("https://codiendonga.com.vn/API/Order/add.php", {
      method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
      headers: {
        "Content-Type": "application/json", // hoặc 'application/x-www-form-urlencoded', ...
        // Nếu cần thêm các header khác, thêm vào đây
      },
      body: JSON.stringify(bodydata), // Đảm bảo chuyển đổi đối tượng dữ liệu thành chuỗi JSON nếu bạn sử dụng 'application/json'
    })
      .then((response) => response.text()) // Nếu server trả về JSON, chuyển đổi response thành đối tượng JavaScript
      .then((data) => {
        setOpen(false);
        localStorage.removeItem("tm-shopping-cart");
        nextPage("/order/finish/"+orderCode);
        console.log("Server response:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  
  const handleCustomerChange = (name,newValue) => {
    setOrder((prevOrder) => ({
      ...prevOrder,
      customer: {
        ...prevOrder.customer,
        [name]: newValue,
      },
    }));
  };
  const getdataPhone = () => {
    const url = "https://codiendonga.com.vn/API/Contact/select.php";
    fetch(url, {
      method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
    })
      .then((response) => response.json()) // Nếu server trả về JSON, chuyển đổi response thành đối tượng JavaScript
      .then((data) => {
        setValueHotline(data[0].phone_Hotline);
        setValueNam(data[0].phone_Nam);
        setValueBac(data[0].phone_Bac);
        setValueTrung(data[0].phone_Trung);
        setValueKT(data[0].phone_KyThuat);
        console.log("Server response:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleClose = () => {
    localStorage.removeItem('tm-shopping-cart');
    setOpen(false);
    nextPage("/");
  };

  const searchData = async (data) => {
    if (data !== "") {
      const bodydata = {
        code: data,
      };
      fetch("https://codiendonga.com.vn/API/Product/findItem.php", {
        method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
        headers: {
          "Content-Type": "application/json", // hoặc 'application/x-www-form-urlencoded', ...
          // Nếu cần thêm các header khác, thêm vào đây
        },
        body: JSON.stringify(bodydata), // Đảm bảo chuyển đổi đối tượng dữ liệu thành chuỗi JSON nếu bạn sử dụng 'application/json'
      })
        .then((response) => response.json()) // Nếu server trả về JSON, chuyển đổi response thành đối tượng JavaScript
        .then((data) => {
          setValueSearchData(data);
          console.log("Server response:", data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setValueSearchData([]);
    }
  };
  const selectItemFind = (event, newData) => {
    const qr = newData.menuSon;
    navigate("/Product/" + newData.codeTM);
  };
  return (
    <div className="page">
      <Helmet>
         <title>Codiendonga.com.vn</title>
         <meta
           name="description"
           content="Cơ điện đông á, chuyên thiết bị điện công nghiệp, điện dân dụng chính hãng , giá rẻ , giao hàng toàn quốc"
         />
         <meta
           name="keywords"
           content="Cơ điện đông á, chuyên thiết bị điện công nghiệp, điện dân dụng chính hãng , giá rẻ , giao hàng toàn quốc" />
              <link rel="canonical" href="https://codiendonga.com.vn/cart" />
       </Helmet>
      {isDesktopOrLaptop === true && (
        <div>
          <Box sx={{ flexGrow: 1 }}>
            <AppBar
              position="static"
              style={{
                paddingLeft: "3%",
                paddingRight: "3%",
                backgroundColor: "teal",
              }}
            >
              <Toolbar>
              <img src={logo} width={70} height={70} alt="logo-codiendonga" style={{marginRight:'10px'}}/>
              <h1 style={{cursor:'pointer'}}  onClick={async () => {
                  
                    nextPage("/");
                  }}
                >
                  ĐÔNG Á
                </h1>
                <div
                  style={{
                    flexGrow: 1,
                    marginLeft: "45px",
                    marginRight: "45px",
                  }}
                >
                  <Autocomplete
              freeSolo
              size="small"
              id="free-solo-2-demo"
              disableClearable
              sx={{ backgroundColor: "white", borderRadius: "80px" }}
              getOptionLabel={(option) => option.name}
              options={valueSearcData.length === 0 ? [] : valueSearcData}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img loading="lazy" width="20" src={option.img} alt="loading" />
                  <div style={{ marginRight: "15px" }}>{option.name}</div>{" "}
                  <div>{formatter.format(parseFloat(option.price))}</div>
                </Box>
              )}
              onChange={(event, newValue) => {
                selectItemFind(event, newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Nhập tìm sản phẩm"
                  style={{ borderRadius: "60px" }}
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                    style: {
                      border: "none",
                      borderWidth: "0px",
                      borderRadius: "60px",
                    },
                    endAdornment: (
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    ),
                  }}
                  onChange={(event) => {
                    console.log(event.target.value);
                    searchData(event.target.value);
                  }}
                />
              )}
            />
                </div>

                <div style={{ fontSize: "20px", marginRight: "10px" }}>
                <Chip
                    label={`Hotline ${valueHotline}`}
                    style={{
                      color: "black",
                      fontSize: "22px",
                      backgroundColor: "#FFC000",
                      padding:'5px'
                    }}
                   
                  />
                </div>
                <div className="shopping-style">
                  <Badge badgeContent={cart.length} color="error">
                    <ShoppingCartOutlinedIcon style={{ color: "white" }} />
                  </Badge>
                </div>
              </Toolbar>
            </AppBar>
          </Box>
          <div className="menu-header">
            <ul>
              <li className="dropdown">
                <a
                  class="dropbtn"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    nextPage("/thuong-hieu");
                  }}
                >
                  Thương hiệu
                </a>
              </li>
              {valueDataMenu.length === 0 ? (
                <div></div>
              ) : (
                valueDataMenu.map((menu) => (
                  <li className="dropdown">
                    <a
                      class="dropbtn"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        nextPage(
                          menu.child.length === 0
                            ? menu.code
                            : ""
                        );
                      }}
                    >
                      {menu.nameMenu}
                    </a>
                    <div className="dropdown-content">
                      {menu.child.map((item) => (
                        <a
                          onClick={() => {
                            nextPage("/" + item.code + "/" + item.codeChild);
                          }}
                        >
                          {item.nameChild}
                        </a>
                      ))}
                    </div>
                  </li>
                ))
              )}
            </ul>
          </div>
        </div>
      )}
      {isTabletOrMobile === true && (
        <div>
          <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" style={{ backgroundColor: "teal" }}>
              <Toolbar variant="dense">
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={eventOpenDraw}
                >
                  <MenuIcon />
                </IconButton>
                <Typography
                  variant="h6"
                  style={{ flexGrow: 1, cursor: "pointer" }}
                  color="inherit"
                  component="div"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  ĐÔNG Á
                </Typography>
                <div className="shopping-style">
                  <Badge badgeContent={cart.length} color="error">
                    <ShoppingCartOutlinedIcon style={{ color: "white" }} />
                  </Badge>
                </div>
              </Toolbar>
            </AppBar>
          </Box>
          <div>
            <Drawer anchor="left" open={openDraw} onClose={closeDraw}>
              <Box
                sx={{ width: 250 }}
                role="presentation"
                // onClick={closeDraw}
                // onKeyDown={closeDraw}
              >
                <List>
                  <ListItemButton
                    onClick={() => {
                      nextPage("/thuong-hieu");
                    }}
                  >
                    <ListItemText
                      primary="Thương hiệu"
                      style={{ color: "blue" }}
                    />
                  </ListItemButton>
                  {valueDataMenu.map((item, index) => (
                    <div key={item.nameMenu}>
                      <ListItemButton
                        onClick={(event) => {
                          handleClickCLose(index);
                           setValueItemListIndex(index);
                          if (item.child.length === 0) {
                            setOpenDraw(false);
                            navigate(item.code);
                          }
                        }}
                      >
                        <ListItemText
                          primary={item.nameMenu}
                          style={{ color: "blue" }}
                        />
                        {item.child.length > 0 ? (
                          valueItemListIndex === index ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )
                        ) : (
                          ""
                        )}
                      </ListItemButton>
                      <Collapse
                        in={valueItemListIndex === index ? true : false}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List
                          component="div"
                          disablePadding
                          style={{
                            backgroundColor: "#eaeffa",
                            marginLeft: "20px",
                            marginRight: "8px",
                            borderRadius: "10px",
                          }}
                        >
                          {item.child.map((menu) => (
                            <ListItemButton
                              sx={{ pl: 1 }}
                              key={menu.nameChild}
                              onClick={() => {
                                nextPage(
                                  "/" + item.code + "/" + menu.codeChild
                                );
                              }}
                            >
                              <ListItemText
                                primary={menu.nameChild}
                                style={{ marginTop: 0, marginBottom: 0 }}
                              />
                            </ListItemButton>
                          ))}
                        </List>
                      </Collapse>
                    </div>
                  ))}
                </List>
              </Box>
            </Drawer>
          </div>
          <div
            style={{
              flexGrow: 1,
              backgroundColor: "teal",
              padding: "8px",
            }}
          >
            <Autocomplete
              freeSolo
              size="small"
              id="free-solo-2-demo"
              disableClearable
              sx={{ backgroundColor: "white", borderRadius: "80px" }}
              getOptionLabel={(option) => option.name}
              options={valueSearcData.length === 0 ? [] : valueSearcData}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img loading="lazy" width="20" src={option.img} alt="loading" />
                  <div style={{ marginRight: "15px" }}>{option.name}</div>{" "}
                  <div>{formatter.format(parseFloat(option.price))}</div>
                </Box>
              )}
              onChange={(event, newValue) => {
                selectItemFind(event, newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Nhập tìm sản phẩm"
                  style={{ borderRadius: "60px" }}
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                    style: {
                      border: "none",
                      borderWidth: "0px",
                      borderRadius: "60px",
                    },
                    endAdornment: (
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    ),
                  }}
                  onChange={(event) => {
                    console.log(event.target.value);
                    searchData(event.target.value);
                  }}
                />
              )}
            />
          </div>

          <div className="fixed-bottom">
            <div style={{ display: "flex", padding: "5px" }}>
              <div
                style={{ width: "25%", cursor: "pointer" }}
                onClick={() => {
                  window.location.href = `tel:`+{valueNam};
                }}
              >
                <div>
                  <LocalPhoneIcon />
                  <div>Miền Nam</div>
                </div>
              </div>
              <div
                style={{ width: "25%", cursor: "pointer" }}
                onClick={() => {
                  window.location.href = `tel:`+{valueBac};
                }}
              >
                <div>
                  <LocalPhoneIcon />
                  <div>Miền Bắc</div>
                </div>
              </div>
              <div
                style={{ width: "25%", cursor: "pointer" }}
                onClick={() => {
                  window.location.href = `tel:`+{valueTrung};
                }}
              >
                <div>
                  <LocalPhoneIcon />
                  <div>Miền Trung</div>
                </div>
              </div>
              <div
                style={{ width: "25%", cursor: "pointer" }}
                onClick={() => {
                  window.location.href = `tel:`+{valueKT};
                }}
              >
                <div>
                  <LocalPhoneIcon />
                  <div>Kỹ thuật</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="container-no" style={{ borderRadius: "10px" }}>
        <div
          className="container-colo-inside"
          style={{
            marginLeft: isTabletOrMobile ? "8px" : "5%",
            marginRight: isTabletOrMobile ? "8px" : "5%",
          }}
        >
          {valuebtBuy === false &&
            (cart.length === 0 ? (
              <div
                style={{
                  backgroundColor: "white",
                  height: "250px",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  alignContent: "center",
                  alignItems: "center",
                  justifyItems: "center",
                }}
              >
                <div>
                  <img src={shoppingCart} width={100} alt={"giỏ hàng trống"}/>
                  <div>Giỏ hàng trống</div>
                </div>
              </div>
            ) : (
              <div>
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <div style={{ flexGrow: 1 }}>Giỏ hàng</div>
                  <button
                    className="btn-delete-cart"
                    onClick={() => {
                      localStorage.removeItem("tm-shopping-cart");
                      setCart([]);
                    }}
                  >
                    Xóa giỏ hàng
                  </button>
                </div>
                <Paper sx={{ width: "100%" }}>
                  <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">#</TableCell>
                          <TableCell align="center" colSpan={2}>
                            Sản phẩm
                          </TableCell>
                          <TableCell align="right" width={110}>
                            Giá
                          </TableCell>
                          <TableCell align="left" width={100}>
                            Số lượng
                          </TableCell>
                          <TableCell align="right" width={110}>
                            Thành tiền
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {cart.map((row, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.code}
                            >
                              <TableCell key={row.id}>{index + 1}</TableCell>
                              <TableCell key={row.id}>
                                <img src={row.img} height={50} />
                              </TableCell>
                              <TableCell key={row.id}>{row.name}</TableCell>
                              <TableCell key={row.id} align="right">
                                {formatter.format(parseFloat(row.price))}
                              </TableCell>
                              <TableCell key={row.id}>
                                <div style={{ display: "flex" }}>
                                  <button
                                    className="btn-sub"
                                    onClick={(event) => {
                                      subQuatily(event, row);
                                    }}
                                  >
                                    -
                                  </button>
                                  <input
                                    className="txt-quantily"
                                    type="text"
                                    value={row.quantity}
                                  ></input>
                                  <button
                                    className="btn-sub"
                                    onClick={(event) => {
                                      addQuatily(event, row);
                                    }}
                                  >
                                    +
                                  </button>
                                </div>
                              </TableCell>
                              <TableCell key={row.id} align="right">
                                {formatter.format(
                                  parseFloat(row.price) * row.quantity
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className="table-footer">
                    <div>
                      <div style={{ display: "flex", height: "25px" }}>
                        <div style={{ width: "100px" }}>Thành tiền</div>
                        <div>
                          {formatter.format(
                            parseFloat(
                              cart.reduce(
                                (total, item) =>
                                  total + item.price * item.quantity,
                                0
                              )
                            )
                          )}
                        </div>
                      </div>
                      <hr></hr>
                      <div style={{ display: "flex", height: "25px" }}>
                        <div style={{ width: "100px" }}>Giảm giá</div>
                        <div>
                          <input
                            type="text"
                            className="txt-discount"
                            value={valueDiscount}
                            onChange={(newValue) => {
                              setValuedDiscount(newValue.target.value);
                            }}
                          ></input>
                        </div>
                      </div>
                      <hr></hr>
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "100px" }}>Tạm tính</div>
                        <div>
                          {formatter.format(
                            parseFloat(
                              cart.reduce(
                                (total, item) =>
                                  total + item.price * item.quantity,
                                0
                              )
                            ) - parseFloat(valueDiscount)
                          )}
                        </div>
                      </div>
                      <button
                        className="btn-muahang"
                        onClick={() => {
                          setValuebtBuy(true);
                        }}
                      >
                        Mua hàng
                      </button>
                    </div>
                  </div>
                </Paper>
              </div>
            ))}
          {valuebtBuy === true && (
            <div>
              <div style={{ display: "flex", justifyContent: "start" }}>
                <ArrowBackIosIcon
                  fontSize="small"
                  style={{ marginTop: "5px" }}
                  color="primary"
                />
                <Chip
                  label="Quay lại giỏ hàng"
                  onClick={() => {
                    setValuebtBuy(false);
                  }}
                  style={{ color: "blue", backgroundColor: "#e5e7eb" }}
                />
              </div>
              <div>
                <div style={{ display: "flex" }}>
                  <div
                    className="container-infor"
                    style={{
                      width: isDesktopOrLaptop === true ? "70%" : "100%",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <div style={{ display: "flex", height: "28px" }}>
                          <img src="/Image/person.png" width={22} height={22} />
                          <div>Thông tin khách hàng</div>
                        </div>
                        <div style={{ width: "90%" }}>
                          <TextField
                            id="outlined-basic"
                            fullWidth
                            placeholder="Họ và Tên *"
                            size="small"
                            onChange={(e)=>{
                              handleCustomerChange("name",e.target.value);
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div style={{ display: "flex", height: "28px" }}></div>
                        <div style={{ width: "90%" }}>
                          <TextField
                            id="outlined-basic"
                            placeholder="Số điện thoại *"
                            fullWidth
                            size="small"
                            variant="outlined"
                            onChange={(e)=>{
                              handleCustomerChange("phone",e.target.value);
                            }}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                      <div style={{ display: "flex", height: "10px" }}></div>
                        <div style={{ width: "90%" }}>
                          <TextField
                            id="outlined-basic"
                            fullWidth
                            placeholder="Email *"
                            size="small"
                            onChange={(e)=>{
                              handleCustomerChange("email",e.target.value);
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div style={{ display: "flex", height: "10px" }}></div>
                        <div style={{ width: "90%" }}>
                          <TextField
                            id="outlined-basic"
                            placeholder="Ghi chú "
                            fullWidth
                            size="small"
                            variant="outlined"
                            onChange={(e)=>{
                              handleCustomerChange("note",e.target.value);
                            }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <div style={{ height: "30px" }}></div>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <div style={{ display: "flex", height: "28px" }}>
                          <img
                            src="/Image/location.png"
                            width={24}
                            height={24}
                          />
                          <div>Địa chỉ nhận hàng</div>
                        </div>
                        <div>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            size="small"
                            getOptionLabel={(option) => option.name}
                            options={
                              valueAddress.length === 0 ? [] : valueAddress
                            }
                            sx={{ width: "90%" }}
                            onChange={(event, newValue) => {
                              changeCity(event, newValue);
                              handleCustomerChange("city",newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Chọn Tỉnh/Thành Phố"
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div style={{ display: "flex", height: "28px" }}></div>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          size="small"
                          getOptionLabel={(option) => option.name}
                          options={
                            valueDistrict.length === 0 ? [] : valueDistrict
                          }
                          onChange={(event, newValue) => {
                            changeDistrict(event, newValue);
                            handleCustomerChange("districts",newValue);
                          }}
                          sx={{ width: "90%" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Chọn Quận/Huyện"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <div style={{ height: "15px" }}></div>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <div>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            size="small"
                            getOptionLabel={(option) => option.name}
                            options={
                              valueWard.length === 0
                                ? []
                                : valueWard
                            }
                            sx={{ width: "90%" }}
                            onChange={(event, newValue) => {                            
                              handleCustomerChange("wards",newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Chọn Phường/Xã"
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div style={{ width: "90%" }}>
                          <TextField
                            id="outlined-basic"
                            placeholder="Địa chỉ cụ thể số nhà, đường"
                            fullWidth
                            size="small"
                            variant="outlined"
                            onChange={(e)=>{
                              handleCustomerChange("address",e.target.value);
                              
                            }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  {isDesktopOrLaptop === true && (
                    <div>
                      <div
                        className="fixedCard fixed"
                        style={{ position: isVisible ? "fixed" : "" }}
                      >
                        <button className="pay-button" onClick={saveOrder}>
                          Xác nhận thanh toán
                        </button>
                        <div style={{ display: "flex", marginTop: "15px" }}>
                          <div style={{ flexGrow: 1 }}>Tổng tiền</div>
                          <div>
                            {formatter.format(
                              parseFloat(
                                cart.reduce(
                                  (total, item) =>
                                    total + item.price * item.quantity,
                                  0
                                )
                              )
                            )}
                          </div>
                        </div>
                        <div style={{ display: "flex", marginTop: "15px" }}>
                          <div style={{ flexGrow: 1 }}>Giảm giá</div>
                          <div>
                            {formatter.format(parseFloat(valueDiscount))}
                          </div>
                        </div>
                        <div style={{ display: "flex", marginTop: "15px" }}>
                          <div style={{ flexGrow: 1 }}>Thành tiền</div>
                          <div>
                            {formatter.format(
                              parseFloat(
                                cart.reduce(
                                  (total, item) =>
                                    total + item.price * item.quantity,
                                  0
                                )
                              ) - parseFloat(valueDiscount)
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div style={{ marginTop: "30px" }}>
                <div>Danh sách sản phẩm</div>
                <div
                  style={{
                    backgroundColor: "white",
                    width: isTabletOrMobile === true ? "95%" : "70%",
                    padding: "10px",
                    marginTop: "10px",
                    borderRadius: "8px",
                  }}
                >
                  {cart.map((item) => (
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "13%" }}>
                        <img src={item.img} alt="" height={80} />
                      </div>
                      <div
                        style={{
                          width: "55%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {item.name}
                      </div>
                      <div
                        style={{
                          width: "20%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {formatter.format(parseFloat(item.price))}
                      </div>
                      <div
                        style={{
                          width: "10%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        x{item.quantity}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div style={{ marginTop: "30px" }}>
                <div>Hình thức thanh toán</div>
                <div
                  style={{
                    backgroundColor: "white",
                    width: isTabletOrMobile === true ? "95%" : "70%",
                    padding: "10px",
                    marginTop: "10px",
                    borderRadius: "8px",
                  }}
                >
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="thanh-toan-khi-nhan-hang"
                      name="radio-buttons-group"
                      value={valueRadio}
                      onChange={handleChangeRadio}
                    >
                      <FormControlLabel
                        value="thanh-toan-khi-nhan-hang"
                        control={<Radio />}
                        label="Thanh toán khi nhận hàng"
                        style={{ fontSize: 18 }}
                      />
                      <FormControlLabel
                        value="chuyen-khoan-ngan-hang"
                        control={<Radio />}
                        label="Chuyển khoản ngân hàng"
                        style={{ fontSize: 18 }}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
              {valueRadio === "chuyen-khoan-ngan-hang" && (
                <div
                  style={{
                    width: "72%",
                    backgroundColor: "white",
                    height: "200px",
                    marginTop: "20px",
                    borderRadius: "10px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <div style={{margin:'10px'}}>
                        <div style={{marginBottom:'10px', fontWeight:500, color:'blue'}}>Chuyển khoản cá nhân</div>
                        <div>Ngân hàng Vietcombank</div>
                        <div>STK : 9987289556</div>
                        <div>Tên người nhận : Nguyễn Văn Đông</div>
                        <div>Chi nhánh : </div>
                      </div>
                        
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <div style={{margin:'10px'}}>
                    <div style={{marginBottom:'10px', fontWeight:500, color:'blue'}}>Chuyển khoản công ty</div>
                        <div>Ngân hàng ACB</div>
                        <div>STK : 59597788</div>
                        <div>Tên người nhận : Công ty TNHH TM Cơ Điện Đông A</div>
                        <div>Chi nhánh : </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              )}

              {isTabletOrMobile === true && (
                <div
                  style={{
                    backgroundColor: "white",
                    width: "100%",
                    marginTop: "30px",
                    paddingTop: "10px",
                    borderRadius: "8px",
                    paddingBottom: "10px",
                  }}
                >
                  <div style={{ margin: "20px" }}>
                    <div style={{ display: "flex", marginTop: "15px" }}>
                      <div style={{ flexGrow: 1 }}>Tổng tiền</div>
                      <div>
                        {formatter.format(
                          parseFloat(
                            cart.reduce(
                              (total, item) =>
                                total + item.price * item.quantity,
                              0
                            )
                          )
                        )}
                      </div>
                    </div>
                    <div style={{ display: "flex", marginTop: "15px" }}>
                      <div style={{ flexGrow: 1 }}>Giảm giá</div>
                      <div>{formatter.format(parseFloat(valueDiscount))}</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        marginTop: "15px",
                        marginBottom: "20px",
                      }}
                    >
                      <div style={{ flexGrow: 1 }}>Thành tiền</div>
                      <div>
                        {formatter.format(
                          parseFloat(
                            cart.reduce(
                              (total, item) =>
                                total + item.price * item.quantity,
                              0
                            )
                          ) - parseFloat(valueDiscount)
                        )}
                      </div>
                    </div>
                    <button className="pay-button">Xác nhận thanh toán</button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="container-footer">
        <div
          style={{
            marginLeft: isTabletOrMobile ? "8px" : "5%",
            marginRight: isTabletOrMobile ? "8px" : "5%",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={6} md={3}>
              <div style={{ textAlign: "center", justifyContent: "center" }}>
                <img src={logo} alt="logo" width={80} height={80} />
                <h3 style={{ color: "white" }}>
                  CÔNG TY TNHH TM CƠ ĐIỆN ĐÔNG Á
                </h3>
              </div>
            </Grid>
            <Grid item xs={6} md={3}>
              <div>
                <div className="text-support">HỖ TRỢ KHÁCH HÀNG</div>

                <ul className="policy-list">
                  <li
                    className="li-support"
                    onClick={() => {
                      const link = "cam-ket-chat-luong";
                      navigate("/" + link);
                    }}
                  >
                    Cam kết chất lượng
                  </li>
                  <li
                    className="li-support"
                    onClick={() => {
                      const link = "huong-dan-dat-hang";
                      navigate("/" + link);
                    }}
                  >
                    Hướng dẫn đặt hàng
                  </li>
                  <li
                    className="li-support"
                    onClick={() => {
                      const link = "loi-ich-khi-mua-hang";
                      navigate("/" + link);
                    }}
                  >
                    Lợi ích khi mua hàng
                  </li>
                  <li
                    className="li-support"
                    onClick={() => {
                      const link = "van-chuyen-va-giao-nhan";
                      navigate("/" + link);
                    }}
                  >
                    Vận chuyển và giao nhận
                  </li>
                  <li
                    className="li-support"
                    onClick={() => {
                      const link = "hinh-thuc-thanh-toan";
                      navigate("/" + link);
                    }}
                  >
                    Hình thức thanh toán
                  </li>
                  <li
                    className="li-support"
                    onClick={() => {
                      const link = "lien-he";
                      navigate("/" + link);
                    }}
                  >
                    Liên hệ
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={6} md={3}>
              <div>
                <div className="text-support">ĐIỀU KHOẢN VÀ CHÍNH SÁCH</div>
                <div style={{ display: "block" }}>
                  <ul class="policy-list">
                    <li
                      className="li-support"
                      onClick={() => {
                        const link = "dieu-khoan-su-dung";
                        navigate("/" + link);
                      }}
                    >
                      Điều khoản sử dụng
                    </li>
                    <li
                      className="li-support"
                      onClick={() => {
                        const link = "chinh-sach-doi-tra-hang";
                        navigate("/" + link);
                      }}
                    >
                      Chính sách đổi trả hàng
                    </li>
                    <li
                      className="li-support"
                      onClick={() => {
                        const link = "chinh-sach-khieu-nai";
                        navigate("/" + link);
                      }}
                    >
                      Chính sách khiếu nại
                    </li>
                    <li
                      className="li-support"
                      onClick={() => {
                        const link = "chinh-sach-bao-hanh";
                        navigate("/" + link);
                      }}
                    >
                      Chính sách bảo hành
                    </li>
                    <li
                      className="li-support"
                      onClick={() => {
                        const link = "chinh-sach-bao-mat";
                        navigate("/" + link);
                      }}
                    >
                      Chính sách bảo mật
                    </li>
                  </ul>
                </div>
              </div>
            </Grid>
            <Grid item xs={6} md={3}>
              <div>
                <div className="text-support">LIÊN HỆ</div>
                <ul className="policy-list">
                    <li className="li-support">Miền Bắc : {valueBac}</li>
                    <li className="li-support">Miền Nam : {valueNam}</li>
                    <li className="li-support">Miền Trung : {valueTrung}</li>
                    <li className="li-support">
                      Kỹ thuật - Bảo hành : {valueKT}
                    </li>
                  </ul>
              </div>
            </Grid>
            {/* <Grid item xs={6} md={3}>
              <div>
                <div className="text-support">CHỨNG NHẬN DOANH NGHIỆP</div>
                <div style={{ display: "block" }}>
                  <img
                    src="/Image/dang-ky-bo-cong-thuong-dong-a.png"
                    width={isTabletOrMobile ? "150px" : "220px"}
                    alt="bo-cong-thuong"
                  />
                </div>
              </div>
            </Grid> */}
          </Grid>
        </div>
      </div>
      <div className="cls-company">
        <div
          style={{
            paddingTop:'20px',
            marginLeft: isTabletOrMobile ? "8px" : "5%",
            marginRight: isTabletOrMobile ? "8px" : "5%",
            paddingBottom:isTabletOrMobile ? "80px":"20px"
          }}
        >
          <div style={{fontWeight:600, color:'white',padding:'5px'}}>CÔNG TY TNHH THƯƠNG MẠI CƠ ĐIỆN ĐÔNG Á</div>
          <div style={{ color:'white',padding:'5px'}}>
            Văn phòng chính: Thạnh Hội 1 , Tổ 1 , Ấp Nhứt Thạnh, xã Thạnh Hội,
            Tp Tân Uyên, Bình Dương{" "}
          </div>
          <div style={{color:'white',padding:'5px'}}>
            Kho HCM : 22/15 Đường 440 giao cắt Võ Nguyên Giáp, Phường Phước Long
            A , Tp. Thủ Đức, TP. Hồ Chí Minh
          </div>
        </div>
      </div>
      <div style={{display:'flex',justifyContent:'center'}}>
      <a className="by" href="https://tmsoftware.vn/">Design Created by tmsoftware.vn</a>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <CircularProgress />
          </DialogContentText>
        </DialogContent>
       
      </Dialog>
    </div>
  );
};
export default ShoppingCartPage;
