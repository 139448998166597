/* eslint-disable jsx-a11y/alt-text */
import "../App.css";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import React from "react";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const BannerPage = () => {
    const [valueImg,setValueImg] = React.useState('');
  return (
    <div className="page-admin">
      <div style={{ display: "flex",marginRight:'10px',marginTop:'10px' }}>
        <div style={{flexGrow:1}}>Banner</div>
        <Button
          component="label"
          variant="contained"
          size="small"
          startIcon={<CloudUploadIcon />}
        >
          Image
          <VisuallyHiddenInput type="file" />
        </Button>
      </div>
      <img src="" />
    </div>
  );
};
export default BannerPage;
