/* eslint-disable jsx-a11y/alt-text */
import "../App.css";
import React, { useState, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { Grid } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import styles
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { NumericFormat } from "react-number-format";
import Chip from "@mui/material/Chip";
import parse from "html-react-parser";

const useStyles = makeStyles((theme) => ({
  paginationItem: {
    backgroundColor: "#fff", // Set the background color here
    marginRight: 10,
  },
}));
const formatter = new Intl.NumberFormat("vi-VN", {
  style: "currency",
  currency: "VND",
});
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ProductAdminPage = () => {
  const [valueData, setValueData] = useState([]);
  const [valueDataShow, setValueDataShow] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [page, setPage] = React.useState(0);
  const [checkAdd, setCheckAdd] = useState(false);
  const [checkUpdate, setCheckUpdate] = useState(false);
  const [valueDataBrand, setValueDataBrand] = useState([]);
  const [valueCode, setValueCode] = useState("");
  const [valueName, setValueName] = useState("");
  const [valueMenu, setValueMenu] = useState("");
  const [valuePrice, setValuePrice] = useState("");
  const [valueBrand, setValueBrand] = useState("");
  const [valueQuality, setValueQuality] = useState("");
  const [valueBH, setValueBH] = useState("");
  const [valueChungChi, setValueChungChi] = useState("");
  const [valueCodeTm, setValueCodeTm] = useState("");
  const [valueFill, setValueFill] = useState("");
  const [valueKeywords, setValueKeyword] = useState("");
  const [valueDescription, setValueDescription] = useState("");

  const quillRef = useRef();

  useEffect(() => {
    fetchData();
    fetchDataMenu();
    if (quillRef.current) {
      quillRef.current.getEditor().on("text-change", handleTextChange);
    }
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://codiendonga.com.vn/API/Product/selectAll.php"
      );
      const result = await response.json();
      console.log(result);
      setValueData(result);
      setValueDataShow(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleChangePage = (event, newNumber) => {
    setPage(newNumber);
  };
  const [valueDataMenu, setValueDataMenu] = useState([]);
  const fetchDataMenu = async () => {
    try {
      const response = await fetch(
        "https://codiendonga.com.vn/API/Menu/selectMenuChild.php"
      );
      const result = await response.json();
      console.log(result);
      setValueDataMenu(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchDataBrand = async () => {
    try {
      const response = await fetch(
        "https://codiendonga.com.vn/API/Brand/select.php"
      );
      const result = await response.json();
      console.log(result);
      setValueDataBrand(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchDataFill = async (value) => {
    const bodydata = {
      codeLink: value,
    };
    try {
      const response = await fetch(
        "https://codiendonga.com.vn/API/Product/selectFillter.php",
        {
          method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
          headers: {
            "Content-Type": "application/json", // hoặc 'application/x-www-form-urlencoded', ...
            // Nếu cần thêm các header khác, thêm vào đây
          },
          body: JSON.stringify(bodydata), // Đảm bảo chuyển đổi đối tượng dữ liệu thành chuỗi JSON nếu bạn sử dụng 'application/json'
        }
      );
      const result = await response.json();
      console.log(result);
      setValueFill(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const classes = useStyles();
  const handleTextChange = (delta, oldDelta, source) => {
    if (source === "user") {
      const currentContent = quillRef.current.getEditor().getContents();
      const imageDelta = containsImage(currentContent);

      if (imageDelta) {
        //  handleImageInsert(imageDelta);
      }
    }
  };
  const containsImage = (delta) => {
    for (const op of delta.ops) {
      if (op.insert && op.insert.image) {
        return op;
      }
    }
    return null;
  };

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedPathImage, setSelectedPathImage] = useState(null);
  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setSelectedPathImage(file);
      const reader = new FileReader();

      reader.onload = () => {
        setSelectedImage(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };
  function convertToKebabCase(inputString) {
    return inputString
      .toLowerCase() // Chuyển đổi tất cả thành chữ thường
      .replace(/\s+/g, "-") // Thay thế khoảng trắng bằng dấu gạch ngang
      .replace(/[^a-z0-9-]/g, "") // Loại bỏ các ký tự không phải chữ cái, số, hoặc dấu gạch ngang
      .replace(/--+/g, "-"); // Loại bỏ các dấu gạch ngang liên tiếp
  }
  function generateRandomString(length) {
    const characters = "0123456789";
    let randomString = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters[randomIndex];
    }

    return randomString;
  }
  const [productList, setProductList] = useState([
    { id: 1, type: "dien-ap-ngo-vao", value: "" },
    { id: 2, type: "kich-thuoc-man-hinh", value: "" },
    { id: 3, type: "ngo-ra", value: "" },
    { id: 4, type: "cong-suat", value: "" },
    { id: 5, type: "loai", value: "" },
    { id: 6, type: "duong-kinh", value: "" },
    { id: 7, type: "ti-so-truyen", value: "" },
    { id: 8, type: "luc-rung", value: "" },
    { id: 9, type: "cot-vao", value: "" },
    { id: 10, type: "cot-ra", value: "" },
    { id: 11, type: "toc-do", value: "" },
    { id: 12, type: "dong-dien", value: "" },
    { id: 13, type: "so-cuc", value: "" },
    { id: 14, type: "mau", value: "" },
    { id: 15, type: "dien-ap-dieu-khien", value: "" },
    { id: 16, type: "menu-con", value: "" },
    { id: 17, type: "dien-ap-ngo-ra", value: "" },
    { id: 18, type: "dong-cat", value: "" },
    { id: 19, type: "dong-ro", value: "" },
    { id: 20, type: "menucon", value: "" },
    // Add more items as needed
  ]);
  const handleInputChangeFill = (type, value) => {
    setProductList((prevList) =>
      prevList.map((item) => (item.type === type ? { ...item, value } : item))
    );
  };
  const save = async () => {
    const detail =
      "<p>SKU: " +
      valueCode +
      "<br>Hãng sản xuất: " +
      valueBrand +
      "<br>Chất lượng: Mới 100%<br>Bảo hành: Chính hãng<br>Chứng từ: CO/CQ, hóa đơn VAT</p>";
    const randomString = generateRandomString(10);
    const kebabCaseString = convertToKebabCase(valueName);
    const soTienSo = parseInt(valuePrice.replace(/,/g, ""));
    const formData = new FormData();
    formData.append(
      "image",
      selectedPathImage === null ? "" : selectedPathImage
    );
    formData.append("codeTM", randomString);
    formData.append("code", valueCode);
    formData.append("name", valueName + " " + valueCode);
    formData.append("price", soTienSo);
    formData.append("priceSales", "0");
    formData.append("isHome", "0");
    formData.append("menuFather", "");
    formData.append(
      "menuSon",
      valueDataMenu.find((menu) => menu.nameChild === valueMenu).codeChild
    );
    formData.append("description", valueDescription);
    formData.append("keywords", valueKeywords);

    formData.append("brand", valueBrand);
    formData.append("Quality", valueQuality);
    formData.append("certificate", valueChungChi);
    formData.append("warranty", valueBH);
    formData.append("services", content);
    formData.append("describe", contentDescribe);
    formData.append("information", "");
    formData.append("mota", contentDescribe);
    formData.append("product_detail", detail);
    formData.append("upload", "false");
    formData.append(
      "dienapngovao",
      productList.find((item) => item.type === "dien-ap-ngo-vao").value
    );
    formData.append(
      "congsuat",
      productList.find((item) => item.type === "cong-suat").value
    );
    formData.append(
      "kichthuocmanhinh",
      productList.find((item) => item.type === "kich-thuoc-man-hinh").value
    );
    formData.append(
      "loai",
      productList.find((item) => item.type === "loai").value
    );
    formData.append(
      "dienapdieukhien",
      productList.find((item) => item.type === "dien-ap-dieu-khien").value
    );
    formData.append(
      "dongdien",
      productList.find((item) => item.type === "dong-dien").value
    );
    formData.append(
      "menucon",
      productList.find((item) => item.type === "menu-con").value
    );
    formData.append(
      "duongkinh",
      productList.find((item) => item.type === "duong-kinh").value
    );
    formData.append(
      "dienapngora",
      productList.find((item) => item.type === "dien-ap-ngo-ra").value
    );
    formData.append(
      "dongcat",
      productList.find((item) => item.type === "dong-cat").value
    );
    formData.append(
      "socuc",
      productList.find((item) => item.type === "so-cuc").value
    );
    formData.append(
      "dongro",
      productList.find((item) => item.type === "dong-ro").value
    );
    formData.append(
      "mau",
      productList.find((item) => item.type === "mau").value
    );
    formData.append(
      "tocdo",
      productList.find((item) => item.type === "toc-do").value
    );
    formData.append(
      "tisotruyen",
      productList.find((item) => item.type === "ti-so-truyen").value
    );
    formData.append(
      "cotvao",
      productList.find((item) => item.type === "cot-vao").value
    );
    formData.append(
      "cotra",
      productList.find((item) => item.type === "cot-ra").value
    );
    formData.append(
      "lucrung",
      productList.find((item) => item.type === "luc-rung").value
    );
    formData.append(
      "ngora",
      productList.find((item) => item.type === "ngo-ra").value
    );

    fetch("https://codiendonga.com.vn/API/Product/insertProdut.php", {
      method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
      body: formData, // Đảm bảo chuyển đổi đối tượng dữ liệu thành chuỗi JSON nếu bạn sử dụng 'application/json'
    })
      .then((response) => response.json()) // Nếu server trả về JSON, chuyển đổi response thành đối tượng JavaScript
      .then((data) => {
        console.log("Server response:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const update = () => {
    const soTienSo = parseInt(valuePrice.replace(/,/g, ""));
    const formData = new FormData();
    formData.append(
      "image",
      selectedPathImage === null ? "" : selectedPathImage
    );
    formData.append("codeTM", valueCodeTm);
    formData.append("code", valueCode);
    formData.append("name", valueName + " " + valueCode);
    formData.append("price", soTienSo);
    formData.append("priceSales", "0");
    formData.append("isHome", "0");
    formData.append("menuFather", "");
    formData.append(
      "menuSon",
      valueDataMenu.find((menu) => menu.nameChild === valueMenu).codeChild
    );
    formData.append("description", valueDescription);
    formData.append("keywords", valueKeywords);
    formData.append("brand", valueBrand);
    formData.append("Quality", valueQuality);
    formData.append("certificate", valueChungChi);
    formData.append("warranty", valueBH);
    formData.append("services", content);
    formData.append("describe", contentDescribe);
    formData.append("information", "");
    formData.append("mota", contentDescribe);
    formData.append("product_detail", contentDescribe);
    formData.append("upload", "false");
    formData.append(
      "dienapngovao",
      productList.find((item) => item.type === "dien-ap-ngo-vao").value
    );
    formData.append(
      "congsuat",
      productList.find((item) => item.type === "cong-suat").value
    );
    formData.append(
      "kichthuocmanhinh",
      productList.find((item) => item.type === "kich-thuoc-man-hinh").value
    );
    formData.append(
      "loai",
      productList.find((item) => item.type === "loai").value
    );
    formData.append(
      "dienapdieukhien",
      productList.find((item) => item.type === "dien-ap-dieu-khien").value
    );
    formData.append(
      "dongdien",
      productList.find((item) => item.type === "dong-dien").value
    );
    formData.append(
      "menucon",
      productList.find((item) => item.type === "menu-con").value
    );
    formData.append(
      "duongkinh",
      productList.find((item) => item.type === "duong-kinh").value
    );
    formData.append(
      "dienapngora",
      productList.find((item) => item.type === "dien-ap-ngo-ra").value
    );
    formData.append(
      "dongcat",
      productList.find((item) => item.type === "dong-cat").value
    );
    formData.append(
      "socuc",
      productList.find((item) => item.type === "so-cuc").value
    );
    formData.append(
      "dongro",
      productList.find((item) => item.type === "dong-ro").value
    );
    formData.append(
      "mau",
      productList.find((item) => item.type === "mau").value
    );
    formData.append(
      "tocdo",
      productList.find((item) => item.type === "toc-do").value
    );
    formData.append(
      "tisotruyen",
      productList.find((item) => item.type === "ti-so-truyen").value
    );
    formData.append(
      "cotvao",
      productList.find((item) => item.type === "cot-vao").value
    );
    formData.append(
      "cotra",
      productList.find((item) => item.type === "cot-ra").value
    );
    formData.append(
      "lucrung",
      productList.find((item) => item.type === "luc-rung").value
    );
    formData.append(
      "ngora",
      productList.find((item) => item.type === "ngo-ra").value
    );

    fetch("https://codiendonga.com.vn/API/Product/update.php", {
      method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
      body: formData, // Đảm bảo chuyển đổi đối tượng dữ liệu thành chuỗi JSON nếu bạn sử dụng 'application/json'
    })
      .then((response) => response.json()) // Nếu server trả về JSON, chuyển đổi response thành đối tượng JavaScript
      .then((data) => {
        console.log("Server response:", data);

        fetchData();
        checkUpdate(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const [value, setValue] = useState("0");

  const handleInputChange = (values) => {
    const { value } = values;
    // 'value' ở đây sẽ là giá trị đã được định dạng theo định dạng số tiền
    setValue(value);
  };

  const [content, setContent] = useState("");
  const [contentDescribe, setContentDescribe] = useState("");
  const handleChange = (value) => {
    const images = parse(value, {
      replace: (domNode) => {
        if (domNode.name && domNode.name === "img") {
          return <img src={domNode.attribs.src} alt={domNode.attribs.alt} />;
        }
      },
    });
    setContent(value);
  };
  const handleChangeDes = (value) => {
    const images = parse(value, {
      replace: (domNode) => {
        if (domNode.name && domNode.name === "img") {
          return <img src={domNode.attribs.src} alt={domNode.attribs.alt} />;
        }
      },
    });
    const check = images.length;
    setContentDescribe(value);
  };

  // const searchData = async (data) => {
  //   if (data !== "") {
  //     const filteredList = valueData.filter((item) =>
  //       item.name.toLowerCase().includes(data.toLowerCase())
  //     );
  //     setValueDataShow(filteredList);
  //   } else {
  //     setValueDataShow(valueData);
  //   }
  // };
  const [filters, setFilters] = useState({});
  const handleFilterChange = async (filterName, value) => {
    await setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };
  const searchData = async (data) => {
    if (data !== "") {
      const bodydata = {
        code: data,
      };
      fetch("https://codiendonga.com.vn/API/Product/findItem.php", {
        method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
        headers: {
          "Content-Type": "application/json", // hoặc 'application/x-www-form-urlencoded', ...
          // Nếu cần thêm các header khác, thêm vào đây
        },
        body: JSON.stringify(bodydata), // Đảm bảo chuyển đổi đối tượng dữ liệu thành chuỗi JSON nếu bạn sử dụng 'application/json'
      })
        .then((response) => response.json()) // Nếu server trả về JSON, chuyển đổi response thành đối tượng JavaScript
        .then((data) => {
          setValueDataShow(data);
          console.log("Server response:", data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      fetchData();
    }
  };
  const DeleteItem = async (data) => {
    if (data !== "") {
      const bodydata = {
        codeTM: data,
      };
      fetch("https://codiendonga.com.vn/API/Product/delete.php", {
        method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
        headers: {
          "Content-Type": "application/json", // hoặc 'application/x-www-form-urlencoded', ...
          // Nếu cần thêm các header khác, thêm vào đây
        },
        body: JSON.stringify(bodydata), // Đảm bảo chuyển đổi đối tượng dữ liệu thành chuỗi JSON nếu bạn sử dụng 'application/json'
      })
        .then((response) => response.text()) // Nếu server trả về JSON, chuyển đổi response thành đối tượng JavaScript
        .then((data) => {
          fetchData();
          console.log("Server response:", data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };
  return (
    <div className="page-admin">
      {checkAdd === false && (
        <div>
          <div className="header-page">
            <div
              style={{
                fontSize: "20x",
                fontWeight: 700,
                justifyItems: "center",
                display: "flex",
                justifyContent: "center",
                marginTop: "5px",
              }}
            >
              Sản phẩm
            </div>
            <div
              style={{
                flexGrow: 1,
                marginLeft: "45px",
                marginRight: "45px",
              }}
            >
              <TextField
                size="small"
                placeholder="Nhập tìm sản phẩm"
                style={{width:'50%'}}
                InputProps={{
                  type: "search",

                  endAdornment: (
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
                onChange={(event) => {
                  console.log(event.target.value);
                  searchData(event.target.value);
                }}
              />
            </div>
            <div style={{ height: "60px", marginTop: "5px" }}>
              <button
                className="bt-add-brand"
                onClick={(event) => {
                  setValueDataBrand("");
                  setValueBH("");
                  setValueName("");
                  setValueCode("");
                  setValuePrice("0");
                  setContent("");
                  setSelectedImage("");
                  fetchDataMenu();
                  fetchDataBrand();
                  setCheckAdd(true);
                }}
              >
                Thêm
              </button>
            </div>
          </div>
          <div className="main-content">
            <table className="custom-border" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th className="custom-border" style={{ width: "5%" }}>
                    STT
                  </th>
                  <th className="custom-border" style={{ width: "30%" }}>
                    Tên
                  </th>
                  <th className="custom-border" style={{ width: "17%" }}>
                    Menu
                  </th>
                  <th className="custom-border" style={{ width: "20%" }}>
                    Hình ảnh
                  </th>
                  <th className="custom-border">Đơn giá</th>
                  <th className="custom-border" style={{ width: "15%" }}>
                    Hành động
                  </th>
                </tr>
              </thead>
              <tbody>
                {valueDataShow.length === 0 ? (
                  <div
                    style={{
                      display: "flex",
                      height: "200px",
                      textAlign: "center",
                      justifyItems: "center",
                      justifyContent: "center",
                      width: "100vh",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  valueDataShow
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => (
                      <tr key={"key" + item.id}>
                        <td className="custom-border">{index + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.nameChild}</td>
                        <td>
                          <img src={item.img} width={70} />
                        </td>
                        <td>{formatter.format(parseFloat(item.price))}</td>
                        <td>
                          <div>
                            <button
                              className="bt-menu-edit"
                              onClick={(event) => {
                                setValueCodeTm(item.codeTM);
                                setCheckAdd(true);
                                setCheckUpdate(true);
                                setValueCode(item.code);
                                setValueName(item.name);
                                setValuePrice(item.price);
                                setValueBrand(item.brand);
                                const qr = valueDataMenu.find(
                                  (menu) => menu.codeChild === item.menuSon
                                );
                                if (typeof qr !== 'undefined') {
                                  setValueMenu(
                                    valueDataMenu.find(
                                      (menu) => menu.codeChild === item.menuSon
                                    ).nameChild
                                  );
                                }else{
                                  setValueMenu(item.menuSon);
                                }
                               
                                setValueQuality(item.Quality);
                                setContent(item.services);
                                fetchDataFill(item.menuSon);
                                setContentDescribe(item.mota);
                                handleInputChangeFill(
                                  "dien-ap-ngo-vao",
                                  item.dienapngovao
                                );
                                handleInputChangeFill(
                                  "kich-thuoc-man-hinh",
                                  item.screenSize
                                );
                                handleInputChangeFill("ngo-ra", item.ngora);
                                handleInputChangeFill(
                                  "cong-suat",
                                  item.congsuat
                                );
                                handleInputChangeFill("loai", item.loai);
                                handleInputChangeFill(
                                  "duong-kinh",
                                  item.duongkinh
                                );
                                handleInputChangeFill(
                                  "ti-so-truyen",
                                  item.tisotruyen
                                );
                                handleInputChangeFill("luc-rung", item.lucrung);
                                handleInputChangeFill("cot-vao", item.cotvao);
                                handleInputChangeFill("cot-ra", item.cotra);
                                handleInputChangeFill("toc-do", item.tocdo);
                                handleInputChangeFill(
                                  "dong-dien",
                                  item.dongdien
                                );
                                handleInputChangeFill("so-cuc", item.socuc);
                                handleInputChangeFill("mau", item.mau);
                                handleInputChangeFill(
                                  "dien-ap-dieu-khien",
                                  item.dienapdieukhien
                                );
                                handleInputChangeFill("menu-con", item.menucon);
                                handleInputChangeFill("dien-ap-ngo-ra", "");
                                handleInputChangeFill("dong-cat", item.dongcat);
                                handleInputChangeFill("dong-ro", item.dongro);
                                setSelectedImage(item.img);
                              }}
                            >
                              Edit
                            </button>
                            <button
                              className="bt-menu-delete"
                              onClick={(event) => {
                                DeleteItem(item.codeTM);
                              }}
                            >
                              Delete
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                )}
              </tbody>
            </table>
            <div
              className="cls-page"
              style={{ backgroundColor: "#fff", paddingBottom: "10px" }}
            >
              <Pagination
                count={Math.ceil(valueDataShow.length / 50 - 1)}
                onChange={handleChangePage}
                variant="outlined"
                style={{ backgroundColor: "#e5e7eb" }}
                classes={{ ul: classes.paginationItem }}
                shape="rounded"
              />
            </div>
          </div>
        </div>
      )}

      {checkAdd === true && (
        <div>
          <div className="header-page">
            <Chip
              label="< Quay lại"
              onClick={() => {
                setCheckAdd(false);
              }}
              style={{
                color: "blue",
                backgroundColor: "#e5e7eb",
                marginRight: "10px",
              }}
            />
            <div
              style={{
                fontSize: "20x",
                fontWeight: 700,
                justifyItems: "start",
                display: "flex",
                justifyContent: "start",
                marginTop: "5px",
                flexGrow: 1,
              }}
            >
              Thêm Sản phẩm
            </div>

            <div style={{ height: "60px", marginTop: "5px" }}>
              <button
                className="bt-add-brand"
                onClick={(event) => {
                  setCheckAdd(false);
                  checkUpdate === true ? update() : save();
                }}
              >
                Lưu
              </button>
            </div>
          </div>
          <div style={{ marginRight: "10px" }}>
            <Grid container>
              <Grid item xs={12} md={4}>
                <div style={{ marginRight: "15px" }}>
                  <div style={{ display: "flex" }}>
                    <div style={{ flexGrow: 1 }}>Hình ảnh</div>
                    <Button component="label" variant="contained" size="small">
                      Upload
                      <VisuallyHiddenInput
                        type="file"
                        onChange={handleImageChange}
                      />
                    </Button>
                  </div>
                  {selectedImage && (
                    <div>
                      <img
                        src={selectedImage}
                        alt="Preview"
                        style={{ width: "90%", height: "270px" }}
                      />
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} md={8}>
                <div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={5}>
                      <div>Mã sản phẩm</div>
                      <TextField
                        id="outlined-basic"
                        size="small"
                        placeholder="Mã sản phẩm"
                        fullWidth
                        variant="outlined"
                        value={valueCode}
                        onChange={(event) => {
                          setValueCode(event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <div>Tên sản phẩm</div>
                      <TextField
                        id="outlined-basic"
                        size="small"
                        fullWidth
                        value={valueName}
                        placeholder="Tên sản phẩm"
                        variant="outlined"
                        onChange={(event) => {
                          setValueName(event.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <div style={{ height: "10px" }}></div>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={5}>
               
                      <div>Menu</div>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        size="small"
                        value={valueMenu}
                        options={
                          valueDataMenu.length === 0
                            ? []
                            : valueDataMenu.map((item) => item.nameChild)
                        }
                        sx={{ width: 300 }}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            const qr = valueDataMenu.find(
                              (x) => x.nameChild === newValue
                            ).codeChild;
                            fetchDataFill(qr);

                            setValueMenu(newValue);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Menu" />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <div style={{ display: "flex" }}>
                        <div style={{ marginRight: "8px" }}>
                          <div>Giá</div>
                          <NumericFormat
                            value={valuePrice}
                            thousandSeparator={true}
                            defaultValue="0"
                            style={{ width: "100%", fontFamily: "roboto" }}
                            onChange={(event) => {
                              setValuePrice(event.target.value);
                            }}
                          />
                        </div>
                        <div>
                          <div>Thương hiệu</div>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            size="small"
                            value={valueBrand}
                            options={
                              valueDataBrand.length === 0
                                ? []
                                : valueDataBrand.map((item) => item.name)
                            }
                            sx={{ width: 300 }}
                            onChange={(event, newValue) => {
                              setValueBrand(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Thương hiệu"
                              />
                            )}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <div style={{ height: "10px" }}></div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={5}>
                      <div style={{ display: "flex" }}>
                        <div style={{ marginRight: "8px" }}>
                          <div>Chất lượng</div>
                          <TextField
                            id="outlined-basic"
                            size="small"
                            value={valueQuality}
                            placeholder="Chất lượng"
                            variant="outlined"
                            style={{ marginRight: "8px", width: "100%" }}
                            onChange={(event) => {
                              setValueQuality(event.target.value);
                            }}
                          />
                        </div>
                        <div>
                          <div>Bảo hành</div>
                          <TextField
                            id="outlined-basic"
                            size="small"
                            value={valueBH}
                            placeholder="Bảo hành"
                            variant="outlined"
                            style={{ marginRight: "8px", width: "100%" }}
                            onChange={(event) => {
                              setValueBH(event.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <div style={{ display: "flex" }}>
                        <div style={{ marginRight: "8px" }}>
                          <div>Chứng chỉ</div>
                          <TextField
                            id="outlined-basic"
                            size="small"
                            value={valueChungChi}
                            placeholder="Chứng chỉ"
                            variant="outlined"
                            onChange={(event) => {
                              setValueChungChi(event.target.value);
                            }}
                            style={{ marginRight: "8px", width: "100%" }}
                          />
                        </div>
                        {/* <div>
                        <div>Thương hiệu</div>
                        <TextField
                            id="outlined-basic"
                            size="small"
                            placeholder="Giá"
                            variant="outlined"
                            style={{ marginRight: "8px", width: "100%" }}
                          />
                        </div> */}
                      </div>
                    </Grid>
                  </Grid>
                  <div style={{ marginTop: "10px" }}>
                    {/* <Grid container spacing={2}>
                      {valueFill.length === 0 ? (
                        <div></div>
                      ) : (
                        valueFill.map((item) =>
                          item.fillter === "thuong-hieu" ? (
                            <></>
                          ) : (
                            <Grid item xs={12} md={4}>
                              <div>
                                <div>{item.nameFill}</div>
                                <TextField
                                  id="outlined-basic"
                                  size="small"
                                  value={
                                 
                                    productList.find(
                                      (itemfill) =>
                                        itemfill.type === item.fillter
                                    ).value
                                  }
                                  placeholder={item.nameFill}
                                  variant="outlined"
                                  onChange={(event) => {
                                    handleInputChangeFill(
                                      item.fillter,
                                      event.target.value
                                    );
                                  }}
                                  style={{ marginRight: "8px", width: "100%" }}
                                />
                              </div>
                            </Grid>
                          )
                        )
                      )}
                    </Grid> */}
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <div>Dịch vụ kèm theo</div>
                    <ReactQuill
                      value={content}
                      onChange={handleChange}
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, false] }],
                          [
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "blockquote",
                          ],
                          [{ list: "ordered" }, { list: "bullet" }],
                          ["link", "image"],
                          ["clean"],
                        ],
                      }}
                      formats={[
                        "header",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "blockquote",
                        "list",
                        "bullet",
                        "link",
                        "image",
                      ]}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
            <div style={{ marginBottom: "10px", marginTop: "30px" }}>
              <div>
                <p>Description</p>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  size="small"
                  placeholder="description"
                  variant="outlined"
                  value={valueDescription}
                  onChange={(e)=>{
                    setValueDescription(e.target.value);
                  }}
                />
              </div>
              <div>
                <p>Keywords</p>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  size="small"
                  placeholder="keywords"
                  variant="outlined"
                  value={valueKeywords}
                  onChange={(e)=>{
                    setValueKeyword(e.target.value);
                  }}
                />
              </div>
              <p>Mô tả </p>
              <ReactQuill
                ref={quillRef}
                value={contentDescribe}
                onChange={handleChangeDes}
                modules={{
                  toolbar: [
                    [{ header: [1, 2, false] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["link", "image"],
                    ["clean"],
                  ],
                }}
                formats={[
                  "header",
                  "bold",
                  "italic",
                  "underline",
                  "strike",
                  "blockquote",
                  "list",
                  "bullet",
                  "link",
                  "image",
                ]}
              />
            </div>
            {/* <div>
              <div>Thông tin bổ sung</div>
              <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    value={valueBH}
                    placeholder="Xuất xứ"
                    variant="outlined"
                    style={{ marginRight: "8px", width: "100%" }}
                  />
                </Grid>
              </Grid>
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
};
export default ProductAdminPage;
