/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { CardHeader } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";

const LoginAdminPage = () => {
  const [valueThongbao, setValueThongbao] = useState("");
  const [valueUser, setValueUser] = useState("");
  const [valuePass, setValuePass] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    fetchDataMenu();
  }, []);

  const fetchDataMenu = async () => {
    try {
      const response = await fetch(
        "https://codiendonga.com.vn/API/Menu/select.php"
      );
      const result = await response.json();
      console.log(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const select = async () => {
    const bodyData = {
      user: valueUser,
      pass: valuePass,
    };
    fetch("https://codiendonga.com.vn/API/Login/select.php", {
      method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
      headers: {
        "Content-Type": "application/json", // hoặc 'application/x-www-form-urlencoded', ...
        // Nếu cần thêm các header khác, thêm vào đây
      },
      body: JSON.stringify(bodyData), // Đảm bảo chuyển đổi đối tượng dữ liệu thành chuỗi JSON nếu bạn sử dụng 'application/json'
    })
      .then((response) => response.json()) // Nếu server trả về JSON, chuyển đổi response thành đối tượng JavaScript
      .then((data) => {
        const qr = data["message"];
        if (qr !== "Error") {
          localStorage.setItem("tm-login", qr);
          navigate("/admin");
        } else {
          setValueThongbao("Tên đăng nhập hoặc mật khẩu không đúng");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="page-login">
      <Card sx={{ width: 350 }}>
        <CardContent>
          <div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                fontWeight: 700,
                fontSize: 22,
              }}
            >
              ĐĂNG NHẬP
            </div>
            <div style={{ marginTop: "20px" }}>
              <div>Tên đăng nhập</div>
              <TextField
                id="outlined-basic"
                size="small"
                fullWidth
                value={valueUser}
                placeholder="Tên đăng nhập"
                variant="outlined"
                onChange={(event) => {
                  setValueUser(event.target.value);
                }}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <div>Mật khẩu</div>
              {/* <TextField
                id="outlined-basic"
                size="small"
                fullWidth
                placeholder="Mật khẩu"
                variant="outlined"
                value={valuePass}
                onChange={(event)=>{
                    setValuePass(event.target.value);
                }}
              /> */}
              <FormControl sx={{ m: 0, width: "100%" }} variant="outlined">
                {/* <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel> */}
                <OutlinedInput
                  size="small"
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Mật khẩu"
                  value={valuePass}
                  onChange={(event)=>{
                    setValuePass(event.target.value);
                }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                height: "20px",
                color: "red",
              }}
            >
              {valueThongbao}
            </div>
            <div
              style={{
                marginTop: "15px",
                display: "flex",
                width: "100%",
                justifyContent: "center",
                fontWeight: 700,
                fontSize: 22,
              }}
            >
              <Button variant="contained" onClick={select}>
                Đăng nhập
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
export default LoginAdminPage;
