import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './home';
import ProductPage from './product';
import BrandPage from './brand';
import ProductDetailPage from './ProductDetail';
import AdminPage from './Admin';
import SupportPolicyPage from './support-policy';
import ShoppingCartPage from './shoppingcart';
import BrandDetailPage from './brand-detail';
import CategoryPage from './category';
import LoginAdminPage from './Admin/login';
import LionPage from './lion';
import FinishPage from './finish';
import BaiVietPage from './baiviet';
import BaiVietDetailPage from './baiviet-detail';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/admin/login" element={<LoginAdminPage />} />
        <Route path="/thuong-hieu" element={<BrandPage />} />
        <Route path="/cart" element={<ShoppingCartPage />} />
        <Route path="/category/:parama" element={<CategoryPage />} />
        <Route path="/:productmenu/:param2" element={<ProductPage />} />
        <Route path="/product/:param2" element={<ProductDetailPage />} />
        <Route path="/:support" element={<SupportPolicyPage />} />
        <Route path="/thuong-hieu/:brand" element={<BrandDetailPage />} />
        <Route path="dien-dan-dung/day-cap-dien-daphaco" element={<LionPage />} />
        <Route path="/order/finish/:data" element={<FinishPage />} />
        <Route path="/bai-viet" element={<BaiVietPage />} />
        <Route path="/bai-viet/:code" element={<BaiVietDetailPage />} />
      </Routes>
    </Router>
    
  );
}

export default App;
