import "../App.css";
import React,{useState,useRef} from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import CSS
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import TextField from "@mui/material/TextField";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const Alert = React.forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const linkData = [
  {
    link: "cam-ket-chat-luong",
    value: "Cam kết chất lượng",
    id: 0,
  },
  {
    link: "huong-dan-dat-hang",
    value: "Hướng dẫn đặt hàng",
    id: 1,
  },
  {
    link: "loi-ich-khi-mua-hang",
    value: "Lợi ích khi mua hàng",
    id: 2,
  },
  {
    link: "van-chuyen-va-giao-nhan",
    value: "Vận chuyển và giao nhận",
    id: 3,
  },
  {
    link: "hinh-thuc-thanh-toan",
    value: "Hình thức thanh toán",
    id: 4,
  },
  {
    link: "lien-he",
    value: "Liên hệ",
    id: 5,
  },
];
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const SupportPage = () => {
  const [value, setValue] = React.useState(0);
  const [valuecontent, setValueContent] = React.useState("");
  const [valueKeyWord, setValueKeyWord] = React.useState("");
  const [valueDescript, setValueDescript] = React.useState("");
  const [openLoadImg, setOpenLoadImg] = React.useState(false);
  const [valueAltDetail, setValueDetail] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const openImageDialog = () => {
     setOpenLoadImg(true);
     //setIsImageLoading(true);
     console.log("Open image dialog");
   };
  const [modules, setModules] = React.useState({
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        ["clean"],
        [{ align: [] }],
      ],
      handlers: {
        image: openImageDialog,
      },
    },
  });
  const quillRef = useRef(null);
  const handleChangeTab = (event, newValue) => {
    setValueContent("");
    getdata(newValue);
    setValue(newValue);
  };

  const getdata = (valueLink) => {
    const foundItem = linkData.find((item) => item.id === valueLink).link;
    const url = "https://codiendonga.com.vn/API/Support/select.php";

    const bodydata = {
      link: foundItem,
    };

    fetch(url, {
      method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
      body: JSON.stringify(bodydata), // Đảm bảo chuyển đổi đối tượng dữ liệu thành chuỗi JSON nếu bạn sử dụng 'application/json'
    })
      .then((response) => response.json()) // Nếu server trả về JSON, chuyển đổi response thành đối tượng JavaScript
      .then((data) => {
        setValueContent(data[0].content);
        setValueKeyWord(data[0].Keywords);
        setValueDescript(data[0].description);
        console.log("Server response:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const save = () => {
    const foundItem = linkData.find((item) => item.id === value).link;
    const url = "https://codiendonga.com.vn/API/Support/add.php";
    const formData = new FormData();
    formData.append("link", foundItem);
    formData.append("content", valuecontent);
    formData.append("keyword", valueKeyWord);
    formData.append("description", valueDescript);

    fetch(url, {
      method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
      body: formData, // Đảm bảo chuyển đổi đối tượng dữ liệu thành chuỗi JSON nếu bạn sử dụng 'application/json'
    })
      .then((response) => response.json()) // Nếu server trả về JSON, chuyển đổi response thành đối tượng JavaScript
      .then((data) => {
        console.log("Server response:", data);
        setOpen(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleChange = (value) => {
    setValueContent(value);
  };
  
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [selectedImage1, setSelectedImage1] = useState(null);
  // React.useEffect(() => {
  //   getdata("dieu-khoan-su-dung");
  //   setModules((prevModules) => ({
  //     ...prevModules,
  //     toolbar: {
  //       ...prevModules.toolbar,
  //       handlers: {
  //         ...prevModules.toolbar.handlers,
  //         image: openImageDialog,
  //       },
  //     },
  //   }));
  // }, [setIsImageLoading]);
  const [selectedPathImage1, setSelectedPathImage1] = useState(null);

  
  const handleCloseImg = () => {
    setOpenLoadImg(false);
  };
  const handleImageChangeDetail = (e) => {
    const file = e.target.files[0];

    if (file) {
      setSelectedPathImage1(file);
      yourImageUploadFunction(file);
      const reader = new FileReader();

      reader.onload = () => {
        setSelectedImage1(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };
  const [valuePath, setValuePath] = useState("");
  const yourImageUploadFunction = async (file) => {
    const formData = new FormData();
    formData.append("image", file);

    fetch("https://codiendonga.com.vn/API/Post/uploadImg.php", {
      method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
      body: formData, // Đảm bảo chuyển đổi đối tượng dữ liệu thành chuỗi JSON nếu bạn sử dụng 'application/json'
    })
      .then((response) => response.text()) // Nếu server trả về JSON, chuyển đổi response thành đối tượng JavaScript
      .then((data) => {
        console.log("Server response:", data);
        setValuePath(data.trim());
        return data;
      })
      .catch((error) => {
        console.error("Error:", error);
        return;
      });
  };
  const uploadImage = async () => {
    // const formData = new FormData();
    // formData.append('image', selectedPathImage1);
    try {
      // // Thực hiện logic tải ảnh lên máy chủ và nhận URL
      const imageUrl = "https://codiendonga.com.vn" + valuePath;

      // Thêm ảnh vào nội dung của ReactQuill với thuộc tính alt
      // const cursorPosition = quillRef.current.getEditor().getSelection().index;
      const quill = quillRef.current && quillRef.current.getEditor();
      // const cursorPosition  =0;
      if (quill) {
        const imageElement = `<p><img src="${imageUrl}" alt="${valueAltDetail}" style="display: block; margin: 0 auto;" /></p>`;
        // setValueimg(imageElement);
        //const delta = quill.clipboard.convert(imageElement);
        let cursorPosition = quill.getSelection()?.index || 0;

        if (cursorPosition === 0) {
          cursorPosition = valuecontent.length;
        }

        // quill.updateContents(delta, 'user');
        quillRef.current
          .getEditor()
          .clipboard.dangerouslyPasteHTML(cursorPosition, imageElement);
        setValueDetail("");
        setSelectedImage1(null);
        // Rest of your logic
      }

      setOpenLoadImg(false);
      setIsImageLoading(false);
    } catch (error) {
      console.error("Error:", error);
      return;
    }
  };
  return (
    <div className="page-admin">
      <Box
        sx={{
          flexGrow: 1,
          maxWidth: { xs: 320, sm: "100%" },
          bgcolor: "background.paper",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChangeTab}
          variant="scrollable"
          scrollButtons
          aria-label="visible arrows tabs example"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              "&.Mui-disabled": { opacity: 0.3 },
            },
          }}
        >
          <Tab
            label="Cam kết chất lượng"
            style={{ padding: "12px 7px", fontSize: "13px" }}
          />
          <Tab
            label="Hướng dẫn đặt hàng"
            style={{ padding: "12px 7px", fontSize: "13px" }}
          />
          <Tab
            label="Lợi ích khi mua hàng"
            style={{ padding: "12px 7px", fontSize: "13px" }}
          />
          <Tab
            label="Vận chuyển và giao nhận"
            style={{ padding: "12px 7px", fontSize: "13px" }}
          />
          <Tab
            label="Hình thức thanh toán"
            style={{ padding: "12px 7px", fontSize: "13px" }}
          />
          <Tab
            label="Liên hệ"
            style={{ padding: "12px 7px", fontSize: "13px" }}
          />
        </Tabs>
      </Box>
      <div style={{ marginTop: "5px" }}>
        <div className="cls-btn-save">
          <button className="btn-save" onClick={save}>
            Lưu
          </button>
        </div>
        <p>Keywords</p>
          <TextField
            id="outlined-basic"
            placeholder="Keywords"
            variant="outlined"
            size="small"
            style={{ width: "95%" }}
            value={valueKeyWord}
            onChange={(e) => {
              setValueKeyWord(e.target.value);
            }}
          />
          <p>Descriptions</p>
          <TextField
            id="outlined-basic"
            placeholder="Descriptions"
            variant="outlined"
            size="small"
            multiline
            rows={4}
            style={{ width: "95%",marginBottom:'10px' }}
            value={valueDescript}
            onChange={(e) => {
              setValueDescript(e.target.value);
            }}
          />
        <ReactQuill
            ref={quillRef}
            value={valuecontent}
            onChange={handleChange}
            style={{ height: "100vh", minHeight: "100%" }}
            modules={modules}
            formats={[
              "header",
              "bold",
              "italic",
              "underline",
              "strike",
              "blockquote",
              "list",
              "bullet",
              "link",
              "image",
              "align",
            ]}
          />
      </div>
      <Dialog
        // fullWidth ={}
        // maxWidth
        open={openLoadImg}
        onClose={handleCloseImg}
      >
        <DialogTitle>Load ảnh</DialogTitle>
        <DialogContent>
          <div style={{ height: "250px", marginTop: "10px", width: "350px" }}>
            <img
              src={
                selectedImage1 === null
                  ? "https://codiendonga.com.vn/Image/No-Img-DongA.png"
                  : selectedImage1
              }
              alt="Preview"
              style={{ width: "100%", height: "240px" }}
            />
          </div>
          <Button
            component="label"
            variant="contained"
            style={{ width: "100%" }}
            startIcon={<CloudUploadIcon />}
          >
            Upload ảnh
            <VisuallyHiddenInput
              type="file"
              onChange={handleImageChangeDetail}
            />
          </Button>
          <div style={{ height: "20px" }}></div>
          <p>Alt hình ảnh</p>
          <TextField
            id="outlined-basic"
            placeholder="Alt hình ảnh"
            variant="outlined"
            size="small"
            multiline
            rows={2}
            style={{ width: "100%" }}
            value={valueAltDetail}
            onChange={(e) => {
              setValueDetail(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={uploadImage}>OK</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Thành công!
        </Alert>
      </Snackbar>
    </div>
  );
};
export default SupportPage;
