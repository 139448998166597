import "../App.css";
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const MenuPage = () => {
  const [valueTab, setValueTab] = React.useState("1");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [valueMenu, setValueMenu] = React.useState("");
  const [valueMenuSave, setValueMenuSave] = React.useState("");
  const [valueDataMenu, setValueDataMenu] = useState([]);
  const [openDialogChild, setOpenDialogChild] = React.useState(false);
  const [valueCha,setValueCha] = React.useState("");
  const [valueMenuUpdate, setValueMenuUpdate] =React.useState("");
  const [openDialogUpdate, setOpenDialogUpdate] = React.useState(false);
  
  const [valueChaUpdate,setValueChaUpdate] = React.useState("");
  const [openChildUpdate ,setopenChildUpdate]= React.useState("");
  const [valueCode , setValueCode]= React.useState("");

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleCloseDialogChild = () => {
    setOpenDialogChild(false);
  };
  const handleClickOpenDialogChild = () => {
    setOpenDialogChild(true);
  };
  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };

  const handleCloseDialogUpdate = () => {
    setOpenDialogUpdate(false);
  };

  const save = () => {
    setOpenDialog(false);
    const convertedString = valueMenuSave.toLowerCase().replace(/ /g, "-");
    const bodydata = {
      code: convertedString,
      name: valueMenu,
    };
    fetch("https://codiendonga.com.vn/API/Menu/create.php", {
      method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
      headers: {
        "Content-Type": "application/json", // hoặc 'application/x-www-form-urlencoded', ...
        // Nếu cần thêm các header khác, thêm vào đây
      },
      body: JSON.stringify(bodydata), // Đảm bảo chuyển đổi đối tượng dữ liệu thành chuỗi JSON nếu bạn sử dụng 'application/json'
    })
      .then((response) => response.json()) // Nếu server trả về JSON, chuyển đổi response thành đối tượng JavaScript
      .then((data) => {
        console.log("Server response:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  const saveChild=()=>{
    const convertedString = valueMenuSave.toLowerCase().replace(/ /g, "-");
    const bodydata = {
      code: convertedString,
      name: valueMenu,
      father:valueCode,
    };
    fetch("https://codiendonga.com.vn/API/Menu/addChild.php", {
      method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
      headers: {
        "Content-Type": "application/json", // hoặc 'application/x-www-form-urlencoded', ...
        // Nếu cần thêm các header khác, thêm vào đây
      },
      body: JSON.stringify(bodydata), // Đảm bảo chuyển đổi đối tượng dữ liệu thành chuỗi JSON nếu bạn sử dụng 'application/json'
    })
      .then((response) => response.json()) // Nếu server trả về JSON, chuyển đổi response thành đối tượng JavaScript
      .then((data) => {
        setOpen(true);
        setOpenDialogChild(false);
        console.log("Server response:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  const deleteChild =( event, newValue)=>{
    const code = newValue.codeChild;
    const father = newValue.code;
    const bodydata = {
      codechild: code,
      father:father,
    };
    fetch("https://codiendonga.com.vn/API/Menu/deleteChild.php", {
      method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
      headers: {
        "Content-Type": "application/json", // hoặc 'application/x-www-form-urlencoded', ...
        // Nếu cần thêm các header khác, thêm vào đây
      },
      body: JSON.stringify(bodydata), // Đảm bảo chuyển đổi đối tượng dữ liệu thành chuỗi JSON nếu bạn sử dụng 'application/json'
    })
      .then((response) => response.json()) // Nếu server trả về JSON, chuyển đổi response thành đối tượng JavaScript
      .then((data) => {
        setOpen(true);
        fetchDataMenu();
        console.log("Server response:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
const update=()=>{
  const bodydata = {
    code: valueChaUpdate,
    name: valueMenuUpdate,
  };
  fetch("https://codiendonga.com.vn/API/Menu/update.php", {
    method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
    headers: {
      "Content-Type": "application/json", // hoặc 'application/x-www-form-urlencoded', ...
      // Nếu cần thêm các header khác, thêm vào đây
    },
    body: JSON.stringify(bodydata), // Đảm bảo chuyển đổi đối tượng dữ liệu thành chuỗi JSON nếu bạn sử dụng 'application/json'
  })
    .then((response) => response.json()) // Nếu server trả về JSON, chuyển đổi response thành đối tượng JavaScript
    .then((data) => {
      setOpenDialogUpdate(false);
      setOpen(true);
      console.log("Server response:", data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

  useEffect(() => {
    fetchDataMenu();
  }, []);
  const fetchDataMenu = async () => {
    try {
      const response = await fetch(
        "https://codiendonga.com.vn/API/Menu/select.php"
      );
      const result = await response.json();
      console.log(result);
      setValueDataMenu(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const showEdit = (event, newValue) => {
    const qr = newValue.nameMenu;

    setValueMenu(newValue.nameChild);
    setValueCha(newValue.nameMenu);
    setopenChildUpdate(true);
  };

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const handleCloseChild =()=>{
    setopenChildUpdate(false);
  }
  return (
    <div className="page-admin">
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={valueTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Menu cha" value="1" />
              <Tab label="Menu con" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1" style={{ padding: 7 }}>
            <div>
              <div style={{ display: "flex", marginRight: "10px" }}>
                <div style={{ flexGrow: 1 }}>Danh mục cha</div>
                <button className="bt-add" onClick={handleClickOpenDialog}>
                  Thêm
                </button>
              </div>
              <table className="custom-border">
                <thead>
                  <tr>
                    <th className="custom-border">STT</th>
                    <th className="custom-border">Name</th>
                    <th className="custom-border" style={{ width: "27%" }}>
                      Hành động
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {valueDataMenu.length === 0 ? (
                    <div></div>
                  ) : (
                    valueDataMenu.map((item) => (
                      <tr key={"key" + item.id}>
                        <td className="custom-border">{item.id}</td>
                        <td>{item.nameMenu}</td>
                        <td>
                          <div>
                            <button className="bt-menu-edit" onClick={(e)=>{
                              setValueChaUpdate(item.code);
                              setValueMenuUpdate(item.nameMenu);
                              setOpenDialogUpdate(true);
                            }}>Edit</button>
                            <button className="bt-menu-delete">Delete</button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </TabPanel>
          <TabPanel value="2" style={{ padding: 7 }}>
            <div>
              <div style={{ display: "flex", marginRight: "10px" }}>
                <div style={{ flexGrow: 1 }}>Danh mục con</div>
                <button className="bt-add" onClick={handleClickOpenDialogChild}>
                  Thêm
                </button>
              </div>
              <table className="custom-border">
                <thead>
                  <tr>
                    <th className="custom-border">STT</th>
                    <th className="custom-border">Danh mục cha</th>
                    <th className="custom-border">Danh mục con</th>
                    <th className="custom-border" style={{ width: "27%" }}>
                      Hành động
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="custom-border">1</td>
                    <td className="custom-border">Thương hiệu</td>
                    <td></td>
                    <td>
                      <div>
                        <button className="bt-menu-edit">Edit</button>
                        <button className="bt-menu-delete">Delete</button>
                      </div>
                    </td>
                  </tr>
                  {valueDataMenu.length === 0 ? (
                    <div></div>
                  ) : (
                    valueDataMenu.map((item) => (
                      <React.Fragment key={"key" + item.id}>
                        <tr>
                          <td
                            class="custom-border"
                            {...(item.child.length > 0
                              ? { rowspan: item.child.length }
                              : {})}
                          >
                            {item.id + 1}
                          </td>
                          <td
                            class="custom-border"
                            {...(item.child.length > 0
                              ? { rowspan: item.child.length }
                              : {})}
                          >
                            {item.nameMenu}
                          </td>
                          {item.child.length === 0 ? (
                            <td></td>
                          ) : (
                            <td class="custom-border">
                              {item.child[0].nameChild}
                            </td>
                          )}

                          <td>
                            <div>
                              <button
                                className="bt-menu-edit"
                                onClick={(event) => {
                                  showEdit(event, item);
                                }}
                              >
                                Edit
                              </button>
                              <button className="bt-menu-delete">Delete</button>
                            </div>
                          </td>
                        </tr>
                        {item.child.slice(1).map((row) => (
                          <tr key={row.codeChild}>
                            <td>{row.nameChild}</td>
                            <td>
                              <div>
                                <button className="bt-menu-edit" onClick={(event) => {
                                  showEdit(event, row);
                                }}>Edit</button>
                                <button className="bt-menu-delete" onClick={(event)=>{
                                  deleteChild(event, row);
                                }}>
                                  Delete
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </React.Fragment>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </TabPanel>
        </TabContext>
      </Box>

      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Menu cha
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div>
            <TextField
              size="small"
              style={{ width: "300px" }}
              id="outlined-basic"
              placeholder="Tên menu"
              variant="outlined"
              value={valueMenu}
              onChange={(event) => {
                setValueMenu(event.target.value);
                const qr = event.target.value
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "");
                setValueMenuSave(qr);
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={save}>
            Lưu
          </Button>
        </DialogActions>
      </BootstrapDialog>

      {/* Menu con */}
      <BootstrapDialog
        onClose={handleCloseDialogChild}
        aria-labelledby="customized-dialog-title"
        open={openDialogChild}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Menu con
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialogChild}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers style={{ height: "180px" }}>
          <div>
            <div>Menu cha</div>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              getOptionLabel={(option) => option.nameMenu}
              options={valueDataMenu}
              sx={{ width: 300 }}
              size="small"
              onChange={(e,newvalue)=>{
                setValueCode(newvalue.code);
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Menu cha"/>
              )}
            />
            <div style={{ width: "300px", marginTop: "10px" }}>Menu con</div>
            <TextField
              size="small"
              style={{ width: "300px" }}
              id="outlined-basic"
              placeholder="Tên menu"
              variant="outlined"
              value={valueMenu}
              onChange={(event) => {
                setValueMenu(event.target.value);                const qr = event.target.value
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "");
                setValueMenuSave(qr);
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={saveChild}>
            Lưu
          </Button>
        </DialogActions>
      </BootstrapDialog>


      {/* update */}
      <BootstrapDialog
        onClose={handleCloseDialogUpdate}
        aria-labelledby="customized-dialog-title"
        open={openDialogUpdate}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Menu cha
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialogUpdate}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div>
            <TextField
              size="small"
              style={{ width: "300px" }}
              id="outlined-basic"
              placeholder="Tên menu"
              variant="outlined"
              value={valueMenuUpdate}
              onChange={(event) => {
                setValueMenuUpdate(event.target.value);

               
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={update}>
            Lưu
          </Button>
        </DialogActions>
      </BootstrapDialog>


      <BootstrapDialog
        onClose={handleCloseChild}
        aria-labelledby="customized-dialog-title"
        open={openChildUpdate}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Menu con
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseChild}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers style={{ height: "180px" }}>
          <div>
            <div>Menu cha</div>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
             // getOptionLabel={(option) => option.nameMenu}
              options={valueDataMenu}
              sx={{ width: 300 }}
              size="small"
              value={valueCha}
              renderInput={(params) => (
                <TextField {...params} placeholder="Menu cha"/>
              )}
            />
            <div style={{ width: "300px", marginTop: "10px" }}>Menu con</div>
            <TextField
              size="small"
              style={{ width: "300px" }}
              id="outlined-basic"
              placeholder="Tên menu"
              variant="outlined"
              value={valueMenu}
              onChange={(event) => {
                setValueMenu(event.target.value);
                const qr = event.target.value
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "");
                setValueMenuSave(qr);
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={save}>
            Lưu
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Thành công!
        </Alert>
      </Snackbar>
    </div>
  );
};
export default MenuPage;
