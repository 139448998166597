/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid";
import Badge from "@mui/material/Badge";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { myStateAtom } from "./recoilAtoms.js";
import { useRecoilValue } from "recoil";
import { menuStateAtom } from "./recoilMenu.js";
import { useParams } from "react-router-dom";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import zaloIcon from "../src/Image/zalo-icon.png";
import faceIcon from "../src/Image/face.png";
import phoneIcon from "../src/Image/phone.png";
import logo from "../src/Image/co-dien-dong-a-color-logo.png";
import { Helmet } from "react-helmet";
const actions = [
  {
    icon: <img src={zaloIcon} alt="Zalo" width={32} />,
    name: "Zalo",
    link: "https://zalo.me/0987289556",
  },
  {
    icon: <img src={faceIcon} alt="messenge" width={38} />,
    name: "Messenger",
    link: "https://m.me/100084688140473",
  },
  {
    icon: <img src={phoneIcon} alt="phone" width={30} />,
    name: "Gọi điện",
    link: "tel:0974640477",
  },
];
const formatter = new Intl.NumberFormat("vi-VN", {
  style: "currency",
  currency: "VND",
});
function ProductDetailPage() {
  const { productmenu, param2 } = useParams();
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const getValueMenu = useRecoilValue(menuStateAtom);
  const [valueSearcData, setValueSearchData] = useState([]);
  const navigate = useNavigate();
  const [valueData, setValueData] = useState([]);
  const [valueDataMenu, setValueDataMenu] = useState([]);
  const [cart, setCart] = useState([]);
  const [valueDataOld, setValueDataOld] = useRecoilState(myStateAtom);
  const [valueItemListIndex, setValueItemListIndex] = React.useState(0);
  const [valueMenuOld, setValueMenuOld] = useRecoilState(menuStateAtom);
  const [valueDataInfo, setValueDataInfo] = useState([]);
  const [valueQty, setValueQty] = useState(1);
  const [valueHotline, setValueHotline] = React.useState("");
  const [valueNam, setValueNam] = React.useState("");
  const [valueBac, setValueBac] = React.useState("");
  const [valueTrung, setValueTrung] = React.useState("");
  const [valueKT, setValueKT] = React.useState("");
  const nextPage = (path) => {
    navigate(path);
    // history.push(path);
  };
  const addToCart = (event, product) => {
    const existingItemIndex = cart.findIndex(
      (item) => item.codeTM === product.codeTM
    );

    if (existingItemIndex !== -1) {
      // If the product already exists in the cart, increase the quantity
      const updatedCart = [...cart];
      updatedCart[existingItemIndex].quantity += parseInt(valueQty);
      setCart(updatedCart);
      localStorage.setItem("tm-shopping-cart", JSON.stringify(updatedCart));
    } else {
      // If the product doesn't exist in the cart, add it with quantity 1
      const dataCart = [...cart, { ...product, quantity: parseInt(valueQty) }];
      localStorage.setItem("tm-shopping-cart", JSON.stringify(dataCart));
      setCart(dataCart);
    }
  };
  useEffect(() => {
    const storedData = localStorage.getItem("tm-shopping-cart");
    if (storedData) {
      setCart(JSON.parse(storedData));
    }
    getdataPhone();
    getValueMenu.length > 0 ? setValueDataMenu(getValueMenu) : fetchDataMenu();

    fetchData();
    fetchZalo();
  }, [cart]);
  const fetchDataMenu = async () => {
    try {
      const response = await fetch(
        "https://codiendonga.com.vn/API/Menu/select.php"
      );
      const result = await response.json();
      setValueDataMenu(result);
      setValueMenuOld(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const [valueZalo, setvalueZalo] = React.useState([]);
  const fetchZalo = async () => {
    try {
      const response = await fetch(
        "https://codiendonga.com.vn/API/Zalo/select.php"
      );
      const result = await response.json();
      setvalueZalo(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    const url = "https://codiendonga.com.vn/API/Product/selectProductID.php";
    const bodyData = {
      code: param2,
    };
    fetch(url, {
      method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
      headers: {
        "Content-Type": "application/json", // hoặc 'application/x-www-form-urlencoded', ...
        // Nếu cần thêm các header khác, thêm vào đây
      },

      body: JSON.stringify(bodyData), // Đảm bảo chuyển đổi đối tượng dữ liệu thành chuỗi JSON nếu bạn sử dụng 'application/json'
    })
      .then((response) => response.json()) // Nếu server trả về JSON, chuyển đổi response thành đối tượng JavaScript
      .then((data) => {
        setValueData(data);
        const dataInfo = data[0].dataInfo;
        setValueDataInfo(dataInfo);
        console.log("Server response:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const numberWithVNDFormat = (number) => {
    return number.toLocaleString("vi-VN", {
      style: "currency",
      currency: "VND",
    });
  };
  const [openDraw, setOpenDraw] = useState(false);
  const eventOpenDraw = () => {
    setOpenDraw(true);
  };
  const fetchDataHome = async () => {
    try {
      const response = await fetch(
        "https://codiendonga.com.vn/API/Product/select.php"
      );
      const result = await response.json();
      setValueData(result);
      setValueDataOld(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const closeDraw = () => {
    setOpenDraw(false);
  };
  const [openMenuChildren, setOpenMenuChildren] = React.useState(false);
  const handleClickCLose = () => {
    setOpenMenuChildren(!openMenuChildren);
  };
  const getdataPhone = () => {
    const url = "https://codiendonga.com.vn/API/Contact/select.php";
    fetch(url, {
      method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
    })
      .then((response) => response.json()) // Nếu server trả về JSON, chuyển đổi response thành đối tượng JavaScript
      .then((data) => {
        setValueHotline(data[0].phone_Hotline);
        setValueNam(data[0].phone_Nam);
        setValueBac(data[0].phone_Bac);
        setValueTrung(data[0].phone_Trung);
        setValueKT(data[0].phone_KyThuat);
        console.log("Server response:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const searchData = async (data) => {
    if (data !== "") {
      const bodydata = {
        code: data,
      };
      fetch("https://codiendonga.com.vn/API/Product/findItem.php", {
        method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
        headers: {
          "Content-Type": "application/json", // hoặc 'application/x-www-form-urlencoded', ...
          // Nếu cần thêm các header khác, thêm vào đây
        },
        body: JSON.stringify(bodydata), // Đảm bảo chuyển đổi đối tượng dữ liệu thành chuỗi JSON nếu bạn sử dụng 'application/json'
      })
        .then((response) => response.json()) // Nếu server trả về JSON, chuyển đổi response thành đối tượng JavaScript
        .then((data) => {
          setValueSearchData(data);
          console.log("Server response:", data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setValueSearchData([]);
    }
  };
  const selectItemFind = (event, newData) => {
    const qr = newData.menuSon;
    navigate("/Product/" + newData.codeTM);
  };
  const sanitizeAndSetContent = (htmlContent) => {
    const doc = new DOMParser().parseFromString(htmlContent, "text/html");
    const images = doc.querySelectorAll("img");

    images.forEach((image) => {
      image.style.maxWidth = "100%";
      image.style.height = "auto";
    });

    return doc.body.innerHTML;
  };
  return (
    <div className="page">
      <Helmet>
        <title>
          {valueData.length === 0
            ? "Công ty TNHH TM Cơ điện đông á"
            : valueData[0].name}{" "}
          - codiendonga.com.vn
        </title>
        <meta
          name="description"
          content={
            valueData.length === 0
              ? "Công ty TNHH TM Cơ điện đông á, chuyên cung cấp và sửa chữa thiết bị điện công nghiệp, điện dân dụng, thiết bị tự động hóa chính hãng, chất lượng, giá rẻ , giao hàng toàn quốc"
              : valueData[0].description === ""
              ? valueData[0].name + "-codiendonga.com.vn"
              : valueData[0].description
          }
        />
        <meta
          name="keywords"
          content={
            valueData.length === 0
              ? "Cơ điện đông á, chuyên cung cấp thiết bị điện công nghiệp, điện dân dụng, thiết bị tự động hóa chất lượng, chính hãng , giá rẻ , giao hàng toàn quốc"
              : valueData[0].Keywords === ""
              ? valueData[0].name + "-codiendonga.com.vn"
              : valueData[0].Keywords
          }
        />
        <link
          rel="canonical"
          href={
            valueData.length === 0
              ? "https://codiendonga.com.vn"
              : `https://codiendonga.com.vn/Product/${valueData[0].codeTM}`
          }
        />
      </Helmet>
      {isDesktopOrLaptop === true && (
        <div>
          <Box sx={{ flexGrow: 1 }}>
            <AppBar
              position="static"
              style={{
                paddingLeft: "3%",
                paddingRight: "3%",
                backgroundColor: "teal",
              }}
            >
              <Toolbar>
                <img
                  src={logo}
                  width={70}
                  height={70}
                  alt="logo-codiendonga"
                  style={{ marginRight: "10px" }}
                />
                <h1
                  style={{ cursor: "pointer" }}
                  onClick={async () => {
                    fetchDataHome();
                    nextPage("/");
                  }}
                >
                  ĐÔNG Á
                </h1>
                <div
                  style={{
                    flexGrow: 1,
                    marginLeft: "45px",
                    marginRight: "45px",
                  }}
                >
                  <Autocomplete
                    freeSolo
                    size="small"
                    id="free-solo-2-demo"
                    disableClearable
                    sx={{ backgroundColor: "white", borderRadius: "80px" }}
                    getOptionLabel={(option) => option.name}
                    options={valueSearcData.length === 0 ? [] : valueSearcData}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          alt=""
                        />
                        <div style={{ marginRight: "15px" }}>{option.name}</div>{" "}
                        <div>{formatter.format(parseFloat(option.price))}</div>
                      </Box>
                    )}
                    onChange={(event, newValue) => {
                      selectItemFind(event, newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Nhập tìm sản phẩm"
                        style={{ borderRadius: "60px" }}
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                          style: {
                            border: "none",
                            borderWidth: "0px",
                            borderRadius: "60px",
                          },
                          endAdornment: (
                            <IconButton>
                              <SearchIcon />
                            </IconButton>
                          ),
                        }}
                        onChange={(event) => {
                          console.log(event.target.value);
                          searchData(event.target.value);
                        }}
                      />
                    )}
                  />
                </div>

                <div style={{ fontSize: "20px", marginRight: "10px" }}>
                  <Chip
                    label={`Hotline ${valueHotline}`}
                    style={{
                      color: "black",
                      fontSize: "22px",
                      backgroundColor: "#FFC000",
                      padding: "5px",
                    }}
                  />
                </div>
                <div
                  className="shopping-style"
                  onClick={() => {
                    navigate("/cart");
                  }}
                >
                  <Badge badgeContent={cart.length} color="error">
                    <ShoppingCartOutlinedIcon style={{ color: "white" }} />
                  </Badge>
                </div>
              </Toolbar>
            </AppBar>
          </Box>
          <div className="menu-header">
            <ul>
              <li className="dropdown">
                <a
                  class="dropbtn"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    nextPage("/thuong-hieu");
                  }}
                >
                  Thương hiệu
                </a>
              </li>
              {valueDataMenu.length === 0 ? (
                <div></div>
              ) : (
                valueDataMenu.map((menu) => (
                  <li className="dropdown">
                    <a
                      class="dropbtn"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        nextPage(menu.child.length === 0 ? menu.code : "");
                      }}
                    >
                      {menu.nameMenu}
                    </a>
                    <div className="dropdown-content">
                      {menu.child.map((item) => (
                        <a
                          onClick={() => {
                            nextPage("/" + item.code + "/" + item.codeChild);
                          }}
                        >
                          {item.nameChild}
                        </a>
                      ))}
                    </div>
                  </li>
                ))
              )}
            </ul>
          </div>
          <div
            style={{
              backgroundColor: "white",
              marginLeft: "5%",
              marginRight: "5%",
              marginTop: "20px",
              paddingTop: "20px",
              paddingBottom: "20px",
              paddingLeft: "5px",
            }}
          >
            {valueData.length === 0 ? (
              <div></div>
            ) : (
              valueData.map((item) => (
                <div style={{ padding: "5px" }}>
                  <Grid container spacing={1}>
                    <Grid item xs={6} md={4}>
                      <img
                        src={item.img}
                        alt={item.name}
                        style={{
                          width: "95%",
                          marginTop: "-10px",
                          height: "300px",
                          marginLeft: "5px",
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <div>
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "blue",
                          }}
                        >
                          {item.name}
                        </div>
                        <div
                          style={{
                            fontSize: "18px",
                            fontWeight: 600,
                            color: "red",
                            marginTop: "15px",
                          }}
                        >
                          {item.price === "0"
                            ? "Liên hệ"
                            : numberWithVNDFormat(parseFloat(item.price))}
                        </div>
                        {item.upload === "0" ? (
                          <div>
                            <div style={{ marginTop: "20px" }}>
                              {item.code && <div>SKU : {item.code}</div>}
                              {item.brand && (
                                <div>Thương hiệu : {item.brand}</div>
                              )}
                              {item.Quality && (
                                <div>Chất lượng : {item.Quality}</div>
                              )}
                              {item.warranty && (
                                <div>Bảo hành : {item.warranty}</div>
                              )}
                              {item.certificate && (
                                <div>Chứng từ : {item.certificate}</div>
                              )}
                            </div>
                            {item.services === "" ? (
                              <></>
                            ) : (
                              <div
                                style={{
                                  backgroundColor: "antiquewhite",
                                  padding: 5,
                                  marginTop: "15px",
                                }}
                              >
                                <div style={{ fontWeight: 700 }}>
                                  Dịch vụ kèm theo
                                </div>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.services,
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        ) : (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: sanitizeAndSetContent(
                                item.product_detail
                              ),
                            }}
                          />
                        )}

                        {item.price === "0" ? (
                          ""
                        ) : (
                          <div style={{ display: "flex", marginTop: "10px" }}>
                            <input
                              type="text"
                              id="inputField"
                              value={valueQty}
                              defaultValue={1}
                              onChange={(event) => {
                                setValueQty(event.target.value);
                              }}
                            />
                            <button
                              className="btn-add"
                              onClick={(event) => {
                                addToCart(event, item);
                              }}
                            >
                              Thêm vào giỏ hàng
                            </button>
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginRight: "10px",
                        }}
                      >
                        <Card sx={{ width: 270, border: "1px grey soild" }}>
                          <CardContent>
                            <div>
                              <div style={{ color: "blue" }}>
                                Khu vực Miền Nam
                              </div>
                              <hr></hr>
                              <div
                                style={{
                                  padding: "5px",
                                  backgroundColor: "#366092",
                                  borderRadius: "8px",
                                  color: "white",
                                  display: "flex",
                                }}
                              >
                                {`Call ${valueNam}`} /{" "}
                                <div
                                  style={{
                                    marginLeft: "8px",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e)=>{
                                    const trimmedPhoneNumber = valueNam.replace(/\s/g, '');
                                    window.location.href = "zalo://conversation?phone="+trimmedPhoneNumber
                                  }}
                                >
                                  {" "}
                                  Chat Zalo
                                </div>
                              </div>
                              <div style={{ color: "blue", marginTop: "20px" }}>
                                Khu vực Miền Bắc
                              </div>
                              <hr></hr>
                              <div
                                style={{
                                  padding: "5px",
                                  backgroundColor: "#366092",
                                  borderRadius: "8px",
                                  color: "white",
                                  display:'flex'
                                }}
                              >
                                {`Call ${valueBac}`} / <div
                                  style={{
                                    marginLeft: "8px",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e)=>{
                                    const trimmedPhoneNumber = valueBac.replace(/\s/g, '');
                                    window.location.href = "zalo://conversation?phone="+trimmedPhoneNumber
                                  }}
                                >
                                  {" "}
                                  Chat Zalo
                                </div>
                              </div>
                              <div style={{ color: "blue", marginTop: "20px" }}>
                                Khu vực Miền Trung
                              </div>
                              <hr></hr>
                              <div
                                style={{
                                  padding: "5px",
                                  backgroundColor: "#366092",
                                  borderRadius: "8px",
                                  color: "white",
                                  display:'flex'
                                }}
                              >
                                {`Call ${valueTrung}`} / <div
                                  style={{
                                    marginLeft: "8px",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e)=>{
                                    const trimmedPhoneNumber = valueTrung.replace(/\s/g, '');
                                    window.location.href = "zalo://conversation?phone="+trimmedPhoneNumber
                                  }}
                                >
                                  {" "}
                                  Chat Zalo
                                </div>
                              </div>
                              <div style={{ color: "blue", marginTop: "20px" }}>
                                Dịch vụ / Bảo hành
                              </div>
                              <hr></hr>
                              <div
                                style={{
                                  padding: "5px",
                                  backgroundColor: "#366092",
                                  borderRadius: "8px",
                                  color: "white",
                                  display:'flex'
                                }}
                              >
                                {`Call ${valueKT}`} /  <div
                                  style={{
                                    marginLeft: "8px",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e)=>{
                                    const trimmedPhoneNumber = valueKT.replace(/\s/g, '');
                                    window.location.href = "zalo://conversation?phone="+trimmedPhoneNumber
                                  }}
                                >
                                  {" "}
                                  Chat Zalo
                                </div>
                              </div>
                              <div style={{ color: "blue", marginTop: "20px" }}>
                                Email liên hệ
                              </div>
                              <hr></hr>

                              <div
                                style={{
                                  padding: "5px",
                                  backgroundColor: "#366092",
                                  borderRadius: "8px",
                                  color: "white",
                                }}
                              >
                                Codiendonga1981@gmail.com
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      </div>
                    </Grid>
                  </Grid>
                  {item.mota === "" ? (
                    <></>
                  ) : (
                    <div>
                      <div
                        style={{
                          margin: "10px",
                          fontSize: "20px",
                          fontWeight: 600,
                        }}
                      >
                        Mô tả
                      </div>
                      <div
                        style={{ margin: "10px" }}
                        dangerouslySetInnerHTML={{
                          __html: item.mota,
                        }}
                      />
                    </div>
                  )}
                </div>
              ))
            )}
            {valueDataInfo.length === 0 ? (
              <div></div>
            ) : (
              <div>
                <div
                  style={{
                    margin: "10px",
                    fontSize: "20px",
                    fontWeight: 600,
                  }}
                >
                  Thông tin bổ sung
                </div>
                <table className="custom-border" style={{ width: "100%" }}>
                  <tbody>
                    {valueDataInfo.length === 0 ? (
                      <div></div>
                    ) : (
                      valueDataInfo.map((item) => (
                        <tr key={"key" + item.id}>
                          <td className="custom-border">{item.noidung}</td>
                          {item.noidung === "Yêu cầu tư vấn" ? (
                            <td>0989.727.547</td>
                          ) : (
                            <td>{item.valueContent}</td>
                          )}
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      )}
      {isTabletOrMobile === true && (
        <div>
          <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" style={{ backgroundColor: "teal" }}>
              <Toolbar variant="dense">
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2, cursor: "pointer" }}
                  onClick={eventOpenDraw}
                >
                  <MenuIcon />
                </IconButton>
                <Typography
                  variant="h6"
                  style={{ flexGrow: 1 }}
                  color="inherit"
                  component="div"
                  onClick={async () => {
                    fetchDataHome();
                    nextPage("/");
                  }}
                >
                  ĐÔNG Á
                </Typography>
                <div
                  className="shopping-style"
                  onClick={() => {
                    navigate("/cart");
                  }}
                >
                  <Badge badgeContent={cart.length} color="error">
                    <ShoppingCartOutlinedIcon style={{ color: "white" }} />
                  </Badge>
                </div>
              </Toolbar>
            </AppBar>
          </Box>
          <div>
            <Drawer anchor="left" open={openDraw} onClose={closeDraw}>
              {/* {list()} */}
              <Box
                sx={{ width: 250 }}
                role="presentation"
                // onClick={closeDraw}
                // onKeyDown={closeDraw}
              >
                <List>
                  <ListItemButton
                    onClick={() => {
                      nextPage("/thuong-hieu");
                    }}
                  >
                    <ListItemText
                      primary="Thương hiệu"
                      style={{ color: "blue" }}
                    />
                  </ListItemButton>
                  {valueDataMenu.map((item, index) => (
                    <div key={item.nameMenu}>
                      <ListItemButton
                        onClick={(event) => {
                          handleClickCLose(index);
                          if (item.child.length === 0) {
                            navigate(item.code);
                          }
                        }}
                      >
                        <ListItemText
                          primary={item.nameMenu}
                          style={{ color: "blue" }}
                        />
                        {item.child.length > 0 ? (
                          valueItemListIndex === index ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )
                        ) : (
                          ""
                        )}
                      </ListItemButton>
                      <Collapse
                        in={valueItemListIndex === index ? true : false}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List
                          component="div"
                          disablePadding
                          style={{
                            backgroundColor: "#eaeffa",
                            marginLeft: "20px",
                            marginRight: "8px",
                            borderRadius: "10px",
                          }}
                        >
                          {item.child.map((menu) => (
                            <ListItemButton
                              sx={{ pl: 1 }}
                              key={menu.nameChild}
                              onClick={() => {
                                nextPage(
                                  "/" + item.code + "/" + menu.codeChild
                                );
                              }}
                            >
                              <ListItemText
                                primary={menu.nameChild}
                                style={{ marginTop: 0, marginBottom: 0 }}
                              />
                            </ListItemButton>
                          ))}
                        </List>
                      </Collapse>
                    </div>
                  ))}
                </List>
              </Box>
            </Drawer>
          </div>
          <div
            style={{
              flexGrow: 1,
              backgroundColor: "teal",
              padding: "8px",
            }}
          >
            <Autocomplete
              freeSolo
              size="small"
              id="free-solo-2-demo"
              disableClearable
              sx={{ backgroundColor: "white", borderRadius: "80px" }}
              getOptionLabel={(option) => option.name}
              options={valueSearcData.length === 0 ? [] : valueSearcData}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={option.img}
                    alt="loading"
                  />
                  <div style={{ marginRight: "15px" }}>{option.name}</div>{" "}
                  <div>{formatter.format(parseFloat(option.price))}</div>
                </Box>
              )}
              onChange={(event, newValue) => {
                selectItemFind(event, newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Nhập tìm sản phẩm"
                  style={{ borderRadius: "60px" }}
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                    style: {
                      border: "none",
                      borderWidth: "0px",
                      borderRadius: "60px",
                    },
                    endAdornment: (
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    ),
                  }}
                  onChange={(event) => {
                    console.log(event.target.value);
                    searchData(event.target.value);
                  }}
                />
              )}
            />
          </div>
          <div className="fixed-bottom">
            <div style={{ display: "flex", padding: "5px" }}>
              <div
                style={{ width: "25%", cursor: "pointer" }}
                onClick={() => {
                  window.location.href = `tel:84989727547`;
                }}
              >
                <div>
                  <LocalPhoneIcon />
                  <div>Miền Nam</div>
                </div>
              </div>
              <div
                style={{ width: "25%", cursor: "pointer" }}
                onClick={() => {
                  window.location.href = `tel:84379587975`;
                }}
              >
                <div>
                  <LocalPhoneIcon />
                  <div>Miền Bắc</div>
                </div>
              </div>
              <div
                style={{ width: "25%", cursor: "pointer" }}
                onClick={() => {
                  window.location.href = `tel:84869203727`;
                }}
              >
                <div>
                  <LocalPhoneIcon />
                  <div>Miền Trung</div>
                </div>
              </div>
              <div
                style={{ width: "25%", cursor: "pointer" }}
                onClick={() => {
                  window.location.href = `tel:84869203727`;
                }}
              >
                <div>
                  <LocalPhoneIcon />
                  <div>Kỹ thuật</div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundColor: "white",
              marginLeft: "5px",
              marginRight: "5px",
              marginTop: "20px",
              paddingTop: "20px",
              paddingBottom: "20px",
              paddingLeft: "5px",
            }}
          >
            {valueData.length === 0 ? (
              <div></div>
            ) : (
              valueData.map((item) => (
                <div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <div style={{ maxWidth: "300px" }}>
                        <img
                          src={item.img}
                          style={{ width: "100%" }}
                          alt="img-product"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <div>
                        <div style={{ fontSize: "16px", fontWeight: 600 }}>
                          {item.name}
                        </div>
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "red",
                            marginTop: "15px",
                          }}
                        >
                          {item.price === "0"
                            ? "Liên hê"
                            : numberWithVNDFormat(parseFloat(item.price))}
                        </div>
                        {item.upload === "0" ? (
                          <div>
                            <div style={{ marginTop: "20px" }}>
                              {item.code && <p>SKU : {item.code}</p>}
                              {item.brand && <p>Thương hiệu : {item.brand}</p>}
                              {item.Quality && (
                                <p>Chất lượng : {item.Quality}</p>
                              )}
                              {item.warranty && (
                                <p>Bảo hành : {item.warranty}</p>
                              )}
                              {item.certificate && (
                                <p>Chứng từ : {item.certificate}</p>
                              )}
                            </div>
                            {item.services === "" ? (
                              <></>
                            ) : (
                              <div
                                style={{
                                  backgroundColor: "antiquewhite",
                                  padding: 5,
                                  marginTop: "15px",
                                }}
                              >
                                <div style={{ fontWeight: 700 }}>
                                  Dịch vụ kèm theo
                                </div>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.services,
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        ) : (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: sanitizeAndSetContent(
                                item.product_detail
                              ),
                            }}
                          />
                          // <div
                          //   dangerouslySetInnerHTML={{
                          //     __html: item.product_detail,
                          //   }}
                          // />
                        )}
                        {item.price === "0" ? (
                          ""
                        ) : (
                          <div style={{ display: "flex", marginTop: "15px" }}>
                            <input type="text" id="inputField" value={1} />
                            <button
                              className="btn-add"
                              onClick={(event) => {
                                addToCart(event, item);
                              }}
                            >
                              Thêm vào giỏ hàng
                            </button>
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={6} md={4}></Grid>
                  </Grid>
                  {item.mota === "" ? (
                    <></>
                  ) : (
                    <div>
                      <div
                        style={{
                          margin: "10px",
                        }}
                      >
                        <h3>Mô tả</h3>
                      </div>
                      <div
                        style={{ margin: "10px" }}
                        dangerouslySetInnerHTML={{
                          __html: sanitizeAndSetContent(item.mota),
                        }}
                      />
                      {/* <div
                        style={{ margin: "10px" }}
                        dangerouslySetInnerHTML={{
                          __html: item.mota,
                        }}
                      /> */}
                    </div>
                  )}
                  {/* <div
                    style={{
                      margin: "10px",
                      fontSize: "20px",
                      fontWeight: 600,
                    }}
                  >
                    Mô tả
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.mota,
                    }}
                  /> */}
                </div>
              ))
            )}
            {valueDataInfo.length === 0 ? (
              <div></div>
            ) : (
              <div>
                <div
                  style={{
                    margin: "10px",
                  }}
                >
                  <h3>Thông tin bổ sung</h3>
                </div>
                <table className="custom-border" style={{ width: "100%" }}>
                  <tbody>
                    {valueDataInfo.length === 0 ? (
                      <div></div>
                    ) : (
                      valueDataInfo.map((item) => (
                        <tr key={"key" + item.id}>
                          <td className="custom-border">{item.noidung}</td>
                          {item.noidung === "Yêu cầu tư vấn" ? (
                            <td>0989.727.547</td>
                          ) : (
                            <td>{item.valueContent}</td>
                          )}
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      )}

      <div
        style={{
          position: "fixed",
          bottom: isDesktopOrLaptop === true ? 16 : 26,
          right: 16,
        }}
      >
        {valueZalo.length === 0 ? (
          ""
        ) : (
          <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: "absolute", bottom: 16, right: 16 }}
            icon={actions[0].icon}
          >
            {valueZalo.map((action, index) => (
              <SpeedDialAction
                key={action.name}
                icon={actions[index].icon}
                tooltipTitle={action.name}
                onClick={() => {
                  if (isTabletOrMobile === true) {
                    window.location.href = action.link_phone;
                  } else {
                    window.location.href = action.link_Web;
                  }
                }}
              />
            ))}
          </SpeedDial>
        )}
      </div>
      <div className="container-footer">
        <div
          style={{
            marginLeft: isTabletOrMobile ? "8px" : "5%",
            marginRight: isTabletOrMobile ? "8px" : "5%",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={6} md={3}>
              <div style={{ textAlign: "center", justifyContent: "center" }}>
                <img src={logo} alt="logo" width={80} height={80} />
                <h3 style={{ color: "white" }}>
                  CÔNG TY TNHH TM CƠ ĐIỆN ĐÔNG Á
                </h3>
              </div>
            </Grid>
            <Grid item xs={6} md={3}>
              <div>
                <div className="text-support">HỖ TRỢ KHÁCH HÀNG</div>

                <ul className="policy-list">
                  <li
                    className="li-support"
                    onClick={() => {
                      const link = "cam-ket-chat-luong";
                      navigate("/" + link);
                    }}
                  >
                    Cam kết chất lượng
                  </li>
                  <li
                    className="li-support"
                    onClick={() => {
                      const link = "huong-dan-dat-hang";
                      navigate("/" + link);
                    }}
                  >
                    Hướng dẫn đặt hàng
                  </li>
                  <li
                    className="li-support"
                    onClick={() => {
                      const link = "loi-ich-khi-mua-hang";
                      navigate("/" + link);
                    }}
                  >
                    Lợi ích khi mua hàng
                  </li>
                  <li
                    className="li-support"
                    onClick={() => {
                      const link = "van-chuyen-va-giao-nhan";
                      navigate("/" + link);
                    }}
                  >
                    Vận chuyển và giao nhận
                  </li>
                  <li
                    className="li-support"
                    onClick={() => {
                      const link = "hinh-thuc-thanh-toan";
                      navigate("/" + link);
                    }}
                  >
                    Hình thức thanh toán
                  </li>
                  <li
                    className="li-support"
                    onClick={() => {
                      const link = "lien-he";
                      navigate("/" + link);
                    }}
                  >
                    Liên hệ
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={6} md={3}>
              <div>
                <div className="text-support">ĐIỀU KHOẢN VÀ CHÍNH SÁCH</div>
                <div style={{ display: "block" }}>
                  <ul class="policy-list">
                    <li
                      className="li-support"
                      onClick={() => {
                        const link = "dieu-khoan-su-dung";
                        navigate("/" + link);
                      }}
                    >
                      Điều khoản sử dụng
                    </li>
                    <li
                      className="li-support"
                      onClick={() => {
                        const link = "chinh-sach-doi-tra-hang";
                        navigate("/" + link);
                      }}
                    >
                      Chính sách đổi trả hàng
                    </li>
                    <li
                      className="li-support"
                      onClick={() => {
                        const link = "chinh-sach-khieu-nai";
                        navigate("/" + link);
                      }}
                    >
                      Chính sách khiếu nại
                    </li>
                    <li
                      className="li-support"
                      onClick={() => {
                        const link = "chinh-sach-bao-hanh";
                        navigate("/" + link);
                      }}
                    >
                      Chính sách bảo hành
                    </li>
                    <li
                      className="li-support"
                      onClick={() => {
                        const link = "chinh-sach-bao-mat";
                        navigate("/" + link);
                      }}
                    >
                      Chính sách bảo mật
                    </li>
                  </ul>
                </div>
              </div>
            </Grid>
            <Grid item xs={6} md={3}>
              <div>
                <div className="text-support">LIÊN HỆ</div>
                <ul className="policy-list">
                  <li className="li-support">Miền Bắc : {valueBac}</li>
                  <li className="li-support">Miền Nam : {valueNam}</li>
                  <li className="li-support">Miền Trung : {valueTrung}</li>
                  <li className="li-support">
                    Kỹ thuật - Bảo hành : {valueKT}
                  </li>
                </ul>
              </div>
            </Grid>
            {/* <Grid item xs={6} md={3}>
              <div>
                <div className="text-support">CHỨNG NHẬN DOANH NGHIỆP</div>
                <div style={{ display: "block" }}>
                  <img
                    src="/Image/dang-ky-bo-cong-thuong-dong-a.png"
                    width={isTabletOrMobile ? "150px" : "220px"}
                    alt="bo-cong-thuong"
                  />
                </div>
              </div>
            </Grid> */}
          </Grid>
        </div>
      </div>
      <div className="cls-company">
        <div
          style={{
            paddingTop: "20px",
            marginLeft: isTabletOrMobile ? "8px" : "5%",
            marginRight: isTabletOrMobile ? "8px" : "5%",
            paddingBottom: isTabletOrMobile ? "80px" : "20px",
          }}
        >
          <div style={{ fontWeight: 600, color: "white", padding: "5px" }}>
            CÔNG TY TNHH THƯƠNG MẠI CƠ ĐIỆN ĐÔNG Á
          </div>
          <div style={{ color: "white", padding: "5px" }}>
            Văn phòng chính: Thạnh Hội 1 , Tổ 1 , Ấp Nhứt Thạnh, xã Thạnh Hội,
            Tp Tân Uyên, Bình Dương{" "}
          </div>
          <div style={{ color: "white", padding: "5px" }}>
            Kho HCM : 22/15 Đường 440 giao cắt Võ Nguyên Giáp, Phường Phước Long
            A , Tp. Thủ Đức, TP. Hồ Chí Minh
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <a className="by" href="https://tmsoftware.vn/">
          Design Created by tmsoftware.vn
        </a>
      </div>
    </div>
  );
}
export default ProductDetailPage;
