import "../App.css";
import React, { useEffect } from "react";
import Tab from "@mui/material/Tab";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import CSS
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ContactPage = () => {
  const [value, setValue] = React.useState(0);
  const [valueHotline, setValueHotline] = React.useState(0);
  const [valueNam, setValueNam] = React.useState(0);
  const [valueBac, setValueBac] = React.useState(0);
  const [valueTrung, setValueTrung] = React.useState(0);
  const [valueKT, setValueKT] = React.useState(0);
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    getdata();
  }, []);
  const getdata = (valueLink) => {
    const url = "https://codiendonga.com.vn/API/Contact/select.php";
    fetch(url, {
      method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
    })
      .then((response) => response.json()) // Nếu server trả về JSON, chuyển đổi response thành đối tượng JavaScript
      .then((data) => {
        setValueHotline(data[0].phone_Hotline);
        setValueNam(data[0].phone_Nam);
        setValueBac(data[0].phone_Bac);
        setValueTrung(data[0].phone_Trung);
        setValueKT(data[0].phone_KyThuat);
        console.log("Server response:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const save = () => {
    const bodydata = {
      hotline: valueHotline,
      phonenam: valueNam,
      phonebac: valueBac,
      phonetrung: valueTrung,
      phonekt: valueKT,
    };
    fetch("https://codiendonga.com.vn/API/Contact/update.php", {
      method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
      headers: {
        "Content-Type": "application/json", // hoặc 'application/x-www-form-urlencoded', ...
        // Nếu cần thêm các header khác, thêm vào đây
      },
      body: JSON.stringify(bodydata), // Đảm bảo chuyển đổi đối tượng dữ liệu thành chuỗi JSON nếu bạn sử dụng 'application/json'
    })
      .then((response) => response.text()) // Nếu server trả về JSON, chuyển đổi response thành đối tượng JavaScript
      .then((data) => {
        setOpen(true);
        console.log("Server response:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  return (
    <div className="page-admin">
      
      <div style={{ marginTop: "5px" }}>
        <div className="cls-btn-save">
          <button className="btn-save" onClick={save}>
            Lưu
          </button>
        </div>

        <div style={{ marginLeft: "20px" }}>
            <div>
              <div style={{ marginBottom: "5px" }}>Hotline</div>
              <TextField
                id="outlined-basic"
                style={{ width: "300px" }}
                placeholder="Hotline"
                size="small"
                value={valueHotline}
                variant="outlined"
                onChange={(e) => setValueHotline(e.target.value)}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <div style={{ marginBottom: "5px" }}>Miền Nam</div>
              <TextField
                id="outlined-basic"
                style={{ width: "300px" }}
                placeholder="Miền nam"
                value={valueNam}
                size="small"
                variant="outlined"
                onChange={(e) => setValueNam(e.target.value)}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <div style={{ marginBottom: "5px" }}>Miền Bắc</div>
              <TextField
                id="outlined-basic"
                style={{ width: "300px" }}
                placeholder="Miền bắc"
                value={valueBac}
                size="small"
                variant="outlined"
                onChange={(e) => setValueBac(e.target.value)}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <div style={{ marginBottom: "5px" }}>Miền Trung</div>
              <TextField
                id="outlined-basic"
                style={{ width: "300px" }}
                placeholder="Miền trung"
                value={valueTrung}
                size="small"
                variant="outlined"
                onChange={(e) => setValueTrung(e.target.value)}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <div style={{ marginBottom: "5px" }}>Kỹ thuật</div>
              <TextField
                id="outlined-basic"
                style={{ width: "300px" }}
                placeholder="Kỹ thuật"
                size="small"
                value={valueKT}
                variant="outlined"
                onChange={(e) => setValueKT(e.target.value)}
              />
            </div>
          </div>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Thành công!
        </Alert>
      </Snackbar>
    </div>
  );
};
export default ContactPage;
