/* eslint-disable jsx-a11y/alt-text */
import "../App.css";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import { Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const formatter = new Intl.NumberFormat("vi-VN", {
  style: "currency",
  currency: "VND",
});
const OrderPage = () => {
  const [valueImg, setValueImg] = React.useState("");
  const [valueData, setValueData] = React.useState([]);
  const [bitCheck, setBitCheck] = React.useState(false);
  const [valueSelect, setValueSelect] = React.useState([]);

  useEffect(() => {
    fetchDataMenu();
  }, []);
  const fetchDataMenu = async () => {
    try {
      const response = await fetch(
        "https://codiendonga.com.vn/API/Order/select.php"
      );
      const result = await response.json();
      console.log(result);

      setValueData(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const deleteOrder = async (code) => {
    const bodydata = {
      code: code,
    };
    try {
      const response = await fetch(
        "https://codiendonga.com.vn/API/Order/delete.php",
        {
          method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
          headers: {
            "Content-Type": "application/json", // hoặc 'application/x-www-form-urlencoded', ...
            // Nếu cần thêm các header khác, thêm vào đây
          },
          body: JSON.stringify(bodydata),
        }
      );
      const result = await response.text();
      console.log(result);
      setBitCheck(false);
      setOpen(true);
      fetchDataMenu();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  return (
    <div className="page-admin">
      {bitCheck === false && (
        <div>
          <div
            style={{
              display: "flex",
              marginRight: "10px",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <div style={{ fontSize: 18, fontWeight: 600, marginRight: "10px" }}>
              Danh sách đơn hàng
            </div>
            <TextField
              id="outlined-basic"
              size="small"
              placeholder="Nhập mã đơn hàng để tìm"
              variant="outlined"
            />
          </div>
          {valueData.length === 0 ? (
            <div
              style={{
                fontSize: "18px",
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                marginTop:'100px'
              }}
            >
              Chưa có đơn hàng
            </div>
          ) : (
            <table className="custom-border" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th className="custom-border" style={{ width: "25px" }}>
                    STT
                  </th>
                  <th className="custom-border" style={{ width: "100px" }}>
                    Ngày
                  </th>
                  <th className="custom-border" style={{ width: "80px" }}>
                    Đơn hàng
                  </th>
                  <th className="custom-border" style={{ width: "80px" }}>Tên khách hàng</th>
                  <th className="custom-border" style={{ width: "70px" }}>
                    Phone
                  </th>
                  <th className="custom-border" style={{ width: "90px" }}>
                    Email
                  </th>
                  <th className="custom-border" style={{ width: "80px" }}>
                    Địa chỉ
                  </th>
                  
                  <th className="custom-border" style={{ width: "80px" }}>
                    Tổng tiền
                  </th>
                  <th className="custom-border" style={{ width: "80px" }}>
                    Giảm giá
                  </th>
                  <th className="custom-border" style={{ width: "80px" }}>
                    Thanh toán
                  </th>
                  <th className="custom-border" style={{ width: "100px" }}>
                    Hình thức thanh toán
                  </th>
                  <th className="custom-border" style={{ width: "70px" }}>
                    Hành động
                  </th>
                </tr>
              </thead>
              <tbody>
                {valueData.length === 0 ? (
                  <div
                    style={{
                      display: "flex",
                      height: "200px",
                      textAlign: "center",
                      justifyItems: "center",
                      justifyContent: "center",
                      width: "100vh",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  valueData.map((item) => (
                    <tr key={"key" + item.id}>
                      <td className="custom-border">{item.id}</td>
                      <td style={{ width: "80px" }}>{item.ngay}</td>
                      <td>{item.codeOrder}</td>
                      <td>{item.customer_Name}</td>
                      <td>{item.customer_Phone}</td>
                      <td>{item.customer_Email}</td>
                      <td>{(item.customer_city)},{(item.customer_district)},{(item.customer_ward)},{item.customer_number_home}</td>
                      {/* <td>{item.customer_district}</td>
                      <td>{item.customer_ward}</td>
                      <td>{item.customer_number_home}</td> */}
                      <td>{formatter.format(item.total)}</td>
                      <td>{formatter.format(item.promotion)}</td>
                      <td>{formatter.format(item.thanhtoan)}</td>
                      <td>{item.note}</td>
                      <td>
                        <div style={{ display: "flex" }}>
                          <button
                            className="bt-menu-detail"
                            onClick={() => {
                              setBitCheck(true);
                              setValueSelect(item);
                              // DeleteItem(item.code);
                            }}
                          >
                            Chi tiết
                          </button>

                          <button
                            className="bt-menu-delete"
                            onClick={(event) => {
                              deleteOrder(item.codeOrder);
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          )}
        </div>
      )}
      {bitCheck === true && (
        <div>
          <div className="header-page">
            <Chip
              label="< Quay lại"
              onClick={() => {
                setBitCheck(false);
              }}
              style={{
                color: "blue",
                backgroundColor: "#e5e7eb",
                marginRight: "10px",
              }}
            />
            <div
              style={{
                fontSize: "20x",
                fontWeight: 700,
                justifyItems: "start",
                display: "flex",
                justifyContent: "start",
                marginTop: "5px",
                flexGrow: 1,
              }}
            >
              Chi tiết đơn hàng
            </div>

            <div style={{ height: "60px", marginTop: "5px" }}>
              <button
                className="bt-delete-order"
                onClick={(event) => {
                  deleteOrder(valueSelect.codeOrder);
                }}
              >
                Xóa đơn hàng
              </button>
            </div>
          </div>
          <div>
            <Grid container>
              <Grid item xs={12} md={6}>
                <p>Tên : {valueSelect.customer_Name}</p>
                <p>Điện thoại : {valueSelect.customer_Phone}</p>
                <p>Email : {valueSelect.customer_Email}</p>
                <p>Tỉnh/Thành phố : {valueSelect.customer_city}</p>
                <p>Huyện/Quận : {valueSelect.customer_district}</p>
                <p>Xã/Phường : {valueSelect.customer_ward}</p>
                <p>Địa chỉ : {valueSelect.customer_number_home}</p>
              </Grid>
              <Grid item xs={12} md={6}>
                <p>Tổng tiền : {formatter.format(valueSelect.total)}</p>
                <p>Giảm giá : {formatter.format(valueSelect.promotion)}</p>
                <p>Thanh toán :{formatter.format(valueSelect.thanhtoan)}</p>
              </Grid>
            </Grid>
          </div>
          <div>
            <Paper sx={{ width: "100%" }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">#</TableCell>
                      <TableCell align="center">Sản phẩm</TableCell>
                      <TableCell align="right" width={110}>
                        Giá
                      </TableCell>
                      <TableCell align="left" width={100}>
                        Số lượng
                      </TableCell>
                      <TableCell align="right" width={110}>
                        Thành tiền
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {valueSelect.detail.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          <TableCell key={row.id}>{index + 1}</TableCell>
                          <TableCell key={row.id}>{row.name}</TableCell>
                          <TableCell key={row.id} align="right">
                            {formatter.format(parseFloat(row.price))}
                          </TableCell>
                          <TableCell key={row.id}>{row.qty}</TableCell>
                          <TableCell key={row.id} align="right">
                            {formatter.format(parseFloat(row.price) * row.qty)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </div>
      )}

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Thành công!
        </Alert>
      </Snackbar>
    </div>
  );
};
export default OrderPage;
