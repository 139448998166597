/* eslint-disable no-const-assign */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import {useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid";
import Badge from "@mui/material/Badge";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { myStateAtom } from "./recoilAtoms.js";
import { menuStateAtom } from "./recoilMenu.js";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@mui/styles";
import { useRecoilState } from "recoil";
import FilterListIcon from "@mui/icons-material/FilterList";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import zaloIcon from "../src/Image/zalo-icon.png";
import faceIcon from "../src/Image/face.png";
import phoneIcon from "../src/Image/phone.png";
import { useLocation, useHistory } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import logo from "../src/Image/co-dien-dong-a-color-logo.png";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  paginationItem: {
    backgroundColor: "#e0e0e0", // Set the background color here
    marginRight: 10,
  },
}));
const actions = [
  {
    icon: <img src={zaloIcon} alt="Zalo" width={32} />,
    name: "Zalo",
    link: "https://zalo.me/0987289556",
  },
  {
    icon: <img src={faceIcon} alt="messenge" width={38} />,
    name: "Messenger",
    link: "https://m.me/100084688140473",
  },
  {
    icon: <img src={phoneIcon} alt="phone" width={30} />,
    name: "Gọi điện",
    link: "tel:0974640477",
  },
];
const BrandDetailPage = () => {
  const { brand } = useParams();
  const getValueData = useRecoilValue(myStateAtom);
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const [valueSearcData, setValueSearchData] = useState([]);
  const [valueData, setValueData] = useState([]);
  const [valueDataShow, setValueDataShow] = useState([]);
  const [openDraw, setOpenDraw] = useState(false);
  const [openMenuChildren, setOpenMenuChildren] = React.useState(false);
  const navigate = useNavigate();
  const getValueMenu = useRecoilValue(menuStateAtom);
  const [valueDataMenu, setValueDataMenu] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(52);
  const [page, setPage] = React.useState(0);
  const [valueDataOld, setValueDataOld] = useRecoilState(myStateAtom);
  const [valueItemListIndex, setValueItemListIndex] = React.useState(0);
  const [valueMenuOld, setValueMenuOld] = useRecoilState(menuStateAtom);
  const [nameLink, setNameLink] = useState([]);
  const [cart, setCart] = useState([]);
  const location = useLocation();
  const [valueHotline, setValueHotline] = React.useState("");
  const [valueNam, setValueNam] = React.useState("");
  const [valueBac, setValueBac] = React.useState("");
  const [valueTrung, setValueTrung] = React.useState("");
  const [valueKT, setValueKT] = React.useState("");

  const [filterOptions, setFilterOptions] = useState({
    menuson: [],
    screenSize: [],
    dongcat: [],
    loai: [],
    socuc: [],
    dienapdieukhien: [],
    congsuat: [],
    dienapngovao: [],
    dienapngora: [],
    dongdien: [],
    ngora: [],
    duongkinh: [],
    dongro: [],
    mau: [],
    tisotruyen: [],
    lucrung: [],
    cotvao: [],
    cotra: [],
    tocdo: [],
  });
  const [filterOptionsValue, setFilterOptionsValue] = useState({
    menuson: "",
    screenSize: "Kích thước màn hình",
    dongcat: "Dòng cắt",
    loai: "Loại",
    socuc: " Số cực",
    dienapdieukhien: "Điện áp điều khiển",
    congsuat: "Công suất",
    dienapngovao: "Điện áp ngõ vào",
    dienapngora: "Điện áp ngõ ra",
    dongdien: "Dòng điện",
    ngora: "Ngõ ra",
    duongkinh: "Đường kính",
    dongro: "Dòng rò",
    mau: "Màu",
    tisotruyen: "Tỉ số truyền",
    lucrung: "Lực rung",
    cotvao: "Cốt vào",
    cotra: "Cốt ra",
    tocdo: "Tốc độ",
  });
  const queryParams = new URLSearchParams(location.search);
  const currentBrandFilter = queryParams.get("filter_brand") || "";

  // State to keep track of the brand filter
  const eventOpenDraw = () => {
    setOpenDraw(true);
  };
  const closeDraw = () => {
    setOpenDraw(false);
  };
  const handleClickCLose = () => {
    setOpenMenuChildren(!openMenuChildren);
  };
  const nextPage = (path) => {
    navigate(path);
    // history.push(path);
  };
  useEffect(() => {
    const storedData = localStorage.getItem("tm-shopping-cart");
    if (storedData) {
      setCart(JSON.parse(storedData));
    }
    getdataPhone();
    fetchZalo();
    getValueMenu.length > 0 ? setValueDataMenu(getValueMenu) : fetchDataMenu();

    // // getFillter();
    // const qr =
    //   valueDataMenu.length === 0
    //     ? null
    //     : valueDataMenu.find((item) => item.code === productmenu).child;
    // if (qr !== null) {
    //   const link = qr.find((x) => x.codeChild === param2).nameChild;
    //   setNameLink(link);
    // }
  }, [valueDataMenu]);

  const [filters, setFilters] = useState({});
  const [valueZalo, setvalueZalo] = React.useState([]);
  const fetchZalo = async () => {
    try {
      const response = await fetch(
        "https://codiendonga.com.vn/API/Zalo/select.php"
      );
      const result = await response.json();
      setvalueZalo(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fillData = async (datafill) => {
    try {
      const url = "https://codiendonga.com.vn/API/Product/fillterBrand.php";
      //const queryParams = new URLSearchParams(datafill);
      const requestBody = {
        brand: brand,
      };

      const response = await fetch(`${url}?${datafill}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      setValueDataShow(data);
      const uniqueMenu = [
        ...new Set(
          data
            .filter((item) => item.nameChild !== "" && item.nameChild !== null)
            .map((item) => item.nameChild)
        ),
      ];
      const uniqueFletchesSet = [
        ...new Set(
          data.filter((item) => item.brand !== "").map((item) => item.brand)
        ),
      ];
      const dataArrayDaIn = data
        .filter((item) => item.dienapngovao !== "")
        .map((item) => item.dienapngovao);
      const uniqueFletchDaIn = [
        ...new Set(
          dataArrayDaIn.flatMap((obj) => obj.split(",").map((v) => v.trim()))
        ),
      ];
      // const uniqueFletchDaIn = [
      //   ...new Set(
      //     data
      //       .filter((item) => item.dienapngovao !== "")
      //       .map((item) => item.dienapngovao)
      //   ),
      // ];
      const uniqueSizes = [
        ...new Set(
          data
            .filter((item) => item.screenSize !== "")
            .map((item) => item.screenSize)
        ),
      ];
      const uniqueLoai = [
        ...new Set(
          data.filter((item) => item.loai !== "").map((item) => item.loai)
        ),
      ];

      const dataArray = data
        .filter((item) => item.dienapdieukhien !== "")
        .map((item) => item.dienapdieukhien);
      const uniqueVoltages = [
        ...new Set(
          dataArray.flatMap((obj) => obj.split(",").map((v) => v.trim()))
        ),
      ];
      const uniqueDieukhien = [
        ...new Set(
          data
            .filter((item) => item.dienapdieukhien !== "")
            .map((item) => item.dienapdieukhien)
        ),
      ];
      const uniqueCongsuat = [
        ...new Set(
          data
            .filter((item) => item.congsuat !== "")
            .map((item) => item.congsuat)
        ),
      ];
      const uniqueĐongien = [
        ...new Set(
          data
            .filter((item) => item.dongdien !== "")
            .map((item) => item.dongdien)
        ),
      ];
      const uniqueDuongKinh = [
        ...new Set(
          data
            .filter((item) => item.duongkinh !== "")
            .map((item) => item.duongkinh)
        ),
      ];
      const uniqueDongcat = [
        ...new Set(
          data.filter((item) => item.dongcat !== "").map((item) => item.dongcat)
        ),
      ];
      const uniqueSocuc = [
        ...new Set(
          data.filter((item) => item.socuc !== "").map((item) => item.socuc)
        ),
      ];
      const uniqueDongro = [
        ...new Set(
          data.filter((item) => item.dongro !== "").map((item) => item.dongro)
        ),
      ];
      const uniqueMau = [
        ...new Set(
          data.filter((item) => item.mau !== "").map((item) => item.mau)
        ),
      ];
      const uniqueTisotruyen = [
        ...new Set(
          data
            .filter((item) => item.tisotruyen !== "")
            .map((item) => item.tisotruyen)
        ),
      ];
      const uniqueCotvao = [
        ...new Set(
          data.filter((item) => item.cotvao !== "").map((item) => item.cotvao)
        ),
      ];
      const uniqueCotra = [
        ...new Set(
          data.filter((item) => item.cotra !== "").map((item) => item.cotra)
        ),
      ];
      const uniqueLucrung = [
        ...new Set(
          data.filter((item) => item.lucrung !== "").map((item) => item.lucrung)
        ),
      ];
      const uniqueNgora = [
        ...new Set(
          data.filter((item) => item.ngora !== "").map((item) => item.ngora)
        ),
      ];
      const uniqueTocdo = [
        ...new Set(
          data.filter((item) => item.tocdo !== "").map((item) => item.tocdo)
        ),
      ];
      const uniqueDienApNgoRa = [
        ...new Set(
          data
            .filter((item) => item.dienapngora !== "")
            .map((item) => item.dienapngora)
        ),
      ];

      setFilterOptions({
        menuson: uniqueMenu,
        dongcat: uniqueDongcat,
        loai: uniqueLoai,
        socuc: uniqueSocuc,
        dienapdieukhien: uniqueVoltages,
        congsuat: uniqueCongsuat,
        dienapngovao: uniqueFletchDaIn,
        dienapngora: uniqueDienApNgoRa,
        dongdien: uniqueĐongien,
        ngora: uniqueNgora,
        screenSize: uniqueSizes,
        duongkinh: uniqueDuongKinh,
        dongro: uniqueDongro,
        mau: uniqueMau,
        tisotruyen: uniqueTisotruyen,
        lucrung: uniqueLucrung,
        cotvao: uniqueCotvao,
        cotra: uniqueCotra,
        tocdo: uniqueTocdo,
      });
      // Xử lý dữ liệu nhận được từ API
      console.log(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleFilterChange = async (filterName, value) => {
    await setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };
  useEffect(() => {
    // Update URL when filters change

    const searchParams = new URLSearchParams(window.location.search);
    const searchParams1 = new URLSearchParams();
    const urlFilters = {};

    if (Object.keys(filters).length !== 0) {
      const newUrl1 = `${window.location.pathname}?${searchParams.toString()}`;
      Object.keys(filters).forEach((filterName, value) => {
        if (filters[filterName] !== "") {
          searchParams1.set(filterName, filters[filterName]);
        }
      });
      searchParams1.size !== 0 ? fillData(searchParams1) : fetchData();
      const newUrl = `${window.location.pathname}?${searchParams1.toString()}`;
      if (window.location.href !== window.location.origin + newUrl) {
        window.history.pushState({}, "", newUrl);
        // Fetch data based on filters
        // fetchData();
      }
    } else {
      if (searchParams.size !== 0) {
        const menu = searchParams.get("menuson");
        const brand = searchParams.get("brand");
        const dienapngovao = searchParams.get("dienapngovao");
        const sizescreen = searchParams.get("screenSize");
        const loai = searchParams.get("loai");
        const dienapdieukhien = searchParams.get("dienapdieukhien");
        const congsuat = searchParams.get("congsuat");
        const dongdien = searchParams.get("dongdien");
        const duongkinh = searchParams.get("duongkinh");
        const dongcat = searchParams.get("dongcat");
        const socuc = searchParams.get("socuc");
        const dongro = searchParams.get("dongro");
        const mau = searchParams.get("mau");
        const tisotruyen = searchParams.get("tisotruyen");
        const lucrung = searchParams.get("lucrung");
        const cotvao = searchParams.get("cotvao");
        const cotra = searchParams.get("cotra");
        const ngora = searchParams.get("ngora");
        const tocdo = searchParams.get("tocdo");
        const dienapngora = searchParams.get("dienapngora");
        if (menu !== null) {
          handleFilterChange("menuson", menu);
        }
        if (brand !== null) {
          handleFilterChange("brand", brand);
        }
        if (dienapngovao !== null) {
          handleFilterChange("dienapngovao", dienapngovao);
        }
        if (dienapngora !== null) {
          handleFilterChange("dienapngora", dienapngora);
        }
        if (sizescreen !== null) {
          handleFilterChange("screenSize", sizescreen);
        }
        if (loai !== null) {
          handleFilterChange("loai", loai);
        }
        if (dienapdieukhien !== null) {
          handleFilterChange("dienapdieukhien", dienapdieukhien);
        }
        if (congsuat !== null) {
          handleFilterChange("congsuat", congsuat);
        }
        if (dongdien !== null) {
          handleFilterChange("dongdien", dongdien);
        }
        if (duongkinh !== null) {
          handleFilterChange("duongkinh", duongkinh);
        }
        if (dongcat !== null) {
          handleFilterChange("dongcat", dongcat);
        }
        if (socuc !== null) {
          handleFilterChange("socuc", socuc);
        }
        if (dongro !== null) {
          handleFilterChange("dongro", dongro);
        }
        if (mau !== null) {
          handleFilterChange("mau", mau);
        }
        if (tisotruyen !== null) {
          handleFilterChange("tisotruyen", tisotruyen);
        }
        if (lucrung !== null) {
          handleFilterChange("lucrung", lucrung);
        }
        if (cotvao !== null) {
          handleFilterChange("cotvao", cotvao);
        }
        if (cotra !== null) {
          handleFilterChange("cotra", cotra);
        }
        if (ngora !== null) {
          handleFilterChange("ngora", ngora);
        }
        if (tocdo !== null) {
          handleFilterChange("tocdo", tocdo);
        }
        fillData(searchParams);
      } else {
        fetchData();
      }
      const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
      if (window.location.href !== window.location.origin + newUrl) {
        window.history.pushState({}, "", newUrl);
        // Fetch data based on filters
        // fetchData();
      }
    }
  }, [filters]);
  const fetchDataHome = async () => {
    try {
      const response = await fetch(
        "https://codiendonga.com.vn/API/Product/select.php"
      );
      const result = await response.json();
      setValueData(result);
      setValueDataOld(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchData = async () => {
    const url = "https://codiendonga.com.vn/API/Product/BrandProduct.php";
    const bodyData = {
      code: "12234556",
      brand: brand,
    };
    fetch(url, {
      method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
      headers: {
        "Content-Type": "application/json", // hoặc 'application/x-www-form-urlencoded', ...
        // Nếu cần thêm các header khác, thêm vào đây
      },
      body: JSON.stringify(bodyData), // Đảm bảo chuyển đổi đối tượng dữ liệu thành chuỗi JSON nếu bạn sử dụng 'application/json'
    })
      .then((response) => response.json()) // Nếu server trả về JSON, chuyển đổi response thành đối tượng JavaScript
      .then((data) => {
        setValueData(data);
        setValueDataShow(data);
        const uniqueMenu = [
          ...new Set(
            data
              .filter(
                (item) => item.nameChild !== "" && item.nameChild !== null
              )
              .map((item) => item.nameChild)
          ),
        ];
        const uniqueFletchDaIn = [
          ...new Set(
            data
              .filter((item) => item.dienapngovao !== "")
              .map((item) => item.dienapngovao)
          ),
        ];
        const uniqueSizes = [
          ...new Set(
            data
              .filter((item) => item.screenSize !== "")
              .map((item) => item.screenSize)
          ),
        ];

        const uniqueLoai = [
          ...new Set(
            data.filter((item) => item.loai !== "").map((item) => item.loai)
          ),
        ];
        const uniqueDieukhien = [
          ...new Set(
            data
              .filter((item) => item.dienapdieukhien !== "")
              .map((item) => item.dienapdieukhien)
          ),
        ];

        const uniqueCongsuat = [
          ...new Set(
            data
              .filter((item) => item.congsuat !== "")
              .map((item) => item.congsuat)
          ),
        ];

        const uniqueĐongien = [
          ...new Set(
            data
              .filter((item) => item.dongdien !== "")
              .map((item) => item.dongdien)
          ),
        ];

        const uniqueDuongKinh = [
          ...new Set(
            data
              .filter((item) => item.duongkinh !== "")
              .map((item) => item.duongkinh)
          ),
        ];

        const uniqueDongcat = [
          ...new Set(
            data
              .filter((item) => item.dongcat !== "")
              .map((item) => item.dongcat)
          ),
        ];
        const uniqueSocuc = [
          ...new Set(
            data.filter((item) => item.socuc !== "").map((item) => item.socuc)
          ),
        ];
        const uniqueDongro = [
          ...new Set(
            data.filter((item) => item.dongro !== "").map((item) => item.dongro)
          ),
        ];
        const uniqueMau = [
          ...new Set(
            data.filter((item) => item.mau !== "").map((item) => item.mau)
          ),
        ];
        const uniqueTisotruyen = [
          ...new Set(
            data
              .filter((item) => item.tisotruyen !== "")
              .map((item) => item.tisotruyen)
          ),
        ];
        const uniqueCotvao = [
          ...new Set(
            data.filter((item) => item.cotvao !== "").map((item) => item.cotvao)
          ),
        ];
        const uniqueCotra = [
          ...new Set(
            data.filter((item) => item.cotra !== "").map((item) => item.cotra)
          ),
        ];
        const uniqueLucrung = [
          ...new Set(
            data
              .filter((item) => item.lucrung !== "")
              .map((item) => item.lucrung)
          ),
        ];
        const uniqueTocdo = [
          ...new Set(
            data.filter((item) => item.tocdo !== "").map((item) => item.tocdo)
          ),
        ];
        const uniqueDienApNgoRa = [
          ...new Set(
            data
              .filter((item) => item.dienapngora !== "")
              .map((item) => item.dienapngora)
          ),
        ];

        setFilterOptions({
          menuson: uniqueMenu,
          // brand: uniqueFletchesSet,
          dongcat: uniqueDongcat,
          loai: uniqueLoai,
          socuc: uniqueSocuc,
          dienapdieukhien: uniqueDieukhien,
          congsuat: uniqueCongsuat,
          dienapngovao: uniqueFletchDaIn,
          dienapngora: uniqueDienApNgoRa,
          screenSize: uniqueSizes,
          dongdien: uniqueĐongien,
          duongkinh: uniqueDuongKinh,
          dongro: uniqueDongro,
          mau: uniqueMau,
          tisotruyen: uniqueTisotruyen,
          lucrung: uniqueLucrung,
          cotvao: uniqueCotvao,
          cotra: uniqueCotra,
          tocdo: uniqueTocdo,
        });
        console.log("Server response:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const fetchDataMenu = async () => {
    try {
      const response = await fetch(
        "https://codiendonga.com.vn/API/Menu/select.php"
      );
      const result = await response.json();
      console.log(result);
      setValueDataMenu(result);
      setValueMenuOld(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  const handleChangePage = (event, newNumber) => {
    setPage(newNumber);
  };

  const open = Boolean(anchorEl);
  const classes = useStyles();

  const formatter = new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
  });
  const selectItemFind = (event, newData) => {
    const qr = newData.menuSon;
    navigate("/Product/" + newData.codeTM);
  };
  const searchData = async (data) => {
    if (data !== "") {
      const bodydata = {
        code: data,
      };
      fetch("https://codiendonga.com.vn/API/Product/findItem.php", {
        method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
        headers: {
          "Content-Type": "application/json", // hoặc 'application/x-www-form-urlencoded', ...
          // Nếu cần thêm các header khác, thêm vào đây
        },
        body: JSON.stringify(bodydata), // Đảm bảo chuyển đổi đối tượng dữ liệu thành chuỗi JSON nếu bạn sử dụng 'application/json'
      })
        .then((response) => response.json()) // Nếu server trả về JSON, chuyển đổi response thành đối tượng JavaScript
        .then((data) => {
          setValueSearchData(data);
          console.log("Server response:", data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setValueSearchData([]);
    }
  };
  const [openFill, setOpenFill] = React.useState(false);

  const handleClickOpenFill = () => {
    setOpenFill(true);
  };

  const handleCloseFill = () => {
    setOpenFill(false);
  };
  const getdataPhone = () => {
    const url = "https://codiendonga.com.vn/API/Contact/select.php";
    fetch(url, {
      method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
    })
      .then((response) => response.json()) // Nếu server trả về JSON, chuyển đổi response thành đối tượng JavaScript
      .then((data) => {
        setValueHotline(data[0].phone_Hotline);
        setValueNam(data[0].phone_Nam);
        setValueBac(data[0].phone_Bac);
        setValueTrung(data[0].phone_Trung);
        setValueKT(data[0].phone_KyThuat);
        console.log("Server response:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <div className="page">
       <Helmet>
         <title>Codiendonga.com.vn</title>
         <meta
           name="description"
           content="Cơ điện đông á, chuyên thiết bị điện công nghiệp, điện dân dụng chính hãng , giá rẻ , giao hàng toàn quốc"
         />
         <meta
           name="keywords"
           content="Cơ điện đông á, chuyên thiết bị điện công nghiệp, điện dân dụng chính hãng , giá rẻ , giao hàng toàn quốc" />
           <link rel="canonical"  href={valueDataShow.length === 0 ? "https://codiendonga.com.vn" : `https://codiendonga.com.vn/bai-viet/${valueDataShow[0].codeTM}`} />
           
       </Helmet>
      {isDesktopOrLaptop === true && (
        <div>
          <Box sx={{ flexGrow: 1 }}>
            <AppBar
              position="static"
              style={{
                paddingLeft: "3%",
                paddingRight: "3%",
                backgroundColor: "teal",
              }}
            >
              <Toolbar>
              <img src={logo} width={70} height={70} alt="logo-codiendonga" style={{marginRight:'10px'}}/>
              <h1 style={{cursor:'pointer'}}  onClick={async () => {
                   
                   nextPage("/");
                 }}
               >
                 ĐÔNG Á
               </h1>
                <div
                  style={{
                    flexGrow: 1,
                    marginLeft: "45px",
                    marginRight: "45px",
                  }}
                >
                  <Autocomplete
                    freeSolo
                    size="small"
                    id="free-solo-2-demo"
                    disableClearable
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "80px",
                      borderWidth: 0,
                    }}
                    getOptionLabel={(option) => option.name}
                    options={valueSearcData.length === 0 ? [] : valueSearcData}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          alt=""
                        />
                        <div style={{ marginRight: "15px" }}>{option.name}</div>{" "}
                        <div>{formatter.format(parseFloat(option.price))}</div>
                      </Box>
                    )}
                    onChange={(event, newValue) => {
                      selectItemFind(event, newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Nhập tìm sản phẩm"
                        style={{ borderRadius: "60px" }}
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                          style: {
                            border: "none",
                            borderWidth: "0px",
                            borderRadius: "60px",
                          },
                          endAdornment: (
                            <IconButton>
                              <SearchIcon />
                            </IconButton>
                          ),
                        }}
                        onChange={(event) => {
                          console.log(event.target.value);
                          searchData(event.target.value);
                        }}
                      />
                    )}
                  />
                </div>

                <div style={{ fontSize: "20px", marginRight: "10px" }}>
                  <Chip
                    label={`Hotline ${valueHotline}`}
                    style={{
                      color: "black",
                      fontSize: "22px",
                      backgroundColor: "#FFC000",
                      padding: "5px",
                    }}
                  />
                </div>
                <div
                  className="shopping-style"
                  onClick={() => {
                    navigate("/cart");
                  }}
                >
                  <Badge badgeContent={cart.length} color="error">
                    <ShoppingCartOutlinedIcon style={{ color: "white" }} />
                  </Badge>
                </div>
              </Toolbar>
            </AppBar>
          </Box>
          <div className="menu-header">
            <ul>
              <li className="dropdown">
                <a
                  class="dropbtn"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    nextPage("/thuong-hieu");
                  }}
                >
                  Thương hiệu
                </a>
              </li>
              {valueDataMenu.length === 0 ? (
                <div></div>
              ) : (
                valueDataMenu.map((menu) => (
                  <li className="dropdown">
                    <a
                      class="dropbtn"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        nextPage(menu.child.length === 0 ? menu.code : "");
                      }}
                    >
                      {menu.nameMenu}
                    </a>
                    <div className="dropdown-content">
                      {menu.child.map((item) => (
                        // <a href={`/${item.code}/${item.codeChild}`}>{item.nameChild}</a>
                        <a
                          href={`/${item.code}/${item.codeChild}`}
                          onClick={() => {
                            navigate("/" + item.code + "/" + item.codeChild);
                          }}
                        >
                          {item.nameChild}
                        </a>
                        // <a
                        //   onClick={() => {
                        //     nextPage("/" + item.code + "/" + item.codeChild);
                        //   }}
                        // >
                        //   {item.nameChild}
                        // </a>
                      ))}
                    </div>
                  </li>
                ))
              )}
            </ul>
          </div>
        </div>
      )}
      {isTabletOrMobile === true && (
        <div>
          <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" style={{ backgroundColor: "teal" }}>
              <Toolbar variant="dense">
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2, cursor: "pointer" }}
                  onClick={eventOpenDraw}
                >
                  <MenuIcon />
                </IconButton>
                <h1
                  style={{ cursor: "pointer" }}
                  onClick={async () => {
                    fetchDataHome();
                    nextPage("/");
                  }}
                >
                  ĐÔNG Á
                </h1>

                <div
                  className="shopping-style"
                  onClick={() => {
                    navigate("/cart");
                  }}
                >
                  <Badge badgeContent={cart.length} color="error">
                    <ShoppingCartOutlinedIcon style={{ color: "white" }} />
                  </Badge>
                </div>
              </Toolbar>
            </AppBar>
          </Box>
          <div>
            <Drawer anchor="left" open={openDraw} onClose={closeDraw}>
              <Box
                sx={{ width: 250 }}
                role="presentation"
                // onClick={closeDraw}
                // onKeyDown={closeDraw}
              >
                <List>
                  <ListItemButton
                    onClick={() => {
                      nextPage("/thuong-hieu");
                    }}
                  >
                    <ListItemText
                      primary="Thương hiệu"
                      style={{ color: "blue" }}
                    />
                  </ListItemButton>
                  {valueDataMenu.map((item, index) => (
                    <div key={item.nameMenu}>
                      <ListItemButton
                        onClick={(event) => {
                          handleClickCLose(index);
                          if (item.child.length === 0) {
                            navigate(item.code);
                            setOpenDraw(false);
                          }
                        }}
                      >
                        <ListItemText
                          primary={item.nameMenu}
                          style={{ color: "blue" }}
                        />
                        {item.child.length > 0 ? (
                          valueItemListIndex === index ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )
                        ) : (
                          ""
                        )}
                      </ListItemButton>
                      <Collapse
                        in={valueItemListIndex === index ? true : false}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List
                          component="div"
                          disablePadding
                          style={{
                            backgroundColor: "#eaeffa",
                            marginLeft: "20px",
                            marginRight: "8px",
                            borderRadius: "10px",
                          }}
                        >
                          {item.child.map((menu) => (
                            <ListItemButton
                              sx={{ pl: 1 }}
                              key={menu.nameChild}
                              onClick={() => {
                                nextPage(
                                  "/" + item.code + "/" + menu.codeChild
                                );
                              }}
                            >
                              <ListItemText
                                primary={menu.nameChild}
                                style={{ marginTop: 0, marginBottom: 0 }}
                              />
                            </ListItemButton>
                          ))}
                        </List>
                      </Collapse>
                    </div>
                  ))}
                </List>
              </Box>
            </Drawer>
          </div>
          <div
            style={{
              flexGrow: 1,
              backgroundColor: "teal",
              padding: "8px",
            }}
          >
            <Autocomplete
              freeSolo
              size="small"
              id="free-solo-2-demo"
              disableClearable
              sx={{ backgroundColor: "white", borderRadius: "80px" }}
              options={
                valueSearcData.length === 0
                  ? []
                  : valueSearcData.map((option) => option.title)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Nhập tìm sản phẩm"
                  style={{ borderRadius: "60px" }}
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                    style: {
                      border: "none",
                      borderWidth: "0px",
                      borderRadius: "60px",
                    },
                    endAdornment: (
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
          </div>
          <div className="fixed-bottom">
            <div style={{ display: "flex", padding: "5px" }}>
              <div
                style={{ width: "25%", cursor: "pointer" }}
                onClick={() => {
                  window.location.href = `tel:84989727547`;
                }}
              >
                <div>
                  <LocalPhoneIcon />
                  <div>Miền Nam</div>
                </div>
              </div>
              <div
                style={{ width: "25%", cursor: "pointer" }}
                onClick={() => {
                  window.location.href = `tel:84379587975`;
                }}
              >
                <div>
                  <LocalPhoneIcon />
                  <div>Miền Bắc</div>
                </div>
              </div>
              <div
                style={{ width: "25%", cursor: "pointer" }}
                onClick={() => {
                  window.location.href = `tel:84869203727`;
                }}
              >
                <div>
                  <LocalPhoneIcon />
                  <div>Miền Trung</div>
                </div>
              </div>
              <div
                style={{ width: "25%", cursor: "pointer" }}
                onClick={() => {
                  window.location.href = `tel:84869203727`;
                }}
              >
                <div>
                  <LocalPhoneIcon />
                  <div>Kỹ thuật</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className="container-no"
        style={{
          marginLeft: isTabletOrMobile ? "8px" : "5%",
          marginRight: isTabletOrMobile ? "8px" : "5%",
        }}
      >
        <div style={{ display: "flex", marginBottom: "8px" }}>
          <Chip
            label="Trang chủ"
            size="small"
            style={{
              backgroundColor: "#e5e7eb",
              cursor: "pointer",
              color: "blue",
            }}
            onClick={() => {
              navigate("/");
            }}
          />
          <div>/</div>

          <Chip
            label={brand}
            size="small"
            style={{ backgroundColor: "#e5e7eb", cursor: "pointer" }}
          />
          <div>/</div>
          <Chip
            label={nameLink}
            size="small"
            style={{ backgroundColor: "#e5e7eb", cursor: "pointer" }}
          />
          <div style={{ flexGrow: 1 }}></div>
          {isTabletOrMobile === true && (
            <div>
              <Chip
                label="Lọc"
                icon={<FilterListIcon />}
                onClick={handleClickOpenFill}
              />
            </div>
          )}
        </div>
        <Grid container spacing={2}>
          {/* {valueDataShow.length === 0 ? (
                <></>
              ) : (
                valueDataShow
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item) => (
                    <Grid item xs={6} md={2}>
                      <div
                        onClick={() => {
                          navigate("/Product/" + param2 + "/" + item.codeTM);
                        }}
                      >
                        <Card className="card-product-boder" key={item._id}>
                          <CardContent>
                            <div>
                          
                              <img src={item.img} alt="Ảnh đẹp" class="sharp-image"/>
                              <div style={{ height: "70px" }}>
                                <div className="text-product">{item.name}</div>
                              </div>
                              <div style={{ color: "red" }}>
                                {item.price ==="0"?"":formatter.format(parseFloat(item.price))}
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      </div>
                    </Grid>
                  ))
              )} */}
          <Grid item xs={0} md={3}>
            {isDesktopOrLaptop === true && (
              <div style={{ backgroundColor: "white", padding: "10px" }}>
                <div style={{ display: "flex", marginBottom: "20px" }}>
                  <FilterListIcon />
                  <div style={{ marginLeft: "10px" }}>Bộ lọc</div>
                </div>

                {Object.keys(filterOptions).map((filterName) => (
                  <div key={filterName} style={{ marginTop: "5px" }}>
                    {filterOptions[filterName].length > 0 ? (
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={filters[filterName] || ""}
                        size="small"
                        style={{ width: "95%" }}
                        options={filterOptions[filterName].map(
                          (option) => option
                        )}
                        onChange={(e, newValue) => {
                          if (newValue !== null) {
                            handleFilterChange(filterName, newValue);
                          } else {
                            handleFilterChange(filterName, "");
                          }
                        }}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={filterOptionsValue[filterName]}
                          />
                        )}
                      />
                    ) : (
                      <div></div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container spacing={2}>
              {valueDataShow.length === 0 ? (
                <></>
              ) : (
                valueDataShow
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item) => (
                    <Grid item xs={6} md={3}>
                      <div
                        onClick={() => {
                          navigate("/Product/" + item.codeTM);
                        }}
                      >
                        <Card className="card-product-boder" key={item._id}>
                          <CardContent>
                            <div>
                              <img
                                src={item.img}
                                alt={item.name}
                                class="sharp-image"
                              />
                              <div style={{ height: "70px" }}>
                                <div className="text-product">{item.name}</div>
                              </div>
                              <div style={{ color: "red", fontWeight: 600 }}>
                                {item.price === "0"
                                  ? ""
                                  : formatter.format(parseFloat(item.price))}
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      </div>
                    </Grid>
                  ))
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className="cls-page">
        <Pagination
          count={Math.ceil(valueDataShow.length / 52 - 1)}
          onChange={handleChangePage}
          variant="outlined"
          style={{ backgroundColor: "#e5e7eb" }}
          classes={{ ul: classes.paginationItem }}
          shape="rounded"
        />
      </div>
      <div
        style={{
          position: "fixed",
          bottom: isDesktopOrLaptop === true ? 16 : 26,
          right: 16,
        }}
      >
        {valueZalo.length === 0 ? (
          ""
        ) : (
          <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: "absolute", bottom: 16, right: 16 }}
            icon={actions[0].icon}
          >
            {valueZalo.map((action, index) => (
              <SpeedDialAction
                key={action.name}
                icon={actions[index].icon}
                tooltipTitle={action.name}
                onClick={() => {              
                  if(isTabletOrMobile ===true){
                    window.location.href = action.link_phone;
                  }else{
                    window.location.href = action.link_Web;
                  }
                }}
              />
            ))}
          </SpeedDial>
        )}
      </div>
      <div className="container-footer">
        <div
          style={{
            marginLeft: isTabletOrMobile ? "8px" : "5%",
            marginRight: isTabletOrMobile ? "8px" : "5%",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={6} md={3}>
              <div style={{ textAlign: "center", justifyContent: "center" }}>
                <img src={logo} alt="logo" width={80} height={80} />
                <h3 style={{ color: "white" }}>
                  CÔNG TY TNHH TM CƠ ĐIỆN ĐÔNG Á
                </h3>
              </div>
            </Grid>
            <Grid item xs={6} md={3}>
              <div>
                <div className="text-support">HỖ TRỢ KHÁCH HÀNG</div>

                <ul className="policy-list">
                  <li
                    className="li-support"
                    onClick={() => {
                      const link = "cam-ket-chat-luong";
                      navigate("/" + link);
                    }}
                  >
                    Cam kết chất lượng
                  </li>
                  <li
                    className="li-support"
                    onClick={() => {
                      const link = "huong-dan-dat-hang";
                      navigate("/" + link);
                    }}
                  >
                    Hướng dẫn đặt hàng
                  </li>
                  <li
                    className="li-support"
                    onClick={() => {
                      const link = "loi-ich-khi-mua-hang";
                      navigate("/" + link);
                    }}
                  >
                    Lợi ích khi mua hàng
                  </li>
                  <li
                    className="li-support"
                    onClick={() => {
                      const link = "van-chuyen-va-giao-nhan";
                      navigate("/" + link);
                    }}
                  >
                    Vận chuyển và giao nhận
                  </li>
                  <li
                    className="li-support"
                    onClick={() => {
                      const link = "hinh-thuc-thanh-toan";
                      navigate("/" + link);
                    }}
                  >
                    Hình thức thanh toán
                  </li>
                  <li
                    className="li-support"
                    onClick={() => {
                      const link = "lien-he";
                      navigate("/" + link);
                    }}
                  >
                    Liên hệ
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={6} md={3}>
              <div>
                <div className="text-support">ĐIỀU KHOẢN VÀ CHÍNH SÁCH</div>
                <div style={{ display: "block" }}>
                  <ul class="policy-list">
                    <li
                      className="li-support"
                      onClick={() => {
                        const link = "dieu-khoan-su-dung";
                        navigate("/" + link);
                      }}
                    >
                      Điều khoản sử dụng
                    </li>
                    <li
                      className="li-support"
                      onClick={() => {
                        const link = "chinh-sach-doi-tra-hang";
                        navigate("/" + link);
                      }}
                    >
                      Chính sách đổi trả hàng
                    </li>
                    <li
                      className="li-support"
                      onClick={() => {
                        const link = "chinh-sach-khieu-nai";
                        navigate("/" + link);
                      }}
                    >
                      Chính sách khiếu nại
                    </li>
                    <li
                      className="li-support"
                      onClick={() => {
                        const link = "chinh-sach-bao-hanh";
                        navigate("/" + link);
                      }}
                    >
                      Chính sách bảo hành
                    </li>
                    <li
                      className="li-support"
                      onClick={() => {
                        const link = "chinh-sach-bao-mat";
                        navigate("/" + link);
                      }}
                    >
                      Chính sách bảo mật
                    </li>
                  </ul>
                </div>
              </div>
            </Grid>
            <Grid item xs={6} md={3}>
              <div>
                <div className="text-support">LIÊN HỆ</div>
                <ul className="policy-list">
                    <li className="li-support">Miền Bắc : {valueBac}</li>
                    <li className="li-support">Miền Nam : {valueNam}</li>
                    <li className="li-support">Miền Trung : {valueTrung}</li>
                    <li className="li-support">
                      Kỹ thuật - Bảo hành : {valueKT}
                    </li>
                  </ul>
              </div>
            </Grid>
            {/* <Grid item xs={6} md={3}>
              <div>
                <div className="text-support">CHỨNG NHẬN DOANH NGHIỆP</div>
                <div style={{ display: "block" }}>
                  <img
                    src="/Image/dang-ky-bo-cong-thuong-dong-a.png"
                    width={isTabletOrMobile ? "150px" : "220px"}
                    alt="bo-cong-thuong"
                  />
                </div>
              </div>
            </Grid> */}
          </Grid>
        </div>
      </div>
      <div className="cls-company">
        <div
          style={{
            paddingTop:'20px',
            marginLeft: isTabletOrMobile ? "8px" : "5%",
            marginRight: isTabletOrMobile ? "8px" : "5%",
            paddingBottom:isTabletOrMobile ? "80px":"20px"
          }}
        >
          <div style={{fontWeight:600, color:'white',padding:'5px'}}>CÔNG TY TNHH THƯƠNG MẠI CƠ ĐIỆN ĐÔNG Á</div>
          <div style={{ color:'white',padding:'5px'}}>
            Văn phòng chính: Thạnh Hội 1 , Tổ 1 , Ấp Nhứt Thạnh, xã Thạnh Hội,
            Tp Tân Uyên, Bình Dương{" "}
          </div>
          <div style={{color:'white',padding:'5px'}}>
            Kho HCM : 22/15 Đường 440 giao cắt Võ Nguyên Giáp, Phường Phước Long
            A , Tp. Thủ Đức, TP. Hồ Chí Minh
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <a className="by" href="https://tmsoftware.vn/">
          Design Created by tmsoftware.vn
        </a>
      </div>
      <Dialog
        open={openFill}
        onClose={handleCloseFill}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Lọc "}</DialogTitle>
        <DialogContent>
          <div style={{ width: "310px" }}>
            {Object.keys(filterOptions).map((filterName) => (
              <div key={filterName} style={{ marginTop: "5px" }}>
                {filterOptions[filterName].length > 0 ? (
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={filters[filterName] || ""}
                    size="small"
                    style={{ width: "95%" }}
                    options={filterOptions[filterName].map((option) => option)}
                    onChange={(e, newValue) => {
                      if (newValue !== null) {
                        handleFilterChange(filterName, newValue);
                      } else {
                        handleFilterChange(filterName, "");
                      }
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={filterOptionsValue[filterName]}
                      />
                    )}
                  />
                ) : (
                  <div></div>
                )}
              </div>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFill}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default BrandDetailPage;
