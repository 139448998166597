/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Badge from "@mui/material/Badge";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { menuStateAtom } from "./recoilMenu.js";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useParams } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { format } from "date-fns";
import { Helmet } from "react-helmet";
import logo from "../src/Image/LOGO.ico";

const FinishPage = () => {
  const { data } = useParams();
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const { support } = useParams();
  const [openMenuChildren, setOpenMenuChildren] = React.useState(false);
  const [valueSearcData, setValueSearchData] = useState([]);
  const navigate = useNavigate();
  const [valueDataMenu, setValueDataMenu] = useState([]);
  const [openDraw, setOpenDraw] = useState(false);
  const [htmlContent, setHtmlContent] = useState([]);
  const [valueMenuOld, setValueMenuOld] = useRecoilState(menuStateAtom);
  const [cart, setCart] = useState([]);
  const [valueDiscount, setValuedDiscount] = useState("0");
  const [valueRadio, setValueRadio] = useState("thanh-toan-khi-nhan-hang");
  const [valueItemListIndex, setValueItemListIndex] = React.useState(0);
  const [valueHotline, setValueHotline] = React.useState("");
  const [valueNam, setValueNam] = React.useState("");
  const [valueBac, setValueBac] = React.useState("");
  const [valueTrung, setValueTrung] = React.useState("");
  const [valueKT, setValueKT] = React.useState("");
  const [valueOrder, setValueOrder] = useState([]);
  const [open, setOpen] = React.useState(false);

  const handleClickCLose = () => {
    setOpenMenuChildren(!openMenuChildren);
  };
  const nextPage = (path) => {
    navigate(path);
    // history.push(path);
  };
  const eventOpenDraw = () => {
    setOpenDraw(true);
  };
  const closeDraw = () => {
    setOpenDraw(false);
  };
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsVisible(true);
      console.log(true);
    } else {
      setIsVisible(false);
      console.log(false);
    }
  };

  useEffect(() => {
    getdata();
    fetchDataMenu();
    const storedData = localStorage.getItem("tm-shopping-cart");
    if (storedData) {
      setCart(JSON.parse(storedData));
    }
    getdataPhone();

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [support, cart, data]);

  const getdata = () => {
    const url = "https://codiendonga.com.vn/API/Order/selectIdOrder.php";

    const bodydata = {
      code: data,
    };

    fetch(url, {
      method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
      body: JSON.stringify(bodydata), // Đảm bảo chuyển đổi đối tượng dữ liệu thành chuỗi JSON nếu bạn sử dụng 'application/json'
    })
      .then((response) => response.json()) // Nếu server trả về JSON, chuyển đổi response thành đối tượng JavaScript
      .then((data) => {
        setValueOrder(data);
        console.log("Server response:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const fetchDataMenu = async () => {
    try {
      const response = await fetch(
        "https://codiendonga.com.vn/API/Menu/select.php"
      );
      const result = await response.json();
      console.log(result);
      setValueDataMenu(result);
      setValueMenuOld(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const formatter = new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
  });

  const subQuatily = (event, product) => {
    const existingItemIndex = cart.findIndex(
      (item) => item.codeTM === product.codeTM
    );
    if (existingItemIndex !== -1) {
      const updatedCart = [...cart];
      updatedCart[existingItemIndex].quantity -= 1;
      setCart(updatedCart);
      localStorage.setItem("tm-shopping-cart", JSON.stringify(updatedCart));
    }
  };
  const addQuatily = (event, product) => {
    const existingItemIndex = cart.findIndex(
      (item) => item.codeTM === product.codeTM
    );
    if (existingItemIndex !== -1) {
      const updatedCart = [...cart];
      updatedCart[existingItemIndex].quantity += 1;
      setCart(updatedCart);
      localStorage.setItem("tm-shopping-cart", JSON.stringify(updatedCart));
    }
  };

  const handleClick = (path) => {
    navigate(path);
  };
  const handleChangeRadio = (event) => {
    setValueRadio(event.target.value);
  };
  const [order, setOrder] = useState({
    customer: {
      name: "",
      phone: "",
      city: "",
      districts: "",
      wards: "",
      address: "",
      email: "",
      note: "",
      // Add more customer fields as needed
    },
  });
  const [currentDate, setCurrentDate] = useState(new Date());
  function generateRandomString(length) {
    const characters = "0123456789";
    let randomString = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters[randomIndex];
    }

    return randomString;
  }
  const saveOrder = () => {
    const randomString = generateRandomString(10);
    const totalMoney = cart.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
    const formattedDate = format(currentDate, "yyyy-MM-dd HH:mm");
    const ttoan = parseFloat(totalMoney) - parseFloat(valueDiscount);
    const bodydata = {
      codeOrder: "DH" + randomString,
      customer: order,
      total: totalMoney,
      promotion: valueDiscount,
      thanhtoan: ttoan,
      ngay: formattedDate,
      stylePay: valueRadio,
      products: cart,
    };
    fetch("https://codiendonga.com.vn/API/Order/add.php", {
      method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
      headers: {
        "Content-Type": "application/json", // hoặc 'application/x-www-form-urlencoded', ...
        // Nếu cần thêm các header khác, thêm vào đây
      },
      body: JSON.stringify(bodydata), // Đảm bảo chuyển đổi đối tượng dữ liệu thành chuỗi JSON nếu bạn sử dụng 'application/json'
    })
      .then((response) => response.text()) // Nếu server trả về JSON, chuyển đổi response thành đối tượng JavaScript
      .then((data) => {
        setOpen(true);
        sendGoogleSheet("DH" + randomString);
        console.log("Server response:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const sendGoogleSheet = () => {
    const randomString = generateRandomString(10);
    const totalMoney = cart.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
    const ttoan = parseFloat(totalMoney) - parseFloat(valueDiscount);
    const formattedDate = format(currentDate, "yyyy-MM-dd HH:mm");
    // const bodydata = {
    //   codeOrder:"DH"+randomString,
    //   customer:order,
    //   total:totalMoney,
    //   promotion:valueDiscount,
    //   thanhtoan:ttoan,
    //   ngay:formattedDate,
    //   stylePay:valueRadio,
    //   products: cart,
    // };
    const postData = {
      key1: "value1",
      key2: "value2",
      // Add other data as needed
    };
    fetch(
      "https://script.google.com/macros/s/AKfycbwfSpcesQvLLFfqLCbaUhb3JXzTCSeGvyY1zoOcxrTT2CsjP4-abxnB656n4RHFJxVKxg/exec",
      {
        method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
        headers: {
          "Content-Type": "application/json", // hoặc 'application/x-www-form-urlencoded', ...
          // Nếu cần thêm các header khác, thêm vào đây
        },
        body: JSON.stringify(postData), // Đảm bảo chuyển đổi đối tượng dữ liệu thành chuỗi JSON nếu bạn sử dụng 'application/json'
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleCustomerChange = (name, newValue) => {
    setOrder((prevOrder) => ({
      ...prevOrder,
      customer: {
        ...prevOrder.customer,
        [name]: newValue,
      },
    }));
  };
  const getdataPhone = () => {
    const url = "https://codiendonga.com.vn/API/Contact/select.php";
    fetch(url, {
      method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
    })
      .then((response) => response.json()) // Nếu server trả về JSON, chuyển đổi response thành đối tượng JavaScript
      .then((data) => {
        setValueHotline(data[0].phone_Hotline);
        setValueNam(data[0].phone_Nam);
        setValueBac(data[0].phone_Bac);
        setValueTrung(data[0].phone_Trung);
        setValueKT(data[0].phone_KyThuat);
        console.log("Server response:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    localStorage.removeItem("tm-shopping-cart");
    setOpen(false);
    nextPage("/");
  };
  return (
    <div className="page">
      <Helmet>
         <title>Codiendonga.com.vn</title>
         <meta
           name="description"
           content="Cơ điện đông á, chuyên thiết bị điện công nghiệp, điện dân dụng chính hãng , giá rẻ , giao hàng toàn quốc"
         />
         <meta
           name="keywords"
           content="Cơ điện đông á, chuyên thiết bị điện công nghiệp, điện dân dụng chính hãng , giá rẻ , giao hàng toàn quốc" />
       </Helmet>
      {isDesktopOrLaptop === true && (
        <div>
          <Box sx={{ flexGrow: 1 }}>
            <AppBar
              position="static"
              style={{
                paddingLeft: "3%",
                paddingRight: "3%",
                backgroundColor: "teal",
              }}
            >
              <Toolbar>
                <h1
                  style={{ cursor: "pointer" }}
                  onClick={async () => {
                    nextPage("/");
                  }}
                >
                  ĐÔNG Á
                </h1>
                <div
                  style={{
                    flexGrow: 1,
                    marginLeft: "45px",
                    marginRight: "45px",
                  }}
                >
                  <Autocomplete
                    freeSolo
                    size="small"
                    id="free-solo-2-demo"
                    disableClearable
                    sx={{ backgroundColor: "white", borderRadius: "80px" }}
                    options={
                      valueSearcData.length === 0
                        ? []
                        : valueSearcData.map((option) => option.title)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Nhập tìm sản phẩm"
                        style={{ borderRadius: "60px" }}
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                          style: {
                            border: "none",
                            borderWidth: "0px",
                            borderRadius: "60px",
                          },
                          endAdornment: (
                            <IconButton>
                              <SearchIcon />
                            </IconButton>
                          ),
                        }}
                      />
                    )}
                  />
                </div>

                <div style={{ fontSize: "20px", marginRight: "10px" }}>
                  <Chip
                    label={`Hotline ${valueHotline}`}
                    style={{
                      color: "black",
                      fontSize: "22px",
                      backgroundColor: "#FFC000",
                      padding: "5px",
                    }}
                  />
                </div>
                <div className="shopping-style">
                  <Badge badgeContent={cart.length} color="error">
                    <ShoppingCartOutlinedIcon style={{ color: "white" }} />
                  </Badge>
                </div>
              </Toolbar>
            </AppBar>
          </Box>
          <div className="menu-header">
            <ul>
              <li className="dropdown">
                <a
                  class="dropbtn"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    nextPage("/thuong-hieu");
                  }}
                >
                  Thương hiệu
                </a>
              </li>
              {valueDataMenu.length === 0 ? (
                <div></div>
              ) : (
                valueDataMenu.map((menu) => (
                  <li className="dropdown">
                    <a
                      class="dropbtn"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        nextPage(
                          menu.child.length === 0
                            ? "/category/" + menu.code
                            : ""
                        );
                      }}
                    >
                      {menu.nameMenu}
                    </a>
                    <div className="dropdown-content">
                      {menu.child.map((item) => (
                        <a
                          onClick={() => {
                            nextPage("/" + item.code + "/" + item.codeChild);
                          }}
                        >
                          {item.nameChild}
                        </a>
                      ))}
                    </div>
                  </li>
                ))
              )}
            </ul>
          </div>
        </div>
      )}
      {isTabletOrMobile === true && (
        <div>
          <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" style={{ backgroundColor: "teal" }}>
              <Toolbar variant="dense">
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={eventOpenDraw}
                >
                  <MenuIcon />
                </IconButton>
                <Typography
                  variant="h6"
                  style={{ flexGrow: 1, cursor: "pointer" }}
                  color="inherit"
                  component="div"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  ĐÔNG Á
                </Typography>
                <div className="shopping-style">
                  <Badge badgeContent={cart.length} color="error">
                    <ShoppingCartOutlinedIcon style={{ color: "white" }} />
                  </Badge>
                </div>
              </Toolbar>
            </AppBar>
          </Box>
          <div>
            <Drawer anchor="left" open={openDraw} onClose={closeDraw}>
              <Box
                sx={{ width: 250 }}
                role="presentation"
                // onClick={closeDraw}
                // onKeyDown={closeDraw}
              >
                <List>
                  <ListItemButton
                    onClick={() => {
                      nextPage("/thuong-hieu");
                    }}
                  >
                    <ListItemText
                      primary="Thương hiệu"
                      style={{ color: "blue" }}
                    />
                  </ListItemButton>
                  {valueDataMenu.map((item, index) => (
                    <div key={item.nameMenu}>
                      <ListItemButton
                        onClick={(event) => {
                          handleClickCLose(index);
                          setValueItemListIndex(index);
                          if (item.child.length === 0) {
                            navigate("/Product/" + item.code);
                          }
                        }}
                      >
                        <ListItemText
                          primary={item.nameMenu}
                          style={{ color: "blue" }}
                        />
                        {item.child.length > 0 ? (
                          valueItemListIndex === index ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )
                        ) : (
                          ""
                        )}
                      </ListItemButton>
                      <Collapse
                        in={valueItemListIndex === index ? true : false}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List
                          component="div"
                          disablePadding
                          style={{
                            backgroundColor: "#eaeffa",
                            marginLeft: "20px",
                            marginRight: "8px",
                            borderRadius: "10px",
                          }}
                        >
                          {item.child.map((menu) => (
                            <ListItemButton
                              sx={{ pl: 1 }}
                              key={menu.nameChild}
                              onClick={() => {
                                nextPage(
                                  "/" + item.code + "/" + menu.codeChild
                                );
                              }}
                            >
                              <ListItemText
                                primary={menu.nameChild}
                                style={{ marginTop: 0, marginBottom: 0 }}
                              />
                            </ListItemButton>
                          ))}
                        </List>
                      </Collapse>
                    </div>
                  ))}
                </List>
              </Box>
            </Drawer>
          </div>
          <div
            style={{
              flexGrow: 1,
              backgroundColor: "teal",
              padding: "8px",
            }}
          >
            <Autocomplete
              freeSolo
              size="small"
              id="free-solo-2-demo"
              disableClearable
              sx={{ backgroundColor: "white", borderRadius: "80px" }}
              options={
                valueSearcData.length === 0
                  ? []
                  : valueSearcData.map((option) => option.title)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Nhập tìm sản phẩm"
                  style={{ borderRadius: "60px" }}
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                    endAdornment: (
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
          </div>

          <div className="fixed-bottom">
            <div style={{ display: "flex", padding: "5px" }}>
              <div
                style={{ width: "25%", cursor: "pointer" }}
                onClick={() => {
                  window.location.href = `tel:84989727547`;
                }}
              >
                <div>
                  <LocalPhoneIcon />
                  <div>Miền Nam</div>
                </div>
              </div>
              <div
                style={{ width: "25%", cursor: "pointer" }}
                onClick={() => {
                  window.location.href = `tel:84379587975`;
                }}
              >
                <div>
                  <LocalPhoneIcon />
                  <div>Miền Bắc</div>
                </div>
              </div>
              <div
                style={{ width: "25%", cursor: "pointer" }}
                onClick={() => {
                  window.location.href = `tel:84869203727`;
                }}
              >
                <div>
                  <LocalPhoneIcon />
                  <div>Miền Trung</div>
                </div>
              </div>
              <div
                style={{ width: "25%", cursor: "pointer" }}
                onClick={() => {
                  window.location.href = `tel:84869203727`;
                }}
              >
                <div>
                  <LocalPhoneIcon />
                  <div>Kỹ thuật</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="container-no" style={{ borderRadius: "10px" }}>
        {valueOrder.length === 0 ? (
          <></>
        ) : (
          <div style={{ marginLeft: "5%", marginRight: "5%" }}>
            <h3>Đơn hàng đã nhận</h3>
            <div style={{ backgroundColor: "white", padding: "10px" }}>
              <p>
                Cảm ơn Quý Khách đã tin tưởng ủng hộ Công Ty TNHH Thương Mại Cơ
                Điện Đông Á.
              </p>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <div>
                    <p>Mã đơn hàng : {valueOrder[0].codeOrder}</p>
                    <p>Ngày : {valueOrder[0].ngay}</p>
                    <p>Email : {valueOrder[0].customer_Email}</p>
                    <p>Điện thoại : {valueOrder[0].customer_Phone}</p>
                    <p>
                      Tổng cộng : {formatter.format(valueOrder[0].thanhtoan)}
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div>
                    <p> Tỉnh/Thành phố : {valueOrder[0].customer_city}</p>
                    <p>Huyện/Quận : {valueOrder[0].customer_district}</p>
                    <p>Xã/Phường : {valueOrder[0].customer_ward}</p>
                    <p>Địa chỉ : {valueOrder[0].customer_number_home}</p>
                   
                  </div>
                </Grid>
              </Grid>
              <p style={{color:"red"}}>Lưu ý: Quý khách vui lòng chú ý điện thoại để nhân viên Cơ Điện Đông Á sẽ liên hệ xác nhận đơn hàng. Xin cảm ơn</p>
              <div>
                <h3>Chi tiết đơn hàng</h3>
              </div>
              <Paper sx={{ width: "100%" }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">#</TableCell>
                        <TableCell align="center" colSpan={2}>
                          Sản phẩm
                        </TableCell>
                        <TableCell align="right" width={110}>
                          Giá
                        </TableCell>
                        <TableCell align="left" width={100}>
                          Số lượng
                        </TableCell>
                        <TableCell align="right" width={110}>
                          Thành tiền
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {valueOrder[0].detail.map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                          >
                            <TableCell key={row.id}>{index + 1}</TableCell>
                            
                            <TableCell key={row.id}>{row.name}</TableCell>
                            <TableCell key={row.id} align="right">
                              {formatter.format(parseFloat(row.price))}
                            </TableCell>
                            <TableCell key={row.id}>{row.qty}</TableCell>
                            <TableCell key={row.id} align="right">
                              {formatter.format(
                                parseFloat(row.price) * row.qty
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
            
          </div>
        )}
        
      </div>

      <div className="container-footer">
        <div
          style={{
            marginLeft: isTabletOrMobile ? "8px" : "5%",
            marginRight: isTabletOrMobile ? "8px" : "5%",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={6} md={3}>
              <div style={{ textAlign: "center", justifyContent: "center" }}>
                <img src={logo} alt="logo" width={80} height={80} />
                <h3 style={{ color: "white" }}>
                  CÔNG TY TNHH TM CƠ ĐIỆN ĐÔNG Á
                </h3>
              </div>
            </Grid>
            <Grid item xs={6} md={3}>
              <div>
                <div className="text-support">HỖ TRỢ KHÁCH HÀNG</div>

                <ul className="policy-list">
                  <li
                    className="li-support"
                    onClick={() => {
                      const link = "cam-ket-chat-luong";
                      navigate("/" + link);
                    }}
                  >
                    Cam kết chất lượng
                  </li>
                  <li
                    className="li-support"
                    onClick={() => {
                      const link = "huong-dan-dat-hang";
                      navigate("/" + link);
                    }}
                  >
                    Hướng dẫn đặt hàng
                  </li>
                  <li
                    className="li-support"
                    onClick={() => {
                      const link = "loi-ich-khi-mua-hang";
                      navigate("/" + link);
                    }}
                  >
                    Lợi ích khi mua hàng
                  </li>
                  <li
                    className="li-support"
                    onClick={() => {
                      const link = "van-chuyen-va-giao-nhan";
                      navigate("/" + link);
                    }}
                  >
                    Vận chuyển và giao nhận
                  </li>
                  <li
                    className="li-support"
                    onClick={() => {
                      const link = "hinh-thuc-thanh-toan";
                      navigate("/" + link);
                    }}
                  >
                    Hình thức thanh toán
                  </li>
                  <li
                    className="li-support"
                    onClick={() => {
                      const link = "lien-he";
                      navigate("/" + link);
                    }}
                  >
                    Liên hệ
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={6} md={3}>
              <div>
                <div className="text-support">ĐIỀU KHOẢN VÀ CHÍNH SÁCH</div>
                <div style={{ display: "block" }}>
                  <ul class="policy-list">
                    <li
                      className="li-support"
                      onClick={() => {
                        const link = "dieu-khoan-su-dung";
                        navigate("/" + link);
                      }}
                    >
                      Điều khoản sử dụng
                    </li>
                    <li
                      className="li-support"
                      onClick={() => {
                        const link = "chinh-sach-doi-tra-hang";
                        navigate("/" + link);
                      }}
                    >
                      Chính sách đổi trả hàng
                    </li>
                    <li
                      className="li-support"
                      onClick={() => {
                        const link = "chinh-sach-khieu-nai";
                        navigate("/" + link);
                      }}
                    >
                      Chính sách khiếu nại
                    </li>
                    <li
                      className="li-support"
                      onClick={() => {
                        const link = "chinh-sach-bao-hanh";
                        navigate("/" + link);
                      }}
                    >
                      Chính sách bảo hành
                    </li>
                    <li
                      className="li-support"
                      onClick={() => {
                        const link = "chinh-sach-bao-mat";
                        navigate("/" + link);
                      }}
                    >
                      Chính sách bảo mật
                    </li>
                  </ul>
                </div>
              </div>
            </Grid>
            <Grid item xs={6} md={3}>
              <div>
                <div className="text-support">LIÊN HỆ</div>
                <ul className="policy-list">
                    <li className="li-support">Miền Bắc : {valueBac}</li>
                    <li className="li-support">Miền Nam : {valueNam}</li>
                    <li className="li-support">Miền Trung : {valueTrung}</li>
                    <li className="li-support">
                      Kỹ thuật - Bảo hành : {valueKT}
                    </li>
                  </ul>
              </div>
            </Grid>
            {/* <Grid item xs={6} md={3}>
              <div>
                <div className="text-support">CHỨNG NHẬN DOANH NGHIỆP</div>
                <div style={{ display: "block" }}>
                  <img
                    src="/Image/dang-ky-bo-cong-thuong-dong-a.png"
                    width={isTabletOrMobile ? "150px" : "220px"}
                    alt="bo-cong-thuong"
                  />
                </div>
              </div>
            </Grid> */}
          </Grid>
        </div>
      </div>
      <div className="cls-company">
        <div
          style={{
            paddingTop: "20px",
            marginLeft: isTabletOrMobile ? "8px" : "5%",
            marginRight: isTabletOrMobile ? "8px" : "5%",
            paddingBottom: isTabletOrMobile ? "80px" : "20px",
          }}
        >
          <div style={{ fontWeight: 600, color: "white", padding: "5px" }}>
            CÔNG TY TNHH THƯƠNG MẠI CƠ ĐIỆN ĐÔNG Á
          </div>
          <div style={{ color: "white", padding: "5px" }}>
            Văn phòng chính: Thạnh Hội 1 , Tổ 1 , Ấp Nhứt Thạnh, xã Thạnh Hội,
            Tp Tân Uyên, Bình Dương{" "}
          </div>
          <div style={{ color: "white", padding: "5px" }}>
            Kho HCM : 22/15 Đường 440 giao cắt Võ Nguyên Giáp, Phường Phước Long
            A , Tp. Thủ Đức, TP. Hồ Chí Minh
          </div>
        </div>
      </div>
    </div>
  );
};
export default FinishPage;
