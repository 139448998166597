/* eslint-disable no-const-assign */
/* eslint-disable react-hooks/exhaustive-deps */
import "../App.css";
import React, { useEffect, useRef, useState } from "react";
import Tab from "@mui/material/Tab";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import CSS
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import "react-quill/dist/quill.snow.css";
import parse from "html-react-parser";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Helmet } from "react-helmet";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const PostPage = () => {
  const [value, setValue] = React.useState([]);
  const [valueCode, setValueCode] = React.useState([]);
  const [valueCheck, setValueCheck] = React.useState(false);
  const [valueTitle, setValueTitle] = React.useState("");
  const [checkUpdate, setCheckUpdate] = React.useState(false);
  const [valueImg, setValueImg] = React.useState("");
  const [valueKeyWord, setValueKeyWord] = React.useState("");
  const [valueDescript, setValueDescript] = React.useState("");
  const [valueAlt, setValueAlt] = React.useState("");
  const [openLoadImg, setOpenLoadImg] = React.useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const quillRef = useRef(null);
  useEffect(() => {
    getdata();
  }, []);
  const getdata = (valueLink) => {
    const url = "https://codiendonga.com.vn/API/Post/select.php";
    fetch(url, {
      method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
    })
      .then((response) => response.json()) // Nếu server trả về JSON, chuyển đổi response thành đối tượng JavaScript
      .then((data) => {
        setValue(data);
        console.log("Server response:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  function generateRandomString(length) {
    const characters = "0123456789";
    let randomString = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters[randomIndex];
    }

    return randomString;
  }
  const save = () => {
    const randomString = generateRandomString(12);
    const formData = new FormData();
    formData.append(
      "image",
      selectedPathImage === null ? "" : selectedPathImage
    );
    formData.append("code", valueCode === "" ? randomString : valueCode);
    formData.append("title", valueTitle);
    formData.append("content", contentDescribe);
    formData.append("keyword", valueKeyWord);
    formData.append("description", valueDescript);
    formData.append("alt", valueAlt);
    fetch("https://codiendonga.com.vn/API/Post/insert.php", {
      method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
      body: formData, // Đảm bảo chuyển đổi đối tượng dữ liệu thành chuỗi JSON nếu bạn sử dụng 'application/json'
    })
      .then((response) => response.text()) // Nếu server trả về JSON, chuyển đổi response thành đối tượng JavaScript
      .then((data) => {
        setOpen(true);
        setValueCheck(false);
        setValueCode("");
        setValueImg("/Image/No-Img-DongA.png");
        setCheckUpdate(false);
        setValueTitle("");
        setSelectedImage(null);
        setContentDescribe("");
        setValueKeyWord("");
        setValueDescript("");
        setValueAlt("");
        getdata();
        console.log("Server response:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [valueAltDetail, setValueDetail] = useState("");

  const [contentDescribe, setContentDescribe] = useState("");
  const handleChangeDes = (value) => {
    // const images = parse(value, {
    //   replace: (domNode) => {
    //       if (domNode.name && domNode.name === "img") {
    //       return <img src={domNode.attribs.src} alt={valueAltDetail} />;
    //     }
    //   },
    // });
    setContentDescribe(value);
    
    // if (isImageLoading === false) {
    //   setContentDescribe(value);
    // }else{
    //   if(valueimg !==""){
    //     setContentDescribe(value);
    //   }
    // }
  };

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedPathImage, setSelectedPathImage] = useState(null);
  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setSelectedPathImage(file);
      const reader = new FileReader();

      reader.onload = () => {
        setSelectedImage(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const deleteItem = async (value) => {
    const bodydata = {
      code: value,
    };
    try {
      const response = await fetch(
        "https://codiendonga.com.vn/API/Post/delete.php",
        {
          method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
          headers: {
            "Content-Type": "application/json", // hoặc 'application/x-www-form-urlencoded', ...
            // Nếu cần thêm các header khác, thêm vào đây
          },
          body: JSON.stringify(bodydata), // Đảm bảo chuyển đổi đối tượng dữ liệu thành chuỗi JSON nếu bạn sử dụng 'application/json'
        }
      );
      const result = await response.text();
      console.log(result);
      setOpen(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const openImageDialog = () => {
    setOpenLoadImg(true);
    setIsImageLoading(true);
    console.log("Open image dialog");
  };
  const [modules, setModules] = useState({
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        ["clean"],
        [{ align: [] }],
      ],
      handlers: {
        image: openImageDialog,
      },
    },
  });
  const handleCloseImg = () => {
    setOpenLoadImg(false);
  };
  useEffect(() => {
    
    setModules((prevModules) => ({
      ...prevModules,
      toolbar: {
        ...prevModules.toolbar,
        handlers: {
          ...prevModules.toolbar.handlers,
          image: openImageDialog,
        },
      },
    }));
  }, [setIsImageLoading]);
  const [selectedPathImage1, setSelectedPathImage1] = useState(null);
  const [selectedImage1, setSelectedImage1] = useState(null);
  const handleImageChangeDetail = (e) => {
    const file = e.target.files[0];

    if (file) {
      setSelectedPathImage1(file);
      yourImageUploadFunction(file);
      const reader = new FileReader();

      reader.onload = () => {
        setSelectedImage1(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };
  const [valuePath, setValuePath] = useState("");
  const [valueimg, setValueimg] = useState("");
  const uploadImage = async () => {
    // const formData = new FormData();
    // formData.append('image', selectedPathImage1);
    try {
      // // Thực hiện logic tải ảnh lên máy chủ và nhận URL
      const imageUrl = "https://codiendonga.com.vn" + valuePath;

      // Thêm ảnh vào nội dung của ReactQuill với thuộc tính alt
      // const cursorPosition = quillRef.current.getEditor().getSelection().index;
      const quill = quillRef.current && quillRef.current.getEditor();
     // const cursorPosition  =0;
      if (quill) {
        const imageElement = `<p><img src="${imageUrl}" alt="${valueAltDetail}" style="display: block; margin: 0 auto;" /></p>`;
       // setValueimg(imageElement);
        //const delta = quill.clipboard.convert(imageElement);
        let cursorPosition = quill.getSelection()?.index || 0;

        if(cursorPosition ===0){
          cursorPosition = contentDescribe.length;
        }
        
       // quill.updateContents(delta, 'user');
        quillRef.current.getEditor().clipboard.dangerouslyPasteHTML(cursorPosition, imageElement);
        setValueDetail("");
        // Rest of your logic
      }
    
      setOpenLoadImg(false);
      setIsImageLoading(false);
    } catch (error) {
      console.error("Error:", error);
      return;
    }
  };

  const yourImageUploadFunction = async (file) => {
    const formData = new FormData();
    formData.append("image", file);

    fetch("https://codiendonga.com.vn/API/Post/uploadImg.php", {
      method: "POST", // hoặc 'GET' hoặc 'PUT', 'DELETE', ...
      body: formData, // Đảm bảo chuyển đổi đối tượng dữ liệu thành chuỗi JSON nếu bạn sử dụng 'application/json'
    })
      .then((response) => response.text()) // Nếu server trả về JSON, chuyển đổi response thành đối tượng JavaScript
      .then((data) => {
        console.log("Server response:", data);
        setValuePath(data.trim());
        return data;
      })
      .catch((error) => {
        console.error("Error:", error);
        return;
      });
  };
  return (
    <div className="page-admin">
      {valueCheck === false && (
        <div style={{ marginTop: "5px" }}>
          <div
            style={{ display: "flex", marginLeft: "10px", marginRight: "10px" }}
          >
            <h3 style={{ flexGrow: 1 }}>Bài viết</h3>
            <div>
              <button
                className="btn-new-post"
                onClick={(e) => {
                  setValueCheck(true);
                  setValueCode("");
                  setCheckUpdate(false);
                  setValueTitle("");
                  setContentDescribe("");
                  setValueDescript("");
                  setValueKeyWord("");
                }}
              >
                Thêm bài viết
              </button>
            </div>
          </div>

          <div style={{ marginLeft: "20px" }}>
            {value.length === 0 ? (
              <div></div>
            ) : (
              <Grid container spacing={2}>
                {value.map((item) => (
                  <Grid item xs={6} md={3}>
                    <div
                      onClick={(e) => {
                        setValueCode(item.codePost);
                        setValueCheck(true);
                        //setValueImg("https://codiendonga.com.vn" +item.linkImg);
                        setCheckUpdate(true);
                        setValueTitle(item.title);
                        setContentDescribe(item.content);
                        setSelectedImage(
                          "https://codiendonga.com.vn" + item.linkImg
                        );
                        setValueKeyWord(item.Keywords);
                        setValueDescript(item.Description);
                        setValueAlt(item.altImg);
                      }}
                    >
                      <Card sx={{ maxWidth: 345, height: 250 }}>
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            height="140"
                            image={"https://codiendonga.com.vn" + item.linkImg}
                            alt={item.title}
                          />
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="div"
                            >
                              {item.title}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </div>
                  </Grid>
                ))}
              </Grid>
            )}
          </div>
        </div>
      )}
      {valueCheck === true && (
        <div style={{ marginTop: "8px", marginRight: "10px" }}>
          <div style={{ display: "flex" }}>
            <Chip
              label="< Quay lại"
              onClick={() => {
                setValueCheck(false);
                setCheckUpdate(false);
                setSelectedImage(null);
              }}
              style={{
                color: "blue",
                backgroundColor: "#e5e7eb",
                marginRight: "10px",
              }}
            />
            <div
              style={{
                fontSize: "20x",
                fontWeight: 700,
                justifyItems: "start",
                display: "flex",
                justifyContent: "start",
                marginTop: "5px",
                flexGrow: 1,
              }}
            >
              Bài viết mới
            </div>
            <Button variant="contained" onClick={save}>
              Lưu
            </Button>
            {checkUpdate === true && (
              <Button
                variant="outlined"
                onClick={(e) => {
                  deleteItem(valueCode);
                }}
                style={{ marginLeft: "10px" }}
                color="error"
              >
                Xóa bài viết
              </Button>
            )}
          </div>
          <div className="post-container">
            <Grid container spacing={2}>
              <Grid item xs={9} md={9}>
                <div>
                  <p>Tiêu đề bài viết</p>
                  <TextField
                    id="outlined-basic"
                    placeholder="Tiêu đề bài viết"
                    variant="outlined"
                    size="small"
                    style={{ width: "100%" }}
                    value={valueTitle}
                    onChange={(e) => {
                      setValueTitle(e.target.value);
                    }}
                  />
                  <p>Keywords</p>
                  <TextField
                    id="outlined-basic"
                    placeholder="Keywords"
                    variant="outlined"
                    size="small"
                    style={{ width: "100%" }}
                    value={valueKeyWord}
                    onChange={(e) => {
                      setValueKeyWord(e.target.value);
                    }}
                  />
                  <p>Descriptions</p>
                  <TextField
                    id="outlined-basic"
                    placeholder="Descriptions"
                    variant="outlined"
                    size="small"
                    multiline
                    rows={4}
                    style={{ width: "100%" }}
                    value={valueDescript}
                    onChange={(e) => {
                      setValueDescript(e.target.value);
                    }}
                  />
                  <div style={{ marginBottom: "10px", marginTop: "30px" }}>
                    <div>Nội dung </div>
                    <ReactQuill
                      ref={quillRef}
                      value={contentDescribe}
                      onChange={handleChangeDes}
                      style={{ height: "100vh", minHeight: "100%" }}
                      modules={modules}
                      formats={[
                        "header",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "blockquote",
                        "list",
                        "bullet",
                        "link",
                        "image",
                        "align",
                      ]}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={3} md={3}>
                <div style={{ height: "230px", marginTop: "20px" }}>
                  <img
                    src={
                      selectedImage === null
                        ? "https://codiendonga.com.vn/Image/No-Img-DongA.png"
                        : selectedImage
                    }
                    alt="Preview"
                    style={{ width: "90%", height: "220px" }}
                  />
                </div>
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                >
                  Upload ảnh
                  <VisuallyHiddenInput
                    type="file"
                    onChange={handleImageChange}
                  />
                </Button>
                <div style={{ height: "20px" }}></div>
                <p>Alt hình ảnh</p>
                <TextField
                  id="outlined-basic"
                  placeholder="Alt hình ảnh"
                  variant="outlined"
                  size="small"
                  multiline
                  rows={2}
                  style={{ width: "100%" }}
                  value={valueAlt}
                  onChange={(e) => {
                    setValueAlt(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      )}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Thành công!
        </Alert>
      </Snackbar>

      <Dialog
        // fullWidth ={}
        // maxWidth
        open={openLoadImg}
        onClose={handleCloseImg}
      >
        <DialogTitle>Load ảnh</DialogTitle>
        <DialogContent>
          <div style={{ height: "250px", marginTop: "10px", width: "350px" }}>
            <img
              src={
                selectedImage1 === null
                  ? "https://codiendonga.com.vn/Image/No-Img-DongA.png"
                  : selectedImage1
              }
              alt="Preview"
              style={{ width: "100%", height: "240px" }}
            />
          </div>
          <Button
            component="label"
            variant="contained"
            style={{ width: "100%" }}
            startIcon={<CloudUploadIcon />}
          >
            Upload ảnh
            <VisuallyHiddenInput
              type="file"
              onChange={handleImageChangeDetail}
            />
          </Button>
          <div style={{ height: "20px" }}></div>
          <p>Alt hình ảnh</p>
          <TextField
            id="outlined-basic"
            placeholder="Alt hình ảnh"
            variant="outlined"
            size="small"
            multiline
            rows={2}
            style={{ width: "100%" }}
            value={valueAltDetail}
            onChange={(e) => {
              setValueDetail(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={uploadImage}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default PostPage;
